import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import React, {useState} from 'react'
import { Waypoint } from "react-waypoint";
import { useSpring, animated } from '@react-spring/web'
import { useSelector } from 'react-redux';
import '../../../../../assets/css/custom/faqs.css';
import Join from './Join';

function FAQs() {
  const [inView, setInview] = useState(false);
    const springs = useSpring({
        delay: 100,
        from: { x: -500 },
        to: {
            x: !inView ? -500 : 0,
            opacity: !inView ? 0 : 1,
          },
        config: {
          mass: 5,
          friction: 50,
          tension: 160,
        },
      })

  const { faqs } = useSelector(state => state.user);

  function RenderFAQS() {
    return faqs.map((faq, index) => {
      return (
        <div key={index}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className='faq-icon' />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className='faq-question'>
                {faq.question}
              </Typography>
            </AccordionSummary>
            <div style={{ width: '100%', backgroundColor: 'black', height: '2px' }}></div>
            <AccordionDetails>
              <Typography className='faq-answer'>
                {faq.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <div style={{ width: '100%', backgroundColor: 'black', height: '3px' }}></div>
        </div>
      )
    })
  }

  return (
    <>
      <div className='faqs__container'>
        <h1 className='faq-header'>Frequently Asked Questions</h1>
        <Waypoint onEnter={() => setInview(true)}>
          <div className='questions-tabs'>
            <div>
              <animated.div style={{...springs}} className='faq-accordion-container'>
                {RenderFAQS()}
              </animated.div>
            </div>
          </div>
        </Waypoint>         
      </div>
      <div
        className=''
        style={{
          marginTop: '2rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          flex: 1,
          height: '100%',
        }}
      >
        <Join />
      </div>
    </>
  )
}

export default FAQs
