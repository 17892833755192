import { gsap } from "gsap";
import React, { useEffect, useState} from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "swiper/swiper-bundle.css";
import "../../../assets/css/custom/new-episodes.css";
import { GetNewTvShows } from "../../../store/Actions/movieActions";
import RenderNewEpisodeSwiper from "./helperComponents/RenderNewEpisodesSwiper";
import { VerifyActiveSubscription, ChangeLoader } from './../../../store/Actions/userActions';

const gsapAnimate = {
  getData: (elem) => {
    const option = {
      opacity: 0,
      scale: 1,
      position: {
        x: 0,
        y: 0,
      },
      ease: "",
      duration: 1,
      delay: 0.4,
      rotate: 0,
    };
    if (elem !== undefined) {
      option.position.x = gsapAnimate.validValue(elem.dataset.iqPositionX, 0);
      option.position.y = gsapAnimate.validValue(elem.dataset.iqPositionY, 0);
      option.rotate = gsapAnimate.validValue(elem.dataset.iqRotate, 0);
      option.scale = gsapAnimate.validValue(elem.dataset.iqScale, 1);
      option.opacity = gsapAnimate.validValue(elem.dataset.iqOpacity, 0);
      option.delay = gsapAnimate.validValue(elem.dataset.iqDelay, 0.4);
      option.duration = gsapAnimate.validValue(elem.dataset.iqDuration, 1.5);
      option.ease = gsapAnimate.validValue(elem.dataset.iqEase, "");
      const setOption = {
        opacity: option.opacity,
        scale: option.scale,
        x: option.position.x,
        y: option.position.y,
        ease: option.ease,
        rotate: option.rotate,
        duration: option.duration,
        delay: option.delay,
      };
      return setOption;
    } else {
      return { opacity: 0 };
    }
  },

  onStart: (elem) => {
    const setOption = gsapAnimate.getData(elem);
    gsap.from(elem, setOption);
  },

  onEnd: (elem) => {
    const setOption = gsapAnimate.getData(elem);
    gsap.to(elem, setOption);
  },

  onStartEnd: (elem) => {
    const setOption = gsapAnimate.getData(elem);
    const setEndOption = gsapAnimate.getData(elem);
    setEndOption.opacity = 1;
    setEndOption.x = 0;
    setEndOption.y = 0;
    setEndOption.rotate = 0;
    setEndOption.scale = 1;
    gsap.fromTo(elem, setOption, setEndOption);
  },

  validValue: (attr, defaultVal) => {
    if (attr !== undefined && attr !== null) {
      return Number(attr);
    }
    return Number(defaultVal);
  },
};

const NewEpisodes = () => {
  const dispatch = useDispatch();
  const { newEpisodes } = useSelector((state) => state.movie);
  const [show, setShow] = useState()


  useEffect(() => {
    dispatch(VerifyActiveSubscription())
    dispatch(GetNewTvShows(true));
  }, [dispatch]);

  useEffect(() => {
    if(newEpisodes.length){
      dispatch(ChangeLoader(false))
    }
  }, [newEpisodes, dispatch]);

  useEffect(() => {
    let timer1 = setTimeout(() => setShow(true), 5000);
    return () => {
      clearTimeout(timer1);
    };
}, []);

console.log("new Ep: " + JSON.stringify(newEpisodes));
  return (
    <>
      {newEpisodes?.length ? 
      <div className="main-content">
        <Container fluid className="custom-container heading-container">
          <h3 className="main-title main-heading">New Episodes</h3>
          <section id="iq-favorites" className="slider-section"></section>
        </Container>
        <RenderNewEpisodeSwiper episodeList={newEpisodes}/>
        <div style={{ height: 30 }}></div>
      </div>
      : show && "No New Episodes"}
    </>
  );
};

export default NewEpisodes;
