import React from "react";
import { Link } from "react-router-dom";
import { getOS } from "../../../../../config/helperFunctions";
import { IoLogoAndroid, IoLogoApple } from "react-icons/io";

function GetNowBtn() {
  return (
    <div className="get-now-btn">
      {getOS() === "iOS" && (
        <a href='https://apps.apple.com/us/app/multitude/id1573920135'>
          <button className="navbar--button get-now-btn">
            <IoLogoApple
              style={{ fontSize: 25, marginRight: 5, marginBottom: 5 }}
              className="navbar--button-icon os-icon"
            />
            <span className="navbar--button-text get-now-btn-txt">Get App</span>
          </button>
        </a>
      )}
      {getOS() === "Android" && (
        <a href='https://play.google.com/store/apps/details?id=com.multitude&hl=en_US&gl=US'>
          <button className="navbar--button">
            <IoLogoAndroid
              style={{ fontSize: 25, marginRight: 5, marginBottom: 5 }}
              className="navbar--button-icon os-icon"
            />
            <span className="navbar--button-text get-now-btn-txt">Get App</span>
          </button>
        </a>
      )}
      {getOS() !== "iOS" && getOS() !== "Android" && (
        <>
          <Link to="/sign-up">
            <button className="navbar--button">
              <span className="navbar--button-text get-now-btn-txt font-weight-bold">
                JOIN
              </span>
            </button>
          </Link>

          <Link style={{ marginLeft: 10 }} to="/sign-in">
            <button className="navbar--button">
              <span className="navbar--button-text get-now-btn-txt font-weight-bold">
                LOGIN
              </span>
            </button>
          </Link>
        </>
      )}
    </div>
  );
}

export default GetNowBtn;
