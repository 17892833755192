import Button from 'react-bootstrap/Button';
import { useDispatch } from 'react-redux';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import {
    CardElement, useElements,
    useStripe
} from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import { ChangeLoader, UpdatePaymentInfo } from '../../../../store/Actions/userActions';



const NewCardInfo = ({handleClose, handleSubmit}) => {
    const dispatch = useDispatch()

    const stripe = useStripe();
    const elements = useElements();
    
    const handleNewCardSubmit = async (event) => {
          console.log("Ran handleNewCardSubmit")
          event.preventDefault();
          if (elements == null) {
              return;
          }
    
          const { error, paymentMethod } = await stripe.createPaymentMethod({
              type: 'card',
              card: elements.getElement(CardElement),
          });
    
          if (error) {
              dispatch(ChangeLoader(false))
              toast(error?.message)
          }
    
          if (paymentMethod) {
              const {
                  id,
                  card: { brand }
              } = paymentMethod || {}
    
              dispatch(UpdatePaymentInfo({
                  paymentMethod: brand,
                  stripeToken: id,
              }))
              handleSubmit()
          }
          
      };
    const options = {
      style: {
          base: {
              fontSize: 12,
              color: "white",
              letterSpacing: "0.025em",
              fontFamily: "Source Code Pro, monospace",
              "::placeholder": {
                  color: "#aab7c4"
              }
          },
          invalid: {
              color: "#9e2146"
          }
      }
  }


    
    return ( 
        <div>
              <div
                style={{
                    minWidth: '370px'
                }}
                >
                <div className='text__feilds'>
                    <div className='row'>
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': {
                                    m: 2,
                                    width: '40ch',
                                    border: '0px solid red',
                                },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <Typography>
                                Your New Card Information
                            </Typography>
                            <CardElement
                                options={options}
                            />
                        </Box>
                        
                    </div>
                    <div className="updatePaymentModal-btn">
                        <Button onClick={handleClose} className="btn btn-hover iq-button text-medium navbar--button signup-button flx-btn" variant="secondary">
                            Cancel
                        </Button>
                        <Button onClick={handleNewCardSubmit} className="btn btn-hover iq-button text-medium navbar--button signup-button flx-btn" variant="primary">
                            Save Changes
                        </Button>
                    </div>
                </div>
              </div>
            </div>
     );
}
 
export default NewCardInfo;



