import ComputerIcon from '@mui/icons-material/Computer';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import TabletAndroidIcon from '@mui/icons-material/TabletAndroid';
import { Divider, Typography, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import { useHistory } from 'react-router-dom';
import '../../../../assets/css/signIn.css';

function PaymentSuccessful() {

    const history = useHistory()
    const matches = useMediaQuery('(min-width:768px)');

    const handleContinue = () => {
        history.push("/")
    }

    return (
        <>
            <div className='signIn__container'>
                <div className='signIn__container-inner signin-inner'>
                    <Typography
                        variant='h5'
                    >
                        {"Payment Successful"}
                    </Typography>
                    <div className='text__feilds'>
                        <div className='row'>
                            <Box
                                component="form"
                                sx={{
                                    m: 2,
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <Box
                                    sx={{
                                        border: '0px solid red',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            border: '0px solid red',
                                            width: matches ? '40ch' : 'unset',
                                            // width: 100,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                border: '0px solid red',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                p: 2,
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: 'white'
                                                }}
                                            >
                                                Amount Paid
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    color: 'white'
                                                }}
                                            >
                                                $9.99
                                            </Typography>
                                        </Box>
                                        <Divider />
                                        <Box
                                            sx={{
                                                border: '0px solid red',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                p: 2,
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: 'white'
                                                }}
                                            >
                                                Allowed Devices
                                            </Typography>
                                            <Box
                                                sx={{
                                                    border: '0px solid red',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        border: '0px solid red',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',

                                                    }}
                                                >
                                                    <ComputerIcon />
                                                    <Typography
                                                        variant='caption'
                                                        sx={{
                                                            color: 'white',
                                                        }}
                                                    >
                                                        Computer
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        border: '0px solid red',
                                                        mt: 2,
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',

                                                    }}
                                                >
                                                    <PhoneIphoneIcon />
                                                    <Typography
                                                        variant='caption'
                                                        sx={{
                                                            color: 'white',
                                                        }}
                                                    >
                                                        Phone
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        border: '0px solid red',
                                                        mt: 2,
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',

                                                    }}
                                                >
                                                    <TabletAndroidIcon />
                                                    <Typography
                                                        variant='caption'
                                                        sx={{
                                                            color: 'white',
                                                        }}
                                                    >
                                                        Tablet
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            mt: 3,
                                        }}
                                    >
                                        <div
                                            className="btn btn-hover iq-button iq-button text-medium navbar--button signup-button"
                                            onClick={handleContinue}
                                            style={{
                                                marginTop: 0,
                                            }}
                                        >
                                            <span
                                                className='button-txt'
                                                style={{
                                                    color: 'black',
                                                }}
                                            >
                                                Continue
                                            </span>
                                        </div>
                                    </Box>
                                </Box>
                            </Box>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default PaymentSuccessful
