import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, FormGroup } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import TrailerUpload from './components/TrailerUpload';
import PortraitUpload from './components/PortraitUpload';
import LandscapeUpload from './components/LandscapeUpload';
import { useSelector, useDispatch } from 'react-redux';
import { fetchLanguageInfo, GetAllCategories, GetShowDetails, ShowEdit } from './../../store/Actions/movieActions';
import ReactSelect, {components} from 'react-select'
import { Controller, useForm } from 'react-hook-form'
import moment from "moment";
import validateAndSanitizeShowData from './../../validation/showValidation';


const EditTVShow = ({ showId }) => {
    const dispatch = useDispatch()
    const { control } = useForm()
    const { genre, languages, seasonDetail  } = useSelector((state) => state.movie);
    const [formData, setFormData] = useState({
        title: '',
        language: null,
        category: [],
        trailer: null,
        portrait_image: null,
        landscape_image: null,
        release_date: new Date(),
        description: ''
    })

    const [langOptionSelected, setLangOptionSelected] = useState()
    const [langOptionList, setLangOptionList] = useState([])

    const [optionSelected, setOptionSelected] = useState()
    const [genreOptionList, setGenreOptionList] = useState([])

    const Option = (props) => {
        return (
          <div style={{backgroundColor: '#141414'}}>
            <components.Option {...props}>
              <input
                style={{height: '.85rem', backgroundColor: '#141414'}}
                type="checkbox"
                checked={props.isSelected}
                onChange={() => null}
                />{" "}
              <label>{props.label}</label>
            </components.Option>
          </div>
        );
    };

    const colorStyles = {
        control: styles => ({ ...styles, backgroundColor: '#141414'}),
    };

    const handleTrailerUpload = event => {
        setFormData(prevState => ({
            ...prevState,
            trailer: event.target.files[0]
        }))
    }

    const handleImageUpload = event => {
        const { name } = event.target
        setFormData(prevState => ({
            ...prevState,
            [name]: event.target.files[0]
        }))
    }

    const handleInputChange = event => {
        const { name, value } = event.target
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const handleSubmit = event => {
        event.preventDefault()
        let newFormat = moment.utc(formData.release_date).format("ddd MMM D YYYY HH:mm:ss")
        const checkedData = validateAndSanitizeShowData(formData)
        dispatch(ShowEdit(checkedData.sanitizedData, showId, newFormat))
    }
    
    useEffect(() => {
        dispatch(GetShowDetails(showId, false, true, true))
    }, [dispatch, showId]);

    useEffect(() => {
        dispatch(GetAllCategories())
        dispatch(fetchLanguageInfo())
    }, [dispatch]);

    useEffect(() => {
        const tempList = []
        seasonDetail && seasonDetail.category?.map((category) => {
           const objContainer = {}
           objContainer.value = category.category_id
           objContainer.label = category.category_name
           tempList.push(objContainer)
           return setOptionSelected(tempList)
        })
        setFormData(prevState => ({
            ...prevState,
            title: seasonDetail?.name,
            language: seasonDetail?.language_id,
            category: seasonDetail?.category,
            trailer: seasonDetail?.trailer,
            portrait_image: seasonDetail?.portrait_image,
            landscape_image: seasonDetail?.landscape_image,
            release_date: seasonDetail.release_date ? new Date(seasonDetail?.release_date) : new Date(),
            description: seasonDetail?.description,
        }))
    }, [seasonDetail]);

    useEffect(() => {
        const tempList = []
        const genreOptions = () => {
            genre?.length && genre.map((genre) => {
                const objContainer = {}
                objContainer.value = genre.id
                objContainer.label = genre.name
                tempList.push(objContainer)
                return setGenreOptionList(tempList)
            })
        }
        genreOptions()
    }, [genre]); 
    
    useEffect(() => {
        const tempListLang = []
        const langOptions = () => {
        languages?.length && languages.map((language) => {
            const objContainer = {}
            objContainer.value = language.id
            objContainer.label = language.name
            tempListLang.push(objContainer)
            return setLangOptionList(tempListLang)
            })
          }
        langOptions()
      }, [languages]);

    useEffect(() => {
        const newGenreList = []
        optionSelected?.length > 0 && optionSelected.map((genreObj) => {
            return newGenreList.push(genreObj.value)
        })
        setFormData(prevState => ({
            ...prevState,
            category: newGenreList
        }))
     }, [optionSelected])

    useEffect(() => {
        if(!langOptionSelected && langOptionList){
            langOptionList.forEach(langOption => {
                if(langOption.value === seasonDetail.language_id){
                    setLangOptionSelected(langOption)
                }
            });
        }
        setFormData(prevState => ({
            ...prevState,
            language: langOptionSelected?.value
        }))
     }, [langOptionSelected, seasonDetail, langOptionList])

    return (
       <>
        {seasonDetail &&  
        <Container>
            <Row>
                <Col sm={12}>
                    <div className='publisher-card'>
                        <div className='publisher-card-header justify-content-between'>
                            <h4>Edit Show</h4>
                        </div>
                        <div className='publisher-card-body'>
                            <Form method='POST' encType='multipart/form-data' onSubmit={handleSubmit}>
                                <Row className='form_publisher-fields'>
                                    <FormGroup className='col-md-4'>
                                        <Form.Label htmlFor='title'>Title </Form.Label>
                                        <Form.Control type='text' placeholder={seasonDetail?.name} name='title' value={formData.title || "Show Title"} onChange={handleInputChange} />
                                    </FormGroup>
                                    {languages?.length ? <Controller
                                                control={control}
                                                name="mylang"
                                                render={({ field }) => (
                                                    <Form.Group {...field} className='col-md-4'>
                                                        <Form.Label htmlFor='reactSelect-lang-edit'>Languages</Form.Label>
                                                        <ReactSelect
                                                                maxMenuHeight={200}
                                                                placeholder="Choose A Language"
                                                                styles={colorStyles}
                                                                id='reactSelect-lang-edit'
                                                                options={langOptionList}
                                                                closeMenuOnSelect={false}
                                                                hideSelectedOptions={false}
                                                                components={{
                                                                    Option
                                                                }}
                                                                onChange={setLangOptionSelected}
                                                                value={langOptionSelected}
                                                                
                                                            />
                                                    </Form.Group>
                                                )}  
                                            />
                                            : "No Languages"
                                            }
                                    <Controller
                                                control={control}
                                                name="mygenre"
                                                render={({ field }) => (
                                                    <Form.Group {...field} className='col-md-4'>
                                                        <Form.Label htmlFor='reactSelect-genre-edit'>Categories</Form.Label>
                                                        <ReactSelect
                                                                maxMenuHeight={300}
                                                                placeholder="Choose A Category"
                                                                styles={colorStyles}
                                                                id='reactSelect-genre-edit'
                                                                options={genreOptionList}
                                                                isMulti
                                                                closeMenuOnSelect={false}
                                                                hideSelectedOptions={false}
                                                                components={{
                                                                    Option
                                                                }}
                                                                onChange={setOptionSelected}
                                                                allowSelectAll={true}
                                                                value={optionSelected}
                                                                
                                                            />
                                                    </Form.Group>
                                                )}  
                                            />
                                    <Col lg={8} className='pt-2'>
                                        <TrailerUpload handleTrailerUpload={handleTrailerUpload} trailer={seasonDetail?.trailer}></TrailerUpload>
                                    </Col>
                                    <Col lg={4} className='pt-2'>
                                        <PortraitUpload handleImageUpload={handleImageUpload} originalPortraitImg={formData.portrait_image}></PortraitUpload>
                                    </Col>
                                    <FormGroup className='col-4 mt-5 pt-2'>
                                    <Form.Label htmlFor='release_date'>Original Release Date: </Form.Label>
                                        <DatePicker 
                                        selected={formData?.release_date} 
                                        value={formData?.release_date} 
                                        onChange={(date) => {
                                            setFormData(prevState => ({
                                                ...prevState,
                                                release_date: date
                                            }))
                                            }} 
                                        />
                                    </FormGroup>
                                    <Col md={{ span: 4, offset: 4 }} className='pt-2'>
                                        <LandscapeUpload handleImageUpload={handleImageUpload} originalLandscapeImg={formData.landscape_image}></LandscapeUpload>
                                    </Col>
                                    <Col className='col-12 pt-2'>
                                        <Form.Label htmlFor='description'>Description</Form.Label>
                                        <Form.Control placeholder={seasonDetail.description} as='textarea' name='description' rows={5} value={formData.description} onChange={handleInputChange} />
                                    </Col>
                                </Row>
                                <Row>
                                    <FormGroup className='col-12'>
                                        <button type='submit' className='btn btn-primary form_video-upload_button'>Update</button>
                                    </FormGroup>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>}
       </>
    )
}

export default EditTVShow
