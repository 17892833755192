import { Link } from 'react-router-dom';

const EditContentIcon = ({setSidebarComponent, contentId, switchComponent}) => {
    return ( 
        <Link to='#'>
            <i
            onClick={() => setSidebarComponent(switchComponent, contentId)}
            className="ri-pencil-line ri-xl" 
            style={{
                backgroundColor: 'rgb(57,90,66)', 
                borderRadius: '5px', 
                color: "rgb(17,231,136)"
                }}></i>
        </Link>
     );
}
 
export default EditContentIcon;