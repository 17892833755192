import { Box, TextField } from '@mui/material';
import { InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

function CustomInput(props) {

    const {
        register,
        name,
        errorMesage,
        required,
        placeholder,
        error,
        watchPassword,
        type,
        mouseUpPassword,
        mouseDownPassword,
        showPassword
    } = props || {}

    const validateConfirmPassword = (val) => {
        if (watchPassword !== val)
            return false
    }

    return (
        <Box
            component="form"
            sx={{
                '& > :not(style)': { m: 2, width: '40ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <TextField
                {...register(name, {
                    required,
                    pattern: name === 'email' ? /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/ : undefined,
                    validate: name === 'confirmPassword' ? validateConfirmPassword : undefined,
                })}
                type={type ? type : "text"}
                key={placeholder}
                label={placeholder}
                className='custom-field'
                id="outlined-basic"
                variant="outlined"
                InputProps={type ? ({ 
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onMouseUp={mouseUpPassword}
                          onMouseDown={mouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  })
                  : ""
                }
            />
            {error &&
                <div className="text text-danger error-text" style={{color: 'red'}}>
                    {errorMesage}
                </div>
            }
        </Box>
    )
}

export default CustomInput