import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, FormGroup } from 'react-bootstrap'
import TrailerUpload from './components/TrailerUpload'
import PortraitUpload from './components/PortraitUpload'
import LandscapeUpload from './components/LandscapeUpload'
import DatePicker from 'react-datepicker'
import { GetPublisherShowList } from '../../store/Actions/userActions'
import { useSelector, useDispatch } from 'react-redux';
import { CreateSeason } from '../../store/Actions/movieActions'
// import { useHistory } from 'react-router-dom';
import moment from "moment";

const AddNewSeason = () => {
    // const history = useHistory()
    const dispatch = useDispatch()
    const { publishedShows } = useSelector((state) => state.user);
    const [formData, setFormData] = useState({
        title: '',
        tv_show: '',
        portrait_image: null,
        landscape_image: null,
        trailer: null,
        release_date: new Date(),
        description: ''
    })

    const handleTrailerUpload = event => {
        setFormData(prevState => ({
            ...prevState,
            trailer: event.target.files[0]
        }))
    }

    const handleImageUpload = event => {
        const { name } = event.target
        setFormData(prevState => ({
            ...prevState,
            [name]: event.target.files[0]
        }))
    }

    const handleInputChange = event => {
        const { name, value } = event.target
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const handleSubmit = event => {
        event.preventDefault()
        let newFormat = moment.utc(formData.release_date).format("ddd MMM D YYYY HH:mm:ss [UTC]ZZ")
        console.log({newFormat})
        // const checkedData = validateAndSanitizeShowData(formData)
        dispatch(CreateSeason(formData, newFormat))
    }
    
    useEffect(() => {
        dispatch(GetPublisherShowList())
    }, [])

    return (
        <Container>
            <Row>
                <Col sm={12}>
                    <div className='publisher-card'>
                        <div className='publisher-card-header justify-content-between'>
                            <h4>Add New Season</h4>
                        </div>
                        <div className='publisher-card-body'>
                            <Form method='POST' encType='multipart/form-data' onSubmit={handleSubmit}>
                                <Row>
                                    <Col md={8}>
                                        <TrailerUpload handleTrailerUpload={handleTrailerUpload}></TrailerUpload>
                                    </Col>
                                    <Col lg={4}>
                                        <PortraitUpload handleImageUpload={handleImageUpload}></PortraitUpload>
                                    </Col>
                                </Row>
                                <Row style={{ margin: '48px -15px' }}>
                                    <Col lg={8}>
                                        <Row className='form_publisher-fields'>
                                            <Form.Group className='col-md-6'>
                                                <Form.Label htmlFor='title'>Title (required)</Form.Label>
                                                <Form.Control type='text' name='title' onChange={handleInputChange} required />
                                            </Form.Group>
                                            <Form.Group className='col-md-6'>
                                                <Form.Label htmlFor='tv_show'>Show (required)</Form.Label>
                                                <Form.Control as='select' name='tv_show' onChange={handleInputChange} required >
                                                    <option>Choose A Show</option>
                                                    {publishedShows.map(show => {
                                                        return <option value={show.id} key={show.id}>{show.title}</option>
                                                    })}
                                                </Form.Control>
                                            </Form.Group>
                                            <Form.Group className='col-md-6'>
                                                <Form.Label htmlFor='release_date'>Release Date (required)</Form.Label>
                                                <DatePicker selected={formData.release_date} value={formData.release_date} onChange={(date) => {
                                                    setFormData(prevState => ({
                                                        ...prevState,
                                                        release_date: date
                                                    }))
                                                }} />
                                            </Form.Group>
                                            <Form.Group className='col-md-12'>
                                                <Form.Label htmlFor='description'>Description (required)</Form.Label>
                                                <Form.Control as='textarea' name='description' rows={5} onChange={handleInputChange} required />
                                            </Form.Group>
                                        </Row>
                                    </Col>
                                    <Col lg={4}>
                                        <LandscapeUpload handleImageUpload={handleImageUpload}></LandscapeUpload>
                                    </Col>
                                </Row>
                                <Row>
                                    <FormGroup className='col-12 text-center'>
                                        <button type='submit' className='btn btn-primary form_video-upload_button'>Submit</button>
                                    </FormGroup>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default AddNewSeason
