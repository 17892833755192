import React from 'react';
import '../../../../../assets/css/custom/mainScreen.css';
import Background1 from './Background1';
import ContactUs from './ContactUs';
import DownloadYourShows from './DownloadYourShows';
import EnjoyYourTv from './EnjoyYourTv';
import FAQs from './FAQs';
import Footer from './Footer';

function LandingPage() {
    return (
        <>
            <Background1 />
            <DownloadYourShows />
            <div className='gray-line' />
            <EnjoyYourTv />
            <div className='gray-line' />
            <div className='main__screen__container'>
                <FAQs />
            </div>
            <div className='gray-line' />
            <div className='contact-us-container'>
                <ContactUs />
            </div>
            <Footer />
        </>

    )
}

export default LandingPage  