import React from 'react'
import { useEffect } from 'react'
import SidebarStyle from '../../../components/partials/backend/sidebarstyle/sidebarstyle'
import { useDispatch } from 'react-redux';
import { VerifyActiveSubscription } from './../../../store/Actions/userActions';

const PublisherPage = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(VerifyActiveSubscription())
    }, [])

    return (
        <div className='main-content'>
            <SidebarStyle/>
        </div>
    )
}

export default PublisherPage
