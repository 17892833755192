import React, { useState } from 'react'
import { Form } from 'react-bootstrap'

import previewImage from '../../../assets/images/custom/m.png'

const PortraitUpload = ({ handleImageUpload, originalPortraitImg, short }) => {
    const [uploadImg, setUploadImg] = useState(null)

    const handleUpload = event => {
        setUploadImg(URL.createObjectURL(event.target.files[0]))
        handleImageUpload(event)
    }
    let portraitImage = short ? `https://multitude.s3.wasabisys.com/lg/${originalPortraitImg}` : originalPortraitImg
    const backgroundImage = uploadImg ? uploadImg : portraitImage || previewImage;
    return (
        <div className='form_video-upload text-center' id='portraitDiv' style={{ backgroundImage: `url(${backgroundImage})` }}>
            <br /><br /><br />
            <br /><br /><br /><br />
            <Form.Group controlId='portraitFile' style={{ margin: 0 }}>
                <Form.Label className='btn btn-primary form_video-upload_button' style={{ margin: 0 }}>
                    Upload Portrait Image
                    <Form.Control type="file" name='portrait_image' accept='.png, .jpg, .jpeg' onChange={handleUpload} hidden/>
                </Form.Label>
            </Form.Group>
            <br /><br />
        </div>
    )
}

export default PortraitUpload
