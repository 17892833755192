import React from "react";
import { useSpring, animated } from '@react-spring/web'
import "../../../../../assets/css/custom/background1.css";
import logo from "../../../../../assets/images/custom/multitudeWhiteLogo.png";
import Join from "./Join";
import GetNowBtn from "./GetNowBtn";

function Background1() {
  const springs = useSpring({
    delay: 300,
    from: { y: 700,
      opacity: 0 },
    to: { y: 0,
      opacity: 1},
    config: {
      mass: 5,
      friction: 50,
      tension: 160,
    },
  })
  return (
    <div className="hero__section__container ">
      <div className="container-fluid navbar ">
        <div className="navbar--logo">
          <img
            className="img-fluid logo landing-logo"
            src={logo}
            alt="multitude"
          />
        </div>
        
        <GetNowBtn />
      </div>
      <animated.div
        style={{...springs}}
      >
        <div className="hero__section--text ">
          <h1 className="hero__section--text-header">Made for Makers.</h1>
          <h1 className="hero__section--text-header-secondary">
            Currently in open beta. Join, watch, and create.
          </h1>
          <Join />
        </div>
      </animated.div>
    </div>
  );
}

export default Background1;
