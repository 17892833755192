import React, {useState, useEffect } from 'react'
import { Container, Row, Col, Form, FormGroup } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { formatTime } from '../../utils/DateUtils'
import PortraitUpload from './components/PortraitUpload';
import LandscapeUpload from './components/LandscapeUpload';
import { useHistory } from 'react-router-dom';
import { episodeEdit, fetchEpisodeInfo } from './../../store/Actions/movieActions';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment'
import TrailerUpload from './components/TrailerUpload';

const EditEpisode = ({ episodeId }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { episodeToEdit } = useSelector(state => state.movie)

    const [formData, setFormData] = useState({
        title: '',
        tv_show: '',
        tv_show_id: 0,
        season: '',
        season_id: 0,
        episode_number: '',
        portrait_image: null,
        landscape_image: null,
        release_date: '',
        release_time: '',
        description: '',
        video: "",
        id: ""
    })
    const [selectDate, setSelectDate] = useState(new Date())
    
    const uploadVideo = () => {
        history.push({
            pathname: '/upload',
            state: {
                episodeTitle: formData.title,
                episodeId: formData.id
            }
        })
    }

    const handleTrailerUpload = event => {
        setFormData(prevState => ({
            ...prevState,
            trailer: event.target.files[0]
        }))
    }

    const handleFileInput = event => {
        const { name } = event.target
        
        setFormData(prevState => ({
            ...prevState,
            [name]: event.target.files[0]
        }))
    }

    const handleInputChange = event => {
        const { name, value } = event.target

        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const handleSubmit = event => {
        event.preventDefault()
        // const checkedData = validateAndSanitizeShowData(formData)
        dispatch(episodeEdit(formData, episodeId, selectDate))
    }

    useEffect(() => {
        dispatch(fetchEpisodeInfo(episodeId))
    }, [dispatch, episodeId])

    useEffect(() => {
        setFormData(prevState => ({
            ...prevState,
            title: episodeToEdit.title,
            tv_show: episodeToEdit.show_title,
            tv_show_id: episodeToEdit.tv_show_id,
            season: episodeToEdit.season_title,
            season_id: episodeToEdit.season_id,
            episode_number: episodeToEdit.episode_number,
            portrait_image: episodeToEdit.portrait_image,
            landscape_image: episodeToEdit.landscape_image,
            release_date: episodeToEdit.release_date,
            release_time: episodeToEdit.release_time,
            description: episodeToEdit.description,
            video: episodeToEdit.video,
            id: episodeToEdit.id
        }))
        if(episodeToEdit.release_date && episodeToEdit.release_time){
            setSelectDate(new Date(moment(new Date(episodeToEdit.release_date + " " + episodeToEdit.release_time)).format("ddd MMM D YYYY HH:mm:ss")))
        }
    }, [episodeToEdit])

    return (
        <Container>
            <Row>
                <Col sm={12}>
                    <div className='publisher-card'>
                        <div className='publisher-card-header justify-content-between'>
                            <h4>Edit Episode</h4>
                        </div>
                        {formData.id && !formData.video && (
                            <div className='upload-episode-edit'><button className='btn btn-primary form_video-upload_button' onClick={uploadVideo}>Upload Video</button></div>
                        )}
                        <div className='publisher-card-body'>
                            <Form method='POST' encType='multipart/form-data' onSubmit={handleSubmit}>
                                <Row className='form_publisher-fields'>
                                    <FormGroup className='col-md-6'>
                                        <Form.Label htmlFor='title'>Title</Form.Label>
                                        <Form.Control type='text' name='title' onChange={handleInputChange} value={formData.title || "No Title"} />
                                    </FormGroup>
                                    <FormGroup className='col-md-6'>
                                        <Form.Label htmlFor='tv_show'>Show</Form.Label>
                                        <Form.Control type='text' name='tv_show' value={formData.tv_show || "No Show"} disabled />
                                    </FormGroup>
                                    <FormGroup className='col-md-6'>
                                        <Form.Label htmlFor='season'>Season</Form.Label>
                                        <Form.Control type='text' name='season' value={formData.season || "No Season"} disabled />
                                    </FormGroup>
                                    <FormGroup className='col-md-6'>
                                        <Form.Label htmlFor='episode_number'>Episode No.</Form.Label>
                                        <Form.Control type='text' name='episode_number' value={formData.episode_number || 0} disabled />
                                    </FormGroup>
                                    <Col lg={8} className='pt-2'>
                                        <TrailerUpload handleTrailerUpload={handleTrailerUpload} trailer={formData?.trailer}></TrailerUpload>
                                    </Col>
                                    <Col lg={4} className='pt-2'>
                                        <LandscapeUpload handleImageUpload={handleFileInput} originalLandscapeImg={formData.landscape_image} short={true}></LandscapeUpload>
                                        <PortraitUpload handleImageUpload={handleFileInput} originalPortraitImg={formData.portrait_image} short={true}></PortraitUpload>
                                    </Col>
                                    <FormGroup className='col-md-4 pt-2'>
                                        <Form.Label htmlFor='release_date'>Publish Date</Form.Label>
                                        <DatePicker
                                        selected={selectDate}
                                        value={selectDate} 
                                        required 
                                        onChange={(date) => {
                                            setFormData(prevState => ({
                                                ...prevState,
                                                release_date: date
                                            }))
                                            setSelectDate(date)
                                        }} 
                                        />
                                    </FormGroup>
                                                {/* <Col lg={4} className='pt-2'>
                                                </Col> */}
                                    <FormGroup className='col-md-4 pt-2'>
                                        <Form.Label htmlFor='release_date'>Publish Time</Form.Label>
                                        <DatePicker 
                                        selected={selectDate} 
                                        value={`${formatTime(selectDate)}`} 
                                        showTimeSelect 
                                        showTimeSelectOnly 
                                        required 
                                        onChange={(date) => {
                                            setFormData(prevState => ({
                                                ...prevState,
                                                release_time: date
                                            }))
                                            setSelectDate(date)
                                        }} 
                                        />
                                    </FormGroup>
                                    <FormGroup className='col-8 pt-2'>
                                        <Form.Label htmlFor='description'>Description</Form.Label>
                                        <Form.Control as='textarea' name='description' value={formData.description || "No Description"} onChange={handleInputChange} rows={5} />
                                    </FormGroup>
                                </Row>
                                <Row>
                                    <FormGroup className='col-12'>
                                        <button type='submit' className='btn btn-primary form_video-upload_button'>Submit</button>
                                    </FormGroup>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default EditEpisode
