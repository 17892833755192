import { Typography, InputAdornment, IconButton } from '@mui/material';
import TextField from '@mui/material/TextField';
import React, {useState} from 'react';
import {  useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import '../../../../assets/css/signIn.css';
import { ChangeForgotPasswordModal, Login } from '../../../../store/Actions/userActions';
import ForgotPasswordModal from './ForgotPasswordModal';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import loader from '../../../../assets/custom-assets/loader.gif'

function SignIn(props) {

    const defaultValues = {
        email: '',
        password: '',
    }

    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const mouseUpPassword = () => setShowPassword(!showPassword);
    const mouseDownPassword = () => setShowPassword(!showPassword);

    const dispatch = useDispatch()
    const history = useHistory();

    const { userData } = useSelector((state) => state.shared)

    const { formState: { errors }, register } = useForm([
        defaultValues,
    ])

    const { ref: emailRef } = register("email", {
        required: "Enter A Valid Email Address"
      });
      const { ref: passwordRef} = register("password", {
        required: "Enter A Password"
      });
    

    function onLogin(e) {
        e.preventDefault()
        dispatch(Login(email, password, history))
    }

    return (
        <>
            <div className='signIn__container'>
                {userData?.is_subscribed !== true ?
                    <div className='signIn__container-inner signin-inner'>
                        <Typography
                            variant='h5'
                        >
                            {"Log In"}
                        </Typography>
                        <form onSubmit={(e) => {onLogin(e)}} className='text__feilds' >
                            <div className='row px-5'>
                                <TextField
                                    name='email'
                                    style={{width: "275px"}}
                                    InputLabelProps={{ style: { color: '#ffca00' } }}
                                    label="Email"
                                    required
                                    error={!!errors.email}
                                    helperText={errors?.email?.message}
                                    inputRef={emailRef}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    />
                            </div>
                            <div className="row px-5">
                                <TextField
                                    name='password'
                                    type={showPassword ? "text" : "password"}
                                    InputLabelProps={{ style: { color: '#ffca00' } }}
                                    label="Password"
                                    required
                                    error={!!errors.password}
                                    helperText={errors?.password?.message}
                                    inputRef={passwordRef}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    InputProps={({ 
                                        endAdornment: (
                                            <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onMouseUp={mouseUpPassword}
                                                onMouseDown={mouseDownPassword}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                            </InputAdornment>
                                        )
                                        })
                                    }
                                />
                            </div>
                            <div className="text-center">
                                <button
                                    className='btn btn-hover iq-button iq-button text-medium navbar--button signup-button'
                                    type="submit"
                                    style={{ color: 'white', width: '30%' }}
                                    >
                                    Log In
                                </button>
                            </div>
                        </form>
                        <p
                            onClick={() => dispatch(ChangeForgotPasswordModal(true))}
                            className='forgot-password'
                            style={{
                                color: 'white',
                                marginBottom: 0,
                                marginTop: 20,
                                fontStyle: 'italic',
                                cursor: 'pointer',
                            }}
                        >
                            Forgot Password?
                        </p>
                        <p className='signup__text' style={{ color: 'white', }}>
                            Don't have an account?
                            <Link style={{ paddingLeft: 10 }} to="/sign-up" >
                                <span
                                    style={{
                                        fontSize: 16
                                    }}
                                    className="signup__anchor-tag"
                                >
                                    {"Sign Up"}
                                </span>
                            </Link>
                        </p>
                    </div>
                    :
                    <div>
                        <img
                            alt="loader"
                            src={loader}
                            style={{
                                height: 150,
                                width: 150,
                                borderRadius: 5,
                            }}
                        />
                    </div>
                }
            </div >
            <ForgotPasswordModal />
        </>
    )
}

export default SignIn
