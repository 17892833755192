import { gsap } from "gsap";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import SwiperCore, { EffectFade, Navigation, Pagination, Thumbs } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import RenderPopularSeasons from "../movie/helperComponents/RenderPopularSeasons";
import RenderPopularHorrorShows from "../movie/helperComponents/RenderPopularHorrorShows";
import RenderContinueWatching from "../movie/helperComponents/RenderContinueWatching";
import RenderRecommendedShows from "../movie/helperComponents/RenderRecommendedShows";
import RenderUpComingShows from "../movie/helperComponents/RenderUpComingShows";
import RenderGenreSections from "../movie/helperComponents/RenderGenreSections";
import RenderTopShow from "../movie/helperComponents/RenderTopShow";
import RenderShowSection from "../movie/helperComponents/RenderShowSection";
import { GetAllData, GetContinueWatchingShows, GetPosters } from "../../../store/Actions/movieActions";
import { VerifyActiveSubscription } from "../../../store/Actions/userActions";
import { useDispatch } from "react-redux";
import loader from '../../../assets/custom-assets/loader.gif'

SwiperCore.use([EffectFade, Navigation, Thumbs, Pagination]);

const gsapAnimate = {
  getData: (elem) => {
    const option = {
      opacity: 0,
      scale: 1,
      position: {
        x: 0,
        y: 0,
      },
      ease: "",
      duration: 1,
      delay: 0.4,
      rotate: 0,
    };
    if (elem !== undefined) {
      option.position.x = gsapAnimate.validValue(elem.dataset.iqPositionX, 0);
      option.position.y = gsapAnimate.validValue(elem.dataset.iqPositionY, 0);
      option.rotate = gsapAnimate.validValue(elem.dataset.iqRotate, 0);
      option.scale = gsapAnimate.validValue(elem.dataset.iqScale, 1);
      option.opacity = gsapAnimate.validValue(elem.dataset.iqOpacity, 0);
      option.delay = gsapAnimate.validValue(elem.dataset.iqDelay, 0.4);
      option.duration = gsapAnimate.validValue(elem.dataset.iqDuration, 1.5);
      option.ease = gsapAnimate.validValue(elem.dataset.iqEase, "");

      const setOption = {
        opacity: option.opacity,
        scale: option.scale,
        x: option.position.x,
        y: option.position.y,
        ease: option.ease,
        rotate: option.rotate,
        duration: option.duration,
        delay: option.delay,
      };

      return setOption;
    } else {
      return { opacity: 0 };
    }
  },
  onStart: (elem) => {
    const setOption = gsapAnimate.getData(elem);
    gsap.from(elem, setOption);
  },
  onEnd: (elem) => {
    const setOption = gsapAnimate.getData(elem);
    gsap.to(elem, setOption);
  },
  onStartEnd: (elem) => {
    const setOption = gsapAnimate.getData(elem);
    const setEndOption = gsapAnimate.getData(elem);
    setEndOption.opacity = 1;
    setEndOption.x = 0;
    setEndOption.y = 0;
    setEndOption.rotate = 0;
    setEndOption.scale = 1;
    gsap.fromTo(elem, setOption, setEndOption);
  },
  validValue: (attr, defaultVal) => {
    if (attr !== undefined && attr !== null) {
      return Number(attr);
    }
    return Number(defaultVal);
  },
};

const Homepage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    genreSections,
    posters,
    popularMovies,
    recommendedShows,
    continueWatching,
    upComingShows,
    popularHorrorshows,
  } = useSelector((state) => state.movie);
  const [show, setShow] = useState();
  const { userData } = useSelector((state) => state.shared);

  useEffect(() => {
    dispatch(GetPosters());
    dispatch(GetContinueWatchingShows());
    dispatch(VerifyActiveSubscription(history));
    dispatch(GetAllData());
  }, [dispatch, history]);

  useEffect(() => {
    let timer1 = setTimeout(() => setShow(true), 6000);
    return () => {
      clearTimeout(timer1);
    };
  }, []);

  return (!posters.topShow || posters.topShow?.length === 0) &&
    continueWatching?.length === 0 &&
    popularMovies?.length === 0 &&
    (!posters.middleShow || posters.middleShow?.length === 0) &&
    genreSections?.length > 0 &&
    popularHorrorshows?.length === 0 &&
    (!posters?.bottom_show || posters?.bottom_show?.length === 0) &&
    upComingShows?.length === 0 &&
    recommendedShows?.length === 0 ? (
    <div>
      {!show ? (
        <img
          alt="loader"
          src={loader}
          style={{
            height: 50,
            width: 50,
            borderRadius: 5,
            marginLeft: "-20%",
          }}
        />
      ) : (
        "No Seasons Found"
      )}
    </div>
  ) : (
    <>
      <section id="home" className="iq-main-slider p-0 iq-rtl-direction">
        {posters.topShow && posters.topShow?.length !== 0 && (
          <RenderTopShow show={posters.topShow} />
        )}
      </section>
      <div
        className="main-content"
        style={{
          paddingTop: popularMovies.length === 0 ? 0 : 40,
        }}
      >
        {continueWatching?.length > 0 && (
          <section id="iq-tvthrillers" className="s-margin mb-5">
            <Container
              fluid
              className="bottom-shows-container custom-container"
            >
              <Row>
                <Col sm="12" className="overflow-hidden">
                  <div className="slider-title-wrapper d-flex align-items-center justify-content-between">
                    <h4 className="main-title text-xlarge">
                      {`Continue Waching for ${userData?.name}`}
                    </h4>
                  </div>
                  <div id="tvthrillers-contens">
                    <div
                      id="prev-cont"
                      className="swiper-button swiper-button-prev"
                    >
                      <i className="fa fa-chevron-left"></i>
                    </div>
                    <div
                      id="next-cont"
                      className="swiper-button swiper-button-next"
                    >
                      <i className="fa fa-chevron-right"></i>
                    </div>
                    <Swiper
                      spaceBetween={30}
                      modules={[Navigation]}
                      navigation={{
                        prevEl: "#prev-cont",
                        nextEl: "#next-cont",
                      }}
                      centeredSlides={
                        continueWatching.length < 3 ? true : false
                      }
                      breakpoints={{
                        320: { slidesPerView: 2 },
                        550: { slidesPerView: 4 },
                        991: { slidesPerView: 5 },
                        1400: { slidesPerView: 6 },
                      }}
                      className="favorites-slider list-inline row p-0 m-0 iq-rtl-direction"
                    >
                      {continueWatching.map((episode, key) => {
                        return (
                          <SwiperSlide
                            key={key}
                            className="custom-swap-slide"
                            as="li"
                          >
                            <RenderContinueWatching episode={episode} />
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        )}
        {popularMovies?.length > 0 && (
          <section id="iq-favorites vertial-cards-section">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12 ">
                  <div className="favorites-contetns vertial-cards-div">
                    <ul className="favorites-slider list-inline  row p-0 mb-0 iq-rtl-direction">
                      <li
                        className="red-border separate-container col-2  d-flex flex-column  justify-content-center align-items-center slide-item popular-seasons-container"
                        style={{ paddingRight: "40px !important" }}
                      >
                        <h3 className="popular-seasons-heading">
                          Popular Shows
                        </h3>
                        <p className="popular-seasons-text popular-seasons-text border border-top-0 border-left-0 border-right-0 border-bottom-secondary mt-3">
                          Most Watched Seasons By Day
                        </p>
                        <Link to="/trending">
                          <p className="iq-view-all">View All</p>
                        </Link>
                      </li>
                      <RenderPopularSeasons popularMovies={popularMovies} />
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        {posters.middleShow && posters.middleShow?.length !== 0 && (
          <RenderShowSection
            popularMovies={popularMovies}
            show={posters.middleShow}
          />
        )}

        {genreSections?.length > 0 && (
          <RenderGenreSections genreSections={genreSections} />
        )}

        {popularHorrorshows?.length > 0 && (
          <section
            style={{ marginTop: 50 }}
            id="iq-favorites vertial-cards-section"
          >
            <div style={{}} className="container-fluid">
              <div className="row">
                <div className="col-sm-12 ">
                  <div className="favorites-contetns vertial-cards-div">
                    <ul className="favorites-slider list-inline  row p-0 mb-0 iq-rtl-direction">
                      <li
                        className="red-border separate-container col-2  d-flex flex-column  justify-content-center align-items-center slide-item popular-seasons-container"
                        style={{ paddingRight: "40px !important" }}
                      >
                        <h3 className="popular-seasons-heading">
                          Horror Shows
                        </h3>
                        <p className="popular-seasons-text popular-seasons-text border border-top-0 border-left-0 border-right-0 border-bottom-secondary mt-3">
                          Most Watched Horror Seasons
                        </p>
                        {/* <Link to="/trending">
                                       <a className="iq-view-all" >View All</a>
                                    </Link> */}
                      </li>
                      <RenderPopularHorrorShows
                        popularHorrorshows={popularHorrorshows}
                      />
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        {posters?.bottom_show &&
          posters?.bottom_show?.length !== 0 && ( //todo: make this dynamic when bottom shows is added in API
            <RenderShowSection
              popularMovies={popularMovies}
              show={posters.bottom_show}
            />
          )}
        {upComingShows?.length > 0 && (
          <section id="iq-tvthrillers" className="s-margin mb-5">
            <Container
              fluid
              className="bottom-shows-container custom-container"
            >
              <Row>
                <Col sm="12" className="overflow-hidden">
                  <div className="slider-title-wrapper d-flex align-items-center justify-content-between">
                    <h4 className="main-title text-xlarge">Up Coming Shows</h4>
                    <Link className="iq-view-all" to="/new-episodes">
                      View All
                    </Link>
                  </div>
                  <div id="tvthrillers-contens">
                    <div
                      id="prev-new"
                      className="swiper-button swiper-button-prev"
                    >
                      <i className="fa fa-chevron-left"></i>
                    </div>
                    <div
                      id="next-new"
                      className="swiper-button swiper-button-next"
                    >
                      <i className="fa fa-chevron-right"></i>
                    </div>
                    <Swiper
                      spaceBetween={30}
                      modules={[Navigation]}
                      navigation={{
                        prevEl: "#prev-new",
                        nextEl: "#next-new",
                      }}
                      centeredSlides={upComingShows.length < 3 ? true : false}
                      breakpoints={{
                        320: { slidesPerView: 2 },
                        550: { slidesPerView: 4 },
                        991: { slidesPerView: 5 },
                        1400: { slidesPerView: 6 },
                      }}
                      as="ul"
                      className="favorites-slider list-inline row p-0 m-0 iq-rtl-direction"
                    >
                      {upComingShows.map((show, key) => {
                        return (
                          <SwiperSlide
                            key={key}
                            className="custom-swap-slide"
                            as="li"
                          >
                            <RenderUpComingShows show={show} />
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        )}
        {recommendedShows?.length > 0 && (
          <section id="iq-tvthrillers" className="s-margin mb-5">
            <Container
              fluid
              className="bottom-shows-container custom-container"
            >
              <Row>
                <Col sm="12" className="overflow-hidden">
                  <div className="slider-title-wrapper d-flex align-items-center justify-content-between">
                    <h4 className="main-title text-xlarge">
                      Recommended For You
                    </h4>
                    <Link className="iq-view-all" to="/new-episodes">
                      View All
                    </Link>
                  </div>
                  <div id="tvthrillers-contens">
                    <div
                      id="prev-3"
                      className="swiper-button swiper-button-prev"
                    >
                      <i className="fa fa-chevron-left"></i>
                    </div>
                    <div
                      id="next-3"
                      className="swiper-button swiper-button-next"
                    >
                      <i className="fa fa-chevron-right"></i>
                    </div>
                    <Swiper
                      spaceBetween={30}
                      modules={[Navigation]}
                      navigation={{
                        prevEl: "#prev-3",
                        nextEl: "#next-3",
                      }}
                      centeredSlides={
                        recommendedShows.length < 3 ? true : false
                      }
                      breakpoints={{
                        320: { slidesPerView: 2 },
                        550: { slidesPerView: 4 },
                        991: { slidesPerView: 5 },
                        1400: { slidesPerView: 6 },
                      }}
                      as="ul"
                      className="favorites-slider list-inline row p-0 m-0 iq-rtl-direction"
                    >
                      {recommendedShows.map((show, key) => {
                        return (
                          <SwiperSlide
                            key={key}
                            className="custom-swap-slide"
                            as="li"
                          >
                            <RenderRecommendedShows show={show} />
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        )}
      </div>
    </>
  );
};

export default Homepage;
