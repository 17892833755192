import React from 'react'
import { Spinner } from 'react-bootstrap';
import Loader from "react-loader-spinner";

import '../assets/css/spinner.css'

const SpinnerLoader = () => {
    return (
        <div className="spinner-container">
            {/* <Spinner animation="border" variant="warning" /> */}
            <Loader
                type="TailSpin"
                color="#EDB500"
                height={60}
                width={60}
            // timeout={3000} //! we're hiding through
            />
        </div>
    )
}

export default SpinnerLoader