import React from 'react'
import AuthPages from '../router/auth-pages-route'

const BlankLayout = () => {

    return (
        <>
            <div className="">
                <AuthPages />
            </div>
        </>
    )
}

export default BlankLayout;