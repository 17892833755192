import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
    publicKey
} from '../../../../config/apiKeys';
import CheckoutForm from './Components/CheckoutForm';
import { useHistory } from 'react-router-dom';

const stripePromise = loadStripe(publicKey);

function Payment() {
    const history = useHistory()

    return (
        <Elements
            stripe={stripePromise}
        >
            <CheckoutForm history={history}/>
        </Elements>
    );
};

export default Payment