import {
  Category,
  ChartComponent,
  ColumnSeries,
  DataLabel,
  Inject,
  Legend,
  LineSeries,
  SeriesCollectionDirective,
  SeriesDirective,
  Tooltip,
} from "@syncfusion/ej2-react-charts";
import * as React from "react";
import { useSelector } from 'react-redux';


const Analytics = ({ component }) => {
  const {analyticsData} = useSelector((state) => state.user)

  //required for syncfusion chart
  const tooltip = { enable: true, shared: false };
  const primaryxAxis = { valueType: "Category", interval: 1 };
  let primaryyAxis = {
    labelStyle: { color: "white" },
    labelFormat: "${value}K",
    interval: 5,
  };
  const legendSettings = { visible: true };
  const marker = { dataLabel: { visible: true, border: {width: 1, color: '#ffca00'}, position: 'Top', font: { fontWeight: '800', color: '#ffffff' } }};

  switch (component) {
    case "ViewsData":
      primaryyAxis = { labelFormat: "{value}K", interval: 10 };
      component = (
        <SeriesDirective
          dataSource={analyticsData[0]}
          xName="month"
          yName="views"
          name="Views"
          marker={marker}
        />
      );
      break;
    case "FollowersData":
      primaryyAxis = { labelFormat: "{value}K", interval: 10 };
      component = (
        <SeriesDirective
          dataSource={analyticsData[1]}
          xName="month"
          yName="followers"
          name="Followers"
          marker={marker}
        />
      );
      break;
    case "RevenueData":
      component = (
        <SeriesDirective
          dataSource={analyticsData[2]}
          xName="month"
          yName="revenue"
          name="Revenue"
          marker={marker}
        />
      );
      break;
    default:
      component = "Nothing to see here";
  }

  return (
    <ChartComponent
      id="charts"
      primaryXAxis={primaryxAxis}
      primaryYAxis={primaryyAxis}
      legendSettings={legendSettings}
      tooltip={tooltip}
    >
      <Inject
        services={[
          ColumnSeries,
          DataLabel,
          Tooltip,
          Legend,
          LineSeries,
          Category,
        ]}
      />
      <SeriesCollectionDirective>{component}</SeriesCollectionDirective>
    </ChartComponent>
  );
};
export default Analytics;

//CAN BE ADDED TO Y OR X AXIS
   // title: "hello world",
    // titleStyle: {
    //   size: "16px",
    //   color: "blue",
    //   fontFamily: "Segoe UI",
    //   fontWeight: "bold",
    // },
    // majorTickLines: {
    //   color: "blue",
    //   width: 5,
    // },
    // minorTickLines: {
    //   color: "red",
    //   width: 0,
    // },
    // majorGridLines: {
    //   color: "blue",
    //   width: 1,
    // },
    // minorGridLines: {
    //   color: "red",
    //   width: 0,
    // },