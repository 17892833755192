import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const PublisherCard = ({
    title,
    children,
    currentPage,
    totalPages,
    handleChangePage,
    itemsPerPage,
    handleChangeItemsPerPage,
    searchTerm,
    handleSearchChange,
    totalEntries,
}) => {
  return (
    <Container fluid>
      <Row>
        <Col sm={12}>
          <div className="publisher-card">
            <div className="publisher-card-header justify-content-between">
              <h4>{title}</h4>
            </div>
            <div className="publisher-card-body">
              <div className="table-view">
                <Row>
                  <Col sm={12} md={12}>
                    <div className="dataTables_filter">
                      <label>
                        Search:{" "}
                        <input
                          type={`search`}
                          className="form-control form-control-sm"
                          value={searchTerm}
                          onChange={handleSearchChange}
                        ></input>
                      </label>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6} md={6}>
                    <div className="dataTables_length">
                      <label>
                        Show
                        <select
                          className="custom-select custom-select-sm"
                          value={itemsPerPage}
                          onChange={handleChangeItemsPerPage}
                        >
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                        Entries
                      </label>
                    </div>
                  </Col>
                  <Col sm={6} md={6}>
                    <div className="dataTables_paginate">
                      <ul className="pagination">
                        <li
                          className={`paginate_button ${
                            currentPage === 0 ? "disabled" : ""
                          }`}
                        >
                          <button
                            className={`btn ${
                              currentPage === 0 ? "disabled" : "btn-hover"
                            }`}
                            onClick={() =>
                              currentPage > 0 &&
                              handleChangePage(currentPage - 1)
                            }
                          >
                            Previous
                          </button>
                        </li>
                        {Array.from({ length: totalPages }, (_, i) => (
                          <li key={i} className="paginate_button">
                            <button
                              className={`btn ${
                                currentPage === i ? "selected" : "btn-hover"
                              }`}
                              onClick={() => handleChangePage(i)}
                            >
                              {i + 1}
                            </button>
                          </li>
                        ))}
                        <li>
                          <button
                            className={`btn ${
                              currentPage === totalPages - 1
                                ? "disabled"
                                : "btn-hover"
                            }`}
                            onClick={() => {
                              currentPage < totalPages - 1 &&
                                handleChangePage(currentPage + 1);
                            }}
                          >
                            Next
                          </button>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>{children}</Col>
                </Row>
                <Row>
                  <Col sm={12} md={5}>
                    <div className="dataTables_info">
                     {totalEntries > 0 ? `Showing ${currentPage * itemsPerPage + 1} to ${" "}
                      ${(currentPage + 1) * itemsPerPage >= totalEntries
                        ? totalEntries
                        : (currentPage + 1) * itemsPerPage}${" "}
                      of ${totalEntries} entries`
                      : "No entries to show"
                    }
                    </div>
                  </Col>
                  {/* <Col sm={12} md={7}>
                                        <div className='dataTables_paginate'>
                                            <ul className='pagination'>
                                                <li className={`paginate_button ${currentPage === 0 ? 'disabled' : ''}`}>
                                                    <button className='btn btn-hover' onClick={() => currentPage > 0 && handleChangePage(currentPage - 1)}>Previous</button>
                                                </li>
                                                {Array.from({ length: totalPages }, (_, i) => (
                                                    <li key={i} className='paginate_button'>
                                                        <button className='btn btn-hover' onClick={() => handleChangePage(i)}>{i + 1}</button>
                                                    </li>
                                                ))}
                                                <li 
                                                // className={`paginate_button ${currentPage === totalPages - 1 ? 'disabled' : ''}`}
                                                >
                                                    <button 
                                                    className='btn btn-hover' 
                                                    onClick={() => {
                                                        console.log("next page")
                                                        currentPage < totalPages - 1 && handleChangePage(currentPage + 1)
                                                    }}
                                                    >Next</button>
                                                </li>
                                            </ul>
                                        </div>
                                    </Col> */}
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default PublisherCard;
