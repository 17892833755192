import {baseSharingURL} from '../../../../config/baseURL';

export const CopyLink = (showId) => {
    let dummy = document.createElement('input')
    let text;
    if (showId) {
        text = baseSharingURL + showId;
    } else {
        text = window.location.href;
    }
    
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
}