import React, {useState} from 'react'
import { Form } from 'react-bootstrap'

const TrailerUpload = ({ handleTrailerUpload, trailer, short }) => {
    const [trailerFile, setTrailerFile] = useState(null)
    const [show, setShow] = useState(false)

    const handleUpload = event => {
        setTrailerFile(event.target.files[0])
        handleTrailerUpload(event)
    }

    const handleShowVideo = event => {
        event.preventDefault()
        event.stopPropagation()
        setShow(!show)
    }

    return (
        <div className='form_video-upload text-center'>
            <br />
            {!show && 
                <>
                    <span>Your Videos will be private until you publish them. </span>
                    <br /><br /><br />
                    <Form.Group controlId='trailerFile' style={{ margin: 0 }}>
                        <Form.Label className='btn btn-primary form_video-upload_button' style={{ margin: 0 }}>
                            Upload Trailer
                            <Form.Control type="file" name='trailer' onChange={handleUpload} accept='video/mp4,video/x-m4v,video/*' multiple hidden/>
                        </Form.Label>
                    </Form.Group>
                    {trailerFile?.name ? `${trailerFile.name}` : ""}
                
                    <br />
                    <br />
                    <span>1280 x 720 (with maximum width of 640 pixels).16:9 aspect ratio is preferred. Accepted formats: Jvideo/mp4,video/x-m4v,video/* in less than 20MB limit</span>
                </>
            }
            <br /><br />
            {trailer && (
                <div style={{height: "20%"}}>
                    <button 
                        type='button' 
                        onClick={(e) => handleShowVideo(e)} 
                        className="btn btn-primary form_video-upload_button"
                        style={{marginBottom: "2rem"}}
                    >
                        {show ? 'Hide Current Trailer' : 'Show Current Trailer'}
                    </button>
                    {show && (
                    <video width="100%" height="100%" controls>
                        <source src={short ? `https://multitude.s3.wasabisys.com/${trailer}` : trailer} type="video/mp4" />
                    </video>)
                    }
                </div>
            )}
        </div>
    )
}

export default TrailerUpload
