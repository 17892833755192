import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import '../../../../assets/css/edit-profile-modal.css';
import '../../../../assets/css/signUp.css';
import '../../../../assets/css/forgot-password.css';
import CustomInput from '../../../../components/CustomInput';
import {
    ChangeForgotPasswordModal,
    RecoverThePassword,
    SendForgotPasswordEmail,
    VerifyCode
} from '../../../../store/Actions/userActions';
import { useState } from 'react';

function ForgotPasswordModal(props) {

    const defaultValues = {
        email: "",
        code: "",
        password: "",
        confirmPassword: "",
    }

    const dispatch = useDispatch()
    const { register, formState: { errors }, handleSubmit, watch, reset } = useForm({
        mode: 'onSubmit',
        defaultValues,
    })

    const { forgotPasswordModal, forgotPasswordStep } = useSelector((state) => state.user)

    const [verifyCodeResult, setVerifyCodeResult] = useState(null)

    return (
        <Modal
            centered
            size={(forgotPasswordStep === 1 || forgotPasswordStep === 2) ? "" : "lg"}
            show={forgotPasswordModal}
            onHide={() => dispatch(ChangeForgotPasswordModal(false))}
            dialogClassName="modal-90w show-detail-modal"
            aria-labelledby="example-custom-modal-styling-title modal-container"

        >
            <Modal.Header className='custom-modal-header' closeButton>
                <h5 className='signup__heading'>
                    {"Forgot Pasword"}
                </h5>
            </Modal.Header>
            <Modal.Body className='modal-body forgot-pass-body'>
                <span className='forgot-pass-label'>
                    {forgotPasswordStep === 1 &&
                        "Enter email address to recover your password"
                    }
                    {forgotPasswordStep === 2 &&
                        "Enter verification code, check your email to get the code"
                    }
                    {forgotPasswordStep === 3 &&
                        "Enter you new password and confirm password to change your password"
                    }
                </span>
                <div className='row'>
                    {forgotPasswordStep === 1 &&
                        <CustomInput
                            register={register}
                            name={'email'}
                            errorMesage={'Enter valid email'}
                            required={forgotPasswordStep === 1}
                            placeholder={'Email'}
                            error={errors?.email}
                        />
                    }
                    {forgotPasswordStep === 2 &&
                        <CustomInput
                            register={register}
                            name={'code'}
                            errorMesage={'Enter verification code'}
                            required={forgotPasswordStep === 2}
                            placeholder={'Verification Code'}
                            error={errors?.code}
                        />
                    }
                    {forgotPasswordStep === 3 &&
                        <>
                            <CustomInput
                                register={register}
                                name={'password'}
                                errorMesage={'Enter password'}
                                required={forgotPasswordStep === 3}
                                placeholder={'Password'}
                                error={errors?.password}
                            />
                            <CustomInput
                                watchPassword={watch('password')}
                                register={register}
                                name={'confirmPassword'}
                                errorMesage={'Password does not match'}
                                required={forgotPasswordStep === 3}
                                placeholder={'Confirm Password'}
                                error={errors?.confirmPassword}
                            />
                        </>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer className='custom-modal-footer'>
                <div
                    className="btn btn-hover iq-button iq-button text-medium navbar--button signup-button custom-btn"
                    onClick={handleSubmit(async data => {

                        if (forgotPasswordStep === 1)
                            dispatch(SendForgotPasswordEmail(data))
                        else if (forgotPasswordStep === 2) {
                            let result = await dispatch(VerifyCode(data))
                            setVerifyCodeResult(result)
                        }
                        else if (forgotPasswordStep === 3) {

                            const { data: { data: { email, token } } } = verifyCodeResult || {}

                            let result1 = await dispatch(RecoverThePassword({ ...data, email, token }))
                            if (result1?.status >= 200 && result1?.status < 300) {

                                reset(defaultValues)

                            }
                        }
                        else
                            console.error("Wrong forgotPasswordStep")
                    })}
                >
                    {(forgotPasswordStep === 1 || forgotPasswordStep === 2) &&
                        "Next"
                    }
                    {forgotPasswordStep === 3 &&
                        "Done"
                    }
                </div>
            </Modal.Footer>
        </Modal >
    )
}

export default ForgotPasswordModal