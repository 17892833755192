import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import '../../../../assets/css/signUp.css'
import { Signup } from '../../../../store/Actions/userActions'
import validateAndSanitizeSignUpForm from '../../../../validation/signUpValidation'
import loader from '../../../../assets/custom-assets/loader.gif'

function SignUp() {
    const dispatch = useDispatch()
    const history = useHistory();

    const defaultValues = {
        name: "",
        email: "",
        password: "",
        confirmtPassword: "",
    }

    const { control, formState: { errors }, handleSubmit, watch, setValue } = useForm({
        mode: 'onSubmit',
        defaultValues,
    })

    const { userData, getStartedEmail } = useSelector((state) => state.shared)

    function onSignUp(data) {
        const checkedData = validateAndSanitizeSignUpForm(data)
        dispatch(Signup({ ...checkedData.sanitizedData, history }))
    }

    useEffect(() => {
        setValue('email', getStartedEmail, { shouldValidate: false })
    }, [getStartedEmail])

    return (
        <div className='signIn__container'>
            {userData?.is_subscribed !== true ?
                <div onSubmit={handleSubmit(onSignUp)} className='signIn__container-inner signup-inner'>
                    <Typography
                        variant='h5'
                    >
                        {"Create an account"}
                    </Typography>
                    <div className='row'>
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 2, width: '40ch' },
                            }}
                            noValidate
                            autoComplete="off"
                            style={{textAlign: 'center'}}
                        >
                            <Controller
                                name='name'
                                control={control}
                                rules={{
                                    required: true,
                                }}
                                render={({ field }) => (
                                    <TextField
                                        key="Full Name"
                                        className='custom-field'
                                    
                                        {...field}
                                        id="outlined-basic-3"
                                        label="Full Name"
                                        variant="outlined"
                                    />
                                )}
                            />
                            {errors?.name &&
                                <div className="text text-danger error-text">Enter fullname</div>
                            }

                            <Controller
                                name='email'
                                control={control}
                                rules={{
                                    required: true,
                                }}
                                render={({ field }) => (
                                    <TextField
                                        className='custom-field'
                                        {...field}
                                        id="outlined-basic-4"
                                        label="Email Address"
                                        variant="outlined"
                                        autoComplete='off'
                                    />
                                )}
                            />
                            {errors?.email &&
                                <div className="text text-danger error-text">Enter valid email address</div>
                            }
                            <div style={{display: 'flex', width: '100%', paddingLeft: "0rem", marginLeft: "0"}}>
                                <div style={{width: '100%', paddingLeft: "0rem", marginLeft: "0"}}> 

                                    <Controller

                                        name='password'
                                        control={control}
                                        rules={{
                                            required: true,
                                            minLength: 6,
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                style={{width: '91.5%'}}
                                                className='custom-field'
                                                {...field}
                                                id="outlined-basic-5"
                                                label="Password"
                                                variant="outlined"
                                                type="password"
                                            />
                                        )}
                                    />
                                    {errors?.password &&
                                        <div className="text text-danger error-text">Enter password with min 6 chars</div>
                                    }

                                </div>
                                <div style={{width: "100%"}}>
                                    <Controller
                                            name='confirmtPassword'
                                            control={control}
                                            rules={{
                                                required: true,
                                                validate: (val) => {
                                                    if (watch('password') !== val)
                                                        return false
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    style={{width: '91.5%'}}
                                                    className='custom-field'
                                                    {...field}
                                                    id="outlined-basic-6"
                                                    label="Confirm Password"
                                                    variant="outlined"
                                                    type="password"
                                                />

                                            )}
                                        />
                                        {errors?.confirmtPassword &&
                                            <div className="text text-danger error-text">Password does not match</div>
                                        }
                                </div>
                            </div>
                            <button
                                className='btn btn-hover iq-button text-medium signup-button'
                                type='submit'
                                style={{ color: 'white', width: '13%'}}
                                onClick={handleSubmit(onSignUp)}
                                id="btn-signup"
                            >
                                Sign Up
                            </button>
                        </Box>
                    </div>
                    <p className='signup__text'>
                        Already have an account?
                        <Link style={{ marginLeft: 10 }} to="/sign-in" className="signup__anchor-tag" >
                            Sign In
                        </Link>
                    </p>
                </div>
                :
                <div>
                    <img
                        alt="loader"
                        src={loader}
                        style={{
                            height: 150,
                            width: 150,
                            borderRadius: 5,
                        }}
                    />
                </div>
            }

        </div>

    )
}

export default SignUp