import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.css';
import { GetTrendingScreenData } from '../../../store/Actions/movieActions';
import RenderShows from './RenderShows'
import { VerifyActiveSubscription } from './../../../store/Actions/userActions';


const Trending = () => {
    const dispatch = useDispatch()
    const { trendingShows,
        recommendedShows,
        mostTredingShows
    } = useSelector((state) => state.movie);

    useEffect(() => {
        dispatch(VerifyActiveSubscription())
        dispatch(GetTrendingScreenData())
    }, [])

    if (
        (trendingShows.length === 0) &&
        (recommendedShows.length === 0) &&
        (mostTredingShows.length === 0)
    )
        return (
            <div
                style={{
                    height: 200,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <span
                    style={{
                        fontSize: 25,
                        fontWeight: 'bold',
                        textAlign: 'center',
                        color: 'gray'
                    }}
                >
                    {"No shows found!"}
                </span>
            </div>
        )

    return (
        <>
            <div className="main-content">
                <Container fluid className="custom-container heading-container">
                    <h3 className="main-title main-heading">Trending Shows</h3>
                    <section id="iq-favorites" className='slider-section'>
                    </section>
                </Container>

                 {trendingShows.length !== 0 &&
                    <section id="iq-favorites" className='slider-section'>
                        <Container fluid className="custom-container">
                            <Row>
                                <Col sm="12" className="overflow-hidden">
                                    <div style={{ marginTop: 20 }} className="d-flex align-items-center justify-content-between">
                                        <h4 className="main-title text-xlarge">Popular Shows</h4>
                                    </div>
                                    <div id="favorites-contens">
                                        <div id={`prev0`} className="swiper-button swiper-button-prev"><i className="fa fa-chevron-left"></i></div>
                                        <div id={`next0`} className="swiper-button swiper-button-next"><i className="fa fa-chevron-right"></i></div>
                                        <Swiper
                                            spaceBetween={30}
                                            modules={[Navigation]}
                                            navigation={{
                                                prevEl: `#prev0`,
                                                nextEl: `#next0`
                                            }}
                                            centeredSlides={trendingShows.length < 3 ? true : false}
                                            breakpoints={{
                                                320: { slidesPerView: 2 },
                                                550: { slidesPerView: 3 },
                                                991: { slidesPerView: 4 },
                                                1400: { slidesPerView: 5 },
                                            }}
                                            className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction"
                                        >
                                            {trendingShows.map((show, key) => {
                                                return(
                                                    <SwiperSlide className='swiper-slide slide-item red-border' key={key}>
                                                        <RenderShows show={show}/>
                                                    </SwiperSlide>
                                                    )
                                                })
                                            }
                                        </Swiper>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                }
                {recommendedShows.length !== 0 &&
                    <section id="iq-favorites" className='slider-section'>
                        <Container fluid className="custom-container">
                            <Row>
                                <Col sm="12" className="overflow-hidden">
                                    <div style={{ marginTop: 20 }} className="d-flex align-items-center justify-content-between">
                                        <h4 className="main-title text-xlarge">Recommended for You</h4>
                                    </div>
                                    <div id="favorites-contens">
                                        <div id={`prev${1}`} className="swiper-button swiper-button-prev"><i className="fa fa-chevron-left"></i></div>
                                        <div id={`next${1}`} className="swiper-button swiper-button-next"><i className="fa fa-chevron-right"></i></div>
                                        <Swiper
                                            spaceBetween={30}
                                            modules={[Navigation]}
                                            navigation={{
                                                prevEl: `#prev${1}`,
                                                nextEl: `#next${1}`
                                            }}
                                            centeredSlides={recommendedShows.length < 3 ? true : false}
                                            breakpoints={{
                                                320: { slidesPerView: 2 },
                                                550: { slidesPerView: 3 },
                                                991: { slidesPerView: 4 },
                                                1400: { slidesPerView: 5 },
                                            }}
                                            className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction"
                                        >
                                            {recommendedShows.map((show, key) => {
                                                return(
                                                    <SwiperSlide className='swiper-slide slide-item red-border' key={key}>
                                                        <RenderShows show={show}/>
                                                    </SwiperSlide>
                                                    )
                                                })
                                            }
                                        </Swiper>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                }
                {mostTredingShows.length !== 0 &&
                    <section id="iq-favorites" className='slider-section'>
                        <Container fluid className="custom-container">
                            <Row>
                                <Col sm="12" className="overflow-hidden">
                                    <div style={{ marginTop: 20 }} className="d-flex align-items-center justify-content-between">
                                        <h4 className="main-title text-xlarge">Trending in your region</h4>
                                    </div>
                                    <div id="favorites-contens">
                                        <div id={`prev${2}`} className="swiper-button swiper-button-prev"><i className="fa fa-chevron-left"></i></div>
                                        <div id={`next${2}`} className="swiper-button swiper-button-next"><i className="fa fa-chevron-right"></i></div>
                                        <Swiper
                                            spaceBetween={30}
                                            modules={[Navigation]}
                                            navigation={{
                                                prevEl: `#prev${2}`,
                                                nextEl: `#next${2}`
                                            }}
                                            centeredSlides={mostTredingShows.length < 3 ? true : false}
                                            breakpoints={{
                                                320: { slidesPerView: 2 },
                                                550: { slidesPerView: 3 },
                                                991: { slidesPerView: 4 },
                                                1400: { slidesPerView: 5 },
                                            }}
                                            className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction"
                                        >
                                            {mostTredingShows.map((show, key) => {
                                                return(
                                                    <SwiperSlide className='swiper-slide slide-item red-border' key={key}>
                                                        <RenderShows show={show}/>
                                                    </SwiperSlide>
                                                    )
                                                })
                                            }
                                        </Swiper>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                }
                <div style={{ height: 30 }}></div>
            </div>
        </>
    )
}


export default Trending;