import React, {useEffect, useState} from 'react'
import { Table } from 'react-bootstrap'
import PublisherCard from '../PublisherCard'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import DeleteConfirmBox from './DeleteConfirmBox';
import ShowDetailsIcon from './WatchShowIcon';
import EditContentIcon from './EditContentIcon';

const TvShowList = ({setCompSwitch, currentComponent, setSidebarComponent}) => {      

    const { publishedShows } = useSelector((state) => state.user);
    const [showsInCorrectOrder, setShowsInCorrectOrder] = useState()
    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        setShowsInCorrectOrder(publishedShows.sort((a, b) => (a.id > b.id) ? 1 : -1))
    }, [publishedShows])

    const handleChangePage = (page) => {
        setCurrentPage(page);
    }

    const handleChangeItemsPerPage = (event) => {
        setItemsPerPage(event.target.value);
        setCurrentPage(0); // Reset to first page when items per page changes
    }

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(0); // Reset to first page when search term changes
    }

    // Filter shows based on search term
    // Slice shows based on current page and items per page
    // Calculate total pages
    const filteredShows = showsInCorrectOrder?.filter(show => show.title.toLowerCase().includes(searchTerm.toLowerCase()));
    const totalPages = Math.ceil(filteredShows?.length / itemsPerPage);
    const currentItems = filteredShows?.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

    return (
        <PublisherCard 
            title={ `Shows List` }
            totalEntries={filteredShows?.length}
            currentPage={currentPage}
            totalPages={totalPages}
            handleChangePage={handleChangePage}
            itemsPerPage={itemsPerPage}
            handleChangeItemsPerPage={handleChangeItemsPerPage}
            searchTerm={searchTerm}
            handleSearchChange={handleSearchChange}
        >
            <Table className='dataTable'>
                <thead>
                    <tr>
                        <th tabIndex={0} aria-label='#' style={{ width: '59px', paddingRight: '30px' }}>#</th>
                        <th tabIndex={0} aria-label='Show Title' style={{ width: '196px' }}>Show Title</th>
                        <th tabIndex={0} aria-label='Seasons No' style={{ width: '206px' }}>Seasons No</th>
                        <th tabIndex={0} aria-label='Episode No' style={{ width: '150px' }}>Episodes No</th>
                        <th tabIndex={0} aria-label='Category' style={{ width: '275px' }}>Category</th>
                        <th tabIndex={0} aria-label='Views' style={{ width: '123px' }}>Views</th>
                        <th tabIndex={0} aria-label='Release Date' style={{ width: '100px' }}>Release Date</th>
                        <th tabIndex={0} aria-label='Action' style={{ width: '155px' }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems?.map((show) => {
                        const date = show.release_date
                        const [fullDate] = date.split('T');
                        const [year, month, day] = fullDate.split('-')
                        return (
                            <tr key={show.id}>
                                <td>{show.id}</td>
                                <td>
                                    <Link to="#"
                                        onClick={() => setCompSwitch({string: "Season", showId: show.id, showName: show.title})}
                                        >
                                        {show.title}
                                    </Link>
                                </td>
                                <td>{show.seasons_count}</td>
                                <td>{show.episode_count}</td>
                                <td>{show.category?.length && show.category.map(category => {
                                        return `${category.name}`
                                }).join(', ')}
                                </td>
                                <td>{show.show_views_count}</td>
                                <td>{`${month}/${day}/${year}`}</td>
                                <td>
                                    <ShowDetailsIcon showId={show.id}/>
                                    {' '}
                                    <EditContentIcon setSidebarComponent={setSidebarComponent} contentId={show.id} switchComponent="EditTVShow" />
                                    {' '}
                                    <DeleteConfirmBox currentComponent={currentComponent} currentId={show.id}/>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </PublisherCard>
    )
}

export default TvShowList

// const TvShowList = ({setCompSwitch, currentComponent, setSidebarComponent}) => {      

//     const { publishedShows } = useSelector((state) => state.user);
//     const [showsInCorrectOrder, setShowsInCorrectOrder] = useState()
    
//     useEffect(() => {
//         setShowsInCorrectOrder(publishedShows.sort((a, b) => (a.id > b.id) ? 1 : -1))
//     }, [publishedShows])
    
//     return (
//         <PublisherCard title={ `Shows List` }>
//             <Table className='dataTable'>
//                 <thead>
//                     <tr>
//                         <th tabIndex={0} aria-label='#' style={{ width: '59px', paddingRight: '30px' }}>#</th>
//                         <th tabIndex={0} aria-label='Show Title' style={{ width: '196px' }}>Show Title</th>
//                         <th tabIndex={0} aria-label='Seasons No' style={{ width: '206px' }}>Seasons No</th>
//                         <th tabIndex={0} aria-label='Episode No' style={{ width: '150px' }}>Episodes No</th>
//                         <th tabIndex={0} aria-label='Category' style={{ width: '275px' }}>Category</th>
//                         <th tabIndex={0} aria-label='Views' style={{ width: '123px' }}>Views</th>
//                         <th tabIndex={0} aria-label='Release Date' style={{ width: '100px' }}>Release Date</th>
//                         <th tabIndex={0} aria-label='Action' style={{ width: '155px' }}>Action</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {showsInCorrectOrder && showsInCorrectOrder.map((show) => {
//                         const date = show.release_date
//                         const [fullDate] = date.split('T');
//                         const [year, month, day] = fullDate.split('-')
//                         return (
//                             <tr key={show.id}>
//                                 <td>{show.id}</td>
//                                 <td>
//                                     <Link to="#"
//                                         onClick={() => setCompSwitch({string: "Season", showId: show.id, showName: show.title})}
//                                         >
//                                         {show.title}
//                                     </Link>
//                                 </td>
//                                 <td>{show.seasons_count}</td>
//                                 <td>{show.episode_count}</td>
//                                 <td>{show.category?.length && show.category.map(category => {
//                                         return `${category.name}`
//                                 }).join(', ')}
//                                 </td>
//                                 <td>{show.show_views_count}</td>
//                                 <td>{`${month}/${day}/${year}`}</td>
//                                 <td>
//                                     <ShowDetailsIcon showId={show.id}/>
//                                     {' '}
//                                     <EditContentIcon setSidebarComponent={setSidebarComponent} contentId={show.id} switchComponent="EditTVShow" />
//                                     {' '}
//                                     <DeleteConfirmBox currentComponent={currentComponent} currentId={show.id}/>
//                                 </td>
//                             </tr>
//                         )
//                     })}
//                 </tbody>
//             </Table>
//         </PublisherCard>
//     )
// }

// export default TvShowList
