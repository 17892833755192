import moment from 'moment'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import Scrollbar from 'smooth-scrollbar'
import Card from '../../../../components/Card'
import { wasabiBaseURL } from '../../../../config/baseURL'
import '../../../../assets/css/custom/notifications.css'
import { capitalizeFirstLetters, mediaExists } from '../../../../config/helperFunctions'
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import { MarkAsRead } from '../../../../store/Actions/userActions'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


const Notifications = (props) => {
    const dispatch = useDispatch()
    const history = useHistory();

    const { notifications, } = useSelector((state) => state.user)

    function handleReadAll() {
        let ids = []
        notifications.map(item => {
            if (item.status === "unread") {
                ids.push(item.id)
            }
        })
        dispatch(MarkAsRead(ids))
    }

    function RenderNotifications() {
        return notifications.map((item, key) => {
            const { id, title, message, icon, created_at, status } = item

            // let iconExists = mediaExists(wasabiBaseURL + icon)

            return (
                <div
                    key={key}
                    className={
                        `notification-wrapper
                            ${key !== (notifications.length - 1) ?
                            "last-notification-wrapper"
                            :
                            ""
                        }`
                    }
                >
                    <div className={status === "unread" ? `unread-bar` : `read-bar`} />
                    <Link to="#" className="iq-sub-card custom-card">
                        <div className="media align-items-center">
                            {/* {iconExists ?       checks if icon exists in wasabi*/}
                            {/*//! can add a check for null icon then render default img*/}
                            {icon === null || icon === 'landscape.png' || icon === 'landscap.png' ? (
                                <CheckCircleIcon className='notification-checkmark'/>
                            )
                            : (
                                <img
                                    src={wasabiBaseURL + icon}
                                    className="notification-image img-fluid mr-3"
                                    alt="multitude"
                                />
                            )
                            }
                            {/* :
                                <div className='broken-image'>
                                    <BrokenImageIcon
                                        className='icon'
                                        sx={{ fontSize: 60 }}
                                    />
                                </div>
                            } */}
                            <div className="media-body">
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                >
                                    <h6 className="mb-0 ">{capitalizeFirstLetters(title)}</h6>
                                    {status === "unread" &&
                                        <small
                                            onClick={() => dispatch(MarkAsRead([id]))}
                                            className="font-size-12 notification-read"
                                        >
                                            {`read`}
                                        </small>
                                    }
                                </div>
                                <small className="font-size-12">
                                    {`${message}`}
                                </small>
                                <small className="font-size-12 notification-date">
                                    {`${moment(created_at).startOf('day').fromNow()}`}
                                </small>
                            </div>
                        </div>
                    </Link>
                </div>
            )
        })
    }
    useEffect(() => {
        Scrollbar.init(document.querySelector('.notification-card-body'), {
            damping: 0.1,
            thumbMinSize: 100,
            renderByPixels: true,
            alwaysShowTracks: true,
            continuousScrolling: true,
            wheelEventTarget: null,
        })
    }, [])

    return (
        <Card className="shadow-none m-0 notification-card">
            <Card.Header className='notifications-header'>
                <div className='left-head'>
                    <span className='notifications-title'>Notifications</span>
                </div>
                {notifications.some(item => item.status == "unread") &&
                    <div
                        onClick={handleReadAll}
                        className='right-head'
                    >
                        <span className='read-all-btn'>Mark all as read</span>
                    </div>
                }
            </Card.Header>
            <Card.Body className='notification-card-body'>
                {RenderNotifications()}
            </Card.Body>
        </Card>
    )
}

export default Notifications
