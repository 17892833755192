import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { connect } from "react-redux"
import { Link } from 'react-router-dom'
import '../../../../assets/css/custom/footer.css';
import appStoreLogo from '../../../../assets/custom-assets/app-store.png'
import playLogo from '../../../../assets/custom-assets/google-store.png'
// import footer1 from '../../../../assets/images/footer/01.jpg'
// import footer2 from '../../../../assets/images/footer/02.jpg'
import {

    rtlModeAction
} from '../../../../store/Actions/sharedActions'
import { FaInstagram } from 'react-icons/fa';


//This footer shows up in all pages except Landing page. Footer.js is for the Landing Page
const FooterStyle = (props) => {
    // const [show, setShow] = useState(false);

    useEffect(() => {
        // const rtlMode = sessionStorage.getItem('rtl-mode');
        // if (rtlMode === null) {
        //     props.rtlModeAction(props.rtlMode)
        // }
        // else {
        //     props.rtlModeAction(rtlMode);
        // }
    })

    return (
        <>
            {/* <div className={`rtl-box ${show === true ? 'show' : ''}`} >
                <button type="button" className="btn btn-light rtl-btn">
                    <svg onClick={() => setShow(!show)} xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 20 20" fill="white">
                        <path fillRule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
                    </svg>
                </button>
                <div className="rtl-panel">
                    <ul className="modes">
                        <li className="dir-btn" data-mode="rtl" data-active="false" onClick={() => { props.rtlModeAction('ltl') }} data-value="ltr"><Link to="#">LTR</Link></li>
                        <li className="dir-btn" data-mode="rtl" data-active="true" onClick={() => { props.rtlModeAction('rtl') }} data-value="rtl"><Link to="#">RTL</Link></li>
                    </ul>
                </div>
            </div> */}
            <footer id="contact" className="footer-one iq-bg-dark">
                <div className="footer-top">
                    <Container fluid>
                        <Row className="footer-standard">
                            <Col md={{ span: 6, offset: 2 }}>
                                <div className="widget text-left">
                                    <div className="menu-footer-link-1-container">
                                        <ul id="menu-footer-link-1" className="menu p-0">
                                            <li >
                                                <Link to="/terms-of-service">Terms Of Use</Link>
                                            </li>
                                            <li>
                                                <Link to="/privacy-policy">Privacy-Policy</Link>
                                            </li>
                                            <li >
                                                <Link to="/faq">FAQ</Link>
                                            </li>
                                            <li className="last-footer-li">
                                                <Link to="/contact">Contact</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="widget text-left">
                                    <div className="textwidget">
                                        <p><small>© 2022 MULTITUDE. All Rights Reserved. All videos and shows on this platform are trademarks of, and all related images and content are the property of, Multitude LLC. Duplication and copy of this is strictly prohibited. All rights reserved.</small></p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="1" md="6" className="mt-4 mt-lg-0">
                                <h6 className="footer-link-title">
                                    Follow Us :
                                </h6>
                                <ul className="info-share">
                                    <li><a target="_blank" href="https://www.instagram.com/multitude.io/ " rel="noreferrer"><FaInstagram /></a></li>
                                    {/* <li><Link target="_blank" to="#"><i className="fa fa-twitter"></i></Link></li>
                                    <li><Link target="_blank" to="#"><i className="fa fa-google-plus"></i></Link></li>
                                    <li><Link target="_blank" to="#"><i className="fa fa-github"></i></Link></li> */}
                                </ul>

                            </Col>
                            <Col lg="3" md="6" className="mt-4 mt-lg-0">
                                <div className="widget text-left">
                                    <div className="textwidget">
                                        <h6 className="footer-link-title">Multitude App</h6>
                                        <div className="d-flex align-items-center">
                                            <a className="app-image" target="_blank" href="https://play.google.com/store/apps/details?id=com.multitude" rel="noreferrer">
                                                <img src={appStoreLogo} alt="play-store" />
                                            </a>
                                            <br />
                                            <a className="ml-3 app-image" target="_blank" href="https://apps.apple.com/us/app/multitude/id1573920135" rel="noreferrer">
                                                <img src={playLogo} alt="app-store" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </footer>
        </>
    )
}

const mapStateToProps = ({ movie, user, shared }) => ({
    movie, user, shared
})

export default connect(mapStateToProps, {
    rtlModeAction
})(FooterStyle)