export const RenderCast = ({addCast}) => {
    return (
      <ul
        style={{
          display: "inline-block",
          color: "white",
          marginRight: ".1rem",
          marginLeft: ".1rem",
          paddingLeft: "1rem",
        }}
      >
        {addCast.map((actor, idx) => {
          return(
            <li key={idx} className="text-white">
              {actor.addCast_name}
            </li>
          )
        })}
      </ul>
    );
  };