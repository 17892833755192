export const CHANGE_SHOW_MOVIE_INFO_MODAL = 'CHANGE_SHOW_MOVIE_INFO_MODAL'
export const CHANGE_SEARCH_QUERY = 'CHANGE_SEARCH_QUERY'
export const SET_NAVIGATION = 'SET_NAVIGATION'
export const CHANGE_GENRE_PICKER_SWAP_PANNEL = 'CHANGE_GENRE_PICKER_SWAP_PANNEL'
export const TOGGLE_NOTIFICATIONS_SWITCH = 'TOGGLE_NOTIFICATIONS_SWITCH'
export const TOGGLE_WIFI_ONLY_SWITCH = 'TOGGLE_WIFI_ONLY_SWITCH'
export const CHANGE_CELLULAR_DATA_USAGE = 'CHANGE_CELLULAR_DATA_USAGE'
export const CHANGE_VIDEO_QUALITY = 'CHANGE_VIDEO_QUALITY'
export const CHANGE_LOADER = 'CHANGE_LOADER'
export const REMOVE_LOADER_AND_RESET_STATUS = 'REMOVE_LOADER_AND_RESET_STATUS'
export const CHANGE_STATUS = 'CHANGE_STATUS'
export const CHANGE_GENRE = 'CHANGE_GENRE'
export const CHANGE_LOGIN_EMAIL = 'CHANGE_LOGIN_EMAIL'
export const CHANGE_LOGIN_PASSWORD = 'CHANGE_LOGIN_PASSWORD'
export const CHANGE_SIGNUP_EMAIL = 'CHANGE_SIGNUP_EMAIL'
export const CHANGE_SIGNUP_PASSWORD = 'CHANGE_SIGNUP_PASSWORD'
export const CHANGE_SIGNUP_NAME = 'CHANGE_SIGNUP_NAME'
export const CHANGE_USER_DATA = 'CHANGE_USER_DATA'
export const CHANGE_GENRE_SECTIONS = 'CHANGE_GENRE_SECTIONS'
export const CHANGE_LIKED = 'CHANGE_LIKED'
export const CHANGE_NEW_EPISODES = 'CHANGE_NEW_EPISODES'
export const CHANGE_SELECTED_CATEGORY = 'CHANGE_SELECTED_CATEGORY'
export const CHANGE_SHOWS_BY_CATEGORY = 'CHANGE_SHOWS_BY_CATEGORY'
export const CHANGE_SEASON_DETAIL = 'CHANGE_SEASON_DETAIL'
export const CHANGE_CURRENT_SEASON_EPISODES = 'CHANGE_CURRENT_SEASON_EPISODES'
export const CHANGE_USER_PROFILE = 'CHANGE_USER_PROFILE'
export const CHANGE_UPDATE_PASSWORD_COMFIRM_PASSWORD = 'CHANGE_UPDATE_PASSWORD_COMFIRM_PASSWORD'
export const CHANGE_UPDATE_PASSWORD_NEW_PASSWORD = 'CHANGE_UPDATE_PASSWORD_NEW_PASSWORD'
export const CHANGE_SHOW_FULL_SCREEN_PLAYER = 'CHANGE_SHOW_FULL_SCREEN_PLAYER'
export const CHANGE_SELECTED_PUBLISHER_ID = 'CHANGE_SELECTED_PUBLISHER_ID'
export const CHANGE_PUBLISHER_SHOWS = 'CHANGE_PUBLISHER_SHOWS'
export const CHANGE_FORGOT_PASSWORD_STEP = 'CHANGE_FORGOT_PASSWORD_STEP'
export const CHANGE_FORGOT_PASSWORD_CONFIRM_PASSWORD = 'CHANGE_FORGOT_PASSWORD_CONFIRM_PASSWORD'
export const CHANGE_FORGOT_PASSWORD_NEW_PASSWORD = 'CHANGE_FORGOT_PASSWORD_NEW_PASSWORD'
export const CHANGE_FORGOT_PASSWORD_CODE = 'CHANGE_FORGOT_PASSWORD_CODE'
export const CHANGE_FORGOT_PASSWORD_EMAIL = 'CHANGE_FORGOT_PASSWORD_EMAIL'
export const CHANGE_SHOW_FORGOT_PASSWORD_MODAL = 'CHANGE_SHOW_FORGOT_PASSWORD_MODAL'
export const CHANGE_POPULAR_MOVIES = 'CHANGE_POPULAR_MOVIES'
export const CHANGE_SHOW_DETAIL_EPISODES_LOADER = 'CHANGE_SHOW_DETAIL_EPISODES_LOADER'
export const CHANGE_FULL_SCREEN_EPISODE = 'CHANGE_FULL_SCREEN_EPISODE'
export const CHANGE_SELECTED_EPISODE_FOR_FULL_SCREEN = 'CHANGE_SELECTED_EPISODE_FOR_FULL_SCREEN'
export const CHANGE_SELECTED_EPISODE_CURRENT_TIME_FOR_FULL_SCREEN = 'CHANGE_SELECTED_EPISODE_CURRENT_TIME_FOR_FULL_SCREEN'
export const CHANGE_SELECTED_EPISODE_AND_CURRENT_TIME_FOR_FULL_SCREEN = 'CHANGE_SELECTED_EPISODE_AND_CURRENT_TIME_FOR_FULL_SCREEN'
export const CHANGE_RECOMMENDED_SHOWS = 'CHANGE_RECOMMENDED_SHOWS'
export const CHANGE_TRENDING_SHOWS = 'CHANGE_TRENDING_SHOWS'
export const CHANGE_DOWNLOADING_SHOW = 'CHANGE_DOWNLOADING_SHOW'
export const CHANGE_DOWNLOADING_SHOW_PROGRESS = 'CHANGE_DOWNLOADING_SHOW_PROGRESS'
export const CHANGE_IS_CONNECTED = 'CHANGE_IS_CONNECTED'
export const CHANGE_CONNECTION_TYPE = 'CHANGE_CONNECTION_TYPE'
export const CHANGE_PAUSED_TV_SHOWS = 'CHANGE_PAUSED_TV_SHOWS'
export const CHANGE_IS_PANNEL_PLAY_NOW_BTN_ACTIVE = 'CHANGE_IS_PANNEL_PLAY_NOW_BTN_ACTIVE'
export const CHANGE_IS_BANNER_PLAY_NOW_BTN_ACTIVE = 'CHANGE_IS_BANNER_PLAY_NOW_BTN_ACTIVE'
export const TOGGLE_IS_FOLLOWING_IN_SHOW_DETAILS = 'TOGGLE_IS_FOLLOWING_IN_SHOW_DETAILS'
export const CHANGE_PUBLISHER_DETAIL = 'CHANGE_PUBLISHER_DETAIL'
export const REMOVE_DOWNLOADIND_EPISODE_IDS = 'REMOVE_DOWNLOADIND_EPISODE_IDS'
export const ADD_DOWNLOADIND_EPISODE_IDS = 'ADD_DOWNLOADIND_EPISODE_IDS'
export const CHANGE_DOWNLOADED_SEASONS = 'CHANGE_DOWNLOADED_SEASONS'
export const CHANGE_EPISODES_PER_SEASON = 'CHANGE_EPISODES_PER_SEASON'
export const CHANGE_SHOW_ID_IN_EPISODES_PER_SEASON = 'CHANGE_SHOW_ID_IN_EPISODES_PER_SEASON'
export const DELETE_EPISODES_FROM_EPISODES_PER_SEASON = 'DELETE_EPISODES_FROM_EPISODES_PER_SEASON'
export const CHANGE_DOWNLOADED_EPISODES_IDS = 'CHANGE_DOWNLOADED_EPISODES_IDS'
export const CHANGE_DISLIKED_SHOWS_IDS = 'CHANGE_DISLIKED_SHOWS_IDS'
export const CHANGE_LIKED_SHOWS_IDS = 'CHANGE_LIKED_SHOWS_IDS'
export const ADD_DOWNLOADED_EPISODES_IDS = 'ADD_DOWNLOADED_EPISODES_IDS'
export const LIKE_SHOW = 'LIKE_SHOW'
export const DISLIKE_SHOW = 'DISLIKE_SHOW'
export const CHANGE_POSTERS = 'CHANGE_POSTERS'
export const CHANGE_MOST_TREDING_SHOWS = 'CHANGE_MOST_TREDING_SHOWS'
export const CHANGE_POPULAR_SEARCHES = 'CHANGE_POPULAR_SEARCHES'
export const CHANGE_SEARCHED_SHOWS = 'CHANGE_SEARCHED_SHOWS'
export const DELETE_ALL_DOWNLOADS = 'DELETE_ALL_DOWNLOADS'
export const CHANGE_CONTINUE_WATCHING = 'CHANGE_CONTINUE_WATCHING'
export const CHANGE_FOLLOWED_SHOWS_IDS = 'CHANGE_FOLLOWED_SHOWS_IDS'
export const FOLLOW_SHOW = 'FOLLOW_SHOW'
export const UNFOLLOW_SHOW = 'UNFOLLOW_SHOW'
export const RTLMODE = 'RTLMODE'
export const CHANGE_YAXIS = 'CHANGE_YAXIS'
export const CHANGE_SHOW_EPISODES_SIDEBAR = 'CHANGE_SHOW_EPISODES_SIDEBAR'
export const CHANGE_NOTIFICATIONS = 'CHANGE_NOTIFICATIONS'
export const CURRENT_CATEGORY = 'CURRENT_CATEGORY'
export const CHANGE_SHOW_CHANGE_QUALITY = 'CHANGE_SHOW_CHANGE_QUALITY'
export const SEARCHED_QUERY = 'SEARCHED_QUERY'
export const CHANGE_UP_COMING_SHOWS = 'CHANGE_UP_COMING_SHOWS'
export const CHANGE_POPULAR_HORROR_SHOWS = 'CHANGE_POPULAR_HORROR_SHOWS'
export const CURRENT_VIDEO = 'CURRENT_VIDEO'
export const CHANGE_GET_STARTED_EMAIL = 'CHANGE_GET_STARTED_EMAIL'
export const MARK_NOIFICATIONS_AS_READ = 'MARK_NOIFICATIONS_AS_READ'
export const CHANGE_EDIT_PROFILE_MODAL = 'CHANGE_EDIT_PROFILE_MODAL'
export const CHANGE_FORGOT_PASSWORD_MODAL = 'CHANGE_FORGOT_PASSWORD_MODAL'
export const CHANGE_UPDATE_PASSWORD_MODAL = 'CHANGE_UPDATE_PASSWORD_MODAL'
export const SUBSCRIBE_USER = 'SUBSCRIBE_USER'
export const CHANGE_SIMILAR_SHOWS = 'CHANGE_SIMILAR_SHOWS'
export const UNSUBSCRIBE_USER = 'UNSUBSCRIBE_USER'
export const CHANGE_LANGUAGE_DATA = 'CHANGE_LANGUAGE_DATA'
export const CHANGE_PUBLISHED_SHOWS = 'CHANGE_PUBLISHED_SHOWS'
export const CHANGE_PUBLISHED_SEASONS = 'CHANGE_PUBLISHED_SEASONS'
export const CHANGE_PROGRESS_EVENT = 'CHANGE_PROGRESS_EVENT'
export const CHANGE_SCHEDULED_CONTENT = 'CHANGE_SCHEDULED_CONTENT'
export const CHANGE_WALLET_PAYMENTS = 'CHANGE_WALLET_PAYMENTS'
export const CHANGE_ANALYTICS_DATA = 'CHANGE_ANALYTICS_DATA'
export const CHANGE_SHOW_TO_EDIT = 'CHANGE_SHOW_TO_EDIT'
export const CHANGE_SEASON_TO_EDIT = 'CHANGE_SEASON_TO_EDIT'
export const CHANGE_EPISODE_TO_EDIT = 'CHANGE_EPISODE_TO_EDIT'
export const CHANGE_EDIT_FORM_DATA = 'CHANGE_EDIT_FORM_DATA'
export const REFRESH_SIDEBAR_COMPONENT = 'REFRESH_SIDEBAR_COMPONENT'
export const CHANGE_UPLOAD_FINAL = 'CHANGE_UPLOAD_FINAL'
