import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import RenderShows from "./RenderShows";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/swiper-bundle.css";


export default function RenderGenreSections({genreSections}) {
  return (
    genreSections.map((section, key) => {
      const {category, shows } = section;
      if (shows.length === 0) return null;
      return (
        <section key={shows[0].id} style={{ marginBottom: 10, paddingTop: 10 }} className="slider-section iq-favorites">
          <Container fluid className="custom-container">
            <Row>
              <Col sm="12" className="overflow-hidden">
                <div className="slider-title-wrapper d-flex align-items-center justify-content-between">
                  <h4 className="main-title text-xlarge">{category}</h4>
                  <Link className="iq-view-all" to="/">
                    View All
                  </Link>
                </div>
                <div id="favorites-contens">
                  <div
                    id={`prev${key}`}
                    className="swiper-button swiper-button-prev"
                  >
                    <i className="fa fa-chevron-left"></i>
                  </div>
                  <div
                    id={`next${key}`}
                    className="swiper-button swiper-button-next"
                  >
                    <i className="fa fa-chevron-right"></i>
                  </div>
                  <Swiper
                    spaceBetween={30}
                    modules={[Navigation]}
                    navigation={{
                      prevEl: `#prev${key}`,
                      nextEl: `#next${key}`,
                    }}
                    centeredSlides={shows.length < 3 ? true : false}
                    breakpoints={{
                      320: { slidesPerView: 2 },
                      550: { slidesPerView: 4 },
                      991: { slidesPerView: 5 },
                      1400: { slidesPerView: 6 },
                    }}
                    as="ul"
                    className="favorites-slider list-inline row p-0 m-0 iq-rtl-direction"
                  >
                    
                    {section.shows.map((show, key) => {
                      return(
                          <SwiperSlide key={key} className="custom-swap-slide" as="li">
                            <RenderShows show={show}/>
                          </SwiperSlide>
                      )
                    })}
                  </Swiper>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      );
  }))
}
