import React from "react";
import {
  Container,
  Row,
  Col,
  Breadcrumb,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const TermsOfUse = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <>
      <div style={{ backgroundColor: 'white' }}>
        <Container fluid>
          <Row className="align-items-center">
            <Col sm="12">
              <nav
                aria-label="breadcrumb"
                className="text-center iq-breadcrumb-two"
              >
                <h2 className="title" style={{ color: 'black' }}>Terms of Use</h2>
                <Breadcrumb className="main-bg">
                  <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Terms of use</Breadcrumb.Item>
                </Breadcrumb>
              </nav>
            </Col>
          </Row>
        </Container>
      </div>
      <main style={{ backgroundColor: 'white' }}>
        <div
          name="termly-embed"
          data-id="81225711-f07c-43d1-931b-5f414343f134"
          data-type="iframe"
        ></div>
      </main>
    </>
  );
};

export default TermsOfUse;
