import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, FormGroup } from 'react-bootstrap'
import PortraitUpload from './components/PortraitUpload'
import LandscapeUpload from './components/LandscapeUpload'
import DatePicker from 'react-datepicker'
import { formatTime } from '../../utils/DateUtils'
import { useSelector, useDispatch } from 'react-redux';
import { GetSeasonDataFromShow } from './../../store/Actions/userActions';
import { CreateEpisodeDetails } from './../../store/Actions/movieActions';
import { useHistory } from 'react-router-dom';
import TrailerUpload from './components/TrailerUpload'

const AddNewEpisode = () => {
    const history = useHistory()
    const [formData, setFormData] = useState({
        title: '',
        tv_show: '',
        season: '',
        episode_number: 0,
        portrait_image: null,
        landscape_image: null,
        release_date: '',
        release_time: '',
        description: '',
        trailer: null,
    })
    
    const [selectDate, setSelectDate] = useState(new Date())
    const [selectedShow, setSelectedShow] = useState(0)
    const [filteredSeasons, setFilteredSeasons] = useState([])
    const { publishedSeasons, publishedShows  } = useSelector((state) => state.user);

    const dispatch = useDispatch()
   
    const handleFileInput = event => {
        const { name } = event.target
        setFormData(prevState => ({
            ...prevState,
            [name]: event.target.files[0]
        }))
    }

    const handleTrailerUpload = event => {
        setFormData(prevState => ({
            ...prevState,
            trailer: event.target.files[0]
        }))
    }

    const handleInputChange = event => {
        const { name, value } = event.target
        if (name === 'tv_show') {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }))
            setSelectedShow(value)
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }))
        }
    }

    const handleSubmit = event => {
        event.preventDefault()
        dispatch(CreateEpisodeDetails(formData, selectDate, history))
    }
    useEffect(() => {
        setFilteredSeasons(publishedSeasons.filter(season => season.tv_show_id === parseInt(selectedShow) ))
    }, [selectedShow, publishedSeasons])

    useEffect(() => {
        setFormData(prevState => ({
            ...prevState,
            season: filteredSeasons[0]?.id,
        }))
    }, [filteredSeasons])
    
    useEffect(() => {
        dispatch(GetSeasonDataFromShow())
    }, [dispatch])

    return (
        <Container>
            <Row>
                <Col sm={12}>
                    <div className='publisher-card'>
                        <div className='publisher-card-header justify-content-between'>
                            <h4>Add New Episode</h4>
                        </div>
                        <div className='publisher-card-body'>
                            <Form method='POST' encType='multipart/form-data' onSubmit={handleSubmit}>
                                <Row className='form_publisher-fields'>

                                    <Col lg={8} className='pt-2'>
                                        <TrailerUpload handleTrailerUpload={handleTrailerUpload}></TrailerUpload>
                                    </Col>
                                    <Col lg={4} className='pt-2'>
                                        <PortraitUpload handleImageUpload={handleFileInput}></PortraitUpload>
                                    </Col>
                                </Row>
                                
                                    
                                
                                <Row className='form_publisher-fields'>
                                    <FormGroup className='col-md-4'>
                                        <Form.Label htmlFor='title'>Title</Form.Label>
                                        <Form.Control type='text' name='title' onChange={handleInputChange} required />
                                    </FormGroup>
                                    <FormGroup className='col-md-4'>
                                        <Form.Label htmlFor='tv_show'>Show</Form.Label>
                                        <Form.Control as='select' name='tv_show' onChange={handleInputChange} required>
                                            <option value={0}>Show</option>
                                            {publishedShows && publishedShows.map(show => {
                                                return <option value={show.id} key={show.id}>{show.title}</option>
                                            })}
                                        </Form.Control>
                                    </FormGroup>
                                    <Col lg={4} className='pt-2'>
                                        <LandscapeUpload handleImageUpload={handleFileInput}></LandscapeUpload>
                                    </Col>
                                    <FormGroup className='col-md-4'>
                                        <Form.Label htmlFor='season'>Season</Form.Label>
                                        <Form.Control as='select' name='season' onChange={handleInputChange} required>
                                            {/* Double Ternary
                                                If no show selected, prompt to choose a show
                                                If show is selected and we have filtered seasons by show, map the season titles to options
                                                Else if a show is selected and no seasons, prompt to create one, 
                                                Else if no show is selected again remove the prompt to create and replace with prompt to choose a show,
                                            */}
                                            {selectedShow > 0 ? '' : <option defaultValue={0}>Choose a Show First</option>}
                                            {filteredSeasons.length 
                                                ? filteredSeasons.map(season => {
                                                        return <option value={season.id} key={season.id}>{season.title}</option>
                                                    })
                                                :  selectedShow === 0 
                                                    ? '' 
                                                    : <option defaultValue={0}>No Seasons to Display - Create a Season First</option>}
                                        </Form.Control>
                                    </FormGroup>
                                    <FormGroup className='col-md-4'>
                                        <Form.Label htmlFor='episode_number'>Episode No.</Form.Label>
                                        <Form.Control type='text' name='episode_number' onChange={handleInputChange} required />
                                    </FormGroup>
                                </Row>
                                <Row className='form_publisher-fields'>
                                    <FormGroup className='col-md-4 pt-2'>
                                        <Form.Label htmlFor='release_date'>Publish Date</Form.Label>
                                        <DatePicker selected={selectDate} value={selectDate} required onChange={(date) => setSelectDate(date)} />
                                    </FormGroup>
                                    <FormGroup className='col-md-4 pt-2'>
                                        <Form.Label htmlFor='release_date'>Publish Time</Form.Label>
                                        <DatePicker selected={selectDate} value={`${formatTime(selectDate)}`} showTimeSelect showTimeSelectOnly required onChange={(time) => setSelectDate(time)} />
                                    </FormGroup>
                                    <FormGroup className='col-md-8'>
                                        <Form.Label htmlFor='description'>Description</Form.Label>
                                        <Form.Control as='textarea' name='description' onChange={handleInputChange} rows={5} />
                                    </FormGroup>
                                </Row>
                                <Row className='text-center'>
                                    <FormGroup className='col-12 pt-2'>
                                        <button type='submit' className='btn btn-primary form_video-upload_button'>Submit</button>
                                    </FormGroup>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default AddNewEpisode
