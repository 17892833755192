import { Container } from 'react-bootstrap'
import { useState, useEffect } from "react";
import { Link } from 'react-router-dom'
import '../../assets/css/custom/publisher.css'
import Analytics from './components/Analytics';
import { useDispatch, useSelector } from 'react-redux';
import { GetAnalytics, GetPublisherShowList } from "../../store/Actions/userActions";

const ShowAnalytics = () => {
    const { publishedShows } = useSelector(state => state.user)
    const dispatch = useDispatch()
    useEffect(() => {
        if (publishedShows?.length) {
            dispatch(GetAnalytics(publishedShows[0]?.id))
        }
    }, [publishedShows]);
    
    useEffect(() => {
        dispatch(GetPublisherShowList())
    }, []);

    //keep state of and switch the active component being chosen by the buttons for different chart data
    const [activeComponent, setActiveComponent] = useState('ViewsData');

    return (
        <>
            {publishedShows.length
                ? <Container fluid className="custom-container heading-container">
                    <Analytics component={activeComponent}/>
                    <div style={{textAlign: 'center'}}>
                        <button onClick={(e) => setActiveComponent("ViewsData")}>Views</button>
                        <button onClick={(e) => setActiveComponent("FollowersData")}>Followers</button>
                        <button onClick={(e) => setActiveComponent("RevenueData")}>Revenue</button>
                    </div>
                </Container>
                : <Container style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <h4>No shows created</h4>
                    <Link to='/publisher' className='btn btn-primary form_video-upload_button' style={{ marginTop: '12px' }}>Create a show</Link>
                </Container>
            }
        </>
    )
}

export default ShowAnalytics
