import React from 'react'
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap'
import {useEffect} from 'react'
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

      useEffect(() => {
        const script = document.createElement('script');
      
        script.src = "https://app.termly.io/embed-policy.min.js";
        script.async = true;
      
        document.body.appendChild(script);
      
        return () => {
          document.body.removeChild(script);
        }
      }, []);  

    //style={{backgroundImage: `url(${aboutus1})`}} can add this to the first div for a banner image
    return (
        <>
            <div style={{ backgroundColor: 'white' }}>
                <div>  
                    <Container fluid> 
                        <Row className="align-items-center">
                            <Col sm="12">
                                <nav aria-label="breadcrumb" className="text-center iq-breadcrumb-two">
                                    <h2 className="title" style={{ color: 'black' }}>Privacy Policy</h2>
                                    <Breadcrumb className="main-bg">
                                        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>Home</Breadcrumb.Item>  
                                        <Breadcrumb.Item active>Privacy Policy</Breadcrumb.Item>
                                </Breadcrumb>
                                </nav>
                            </Col>
                        </Row> 
                    </Container>
                </div>
                <main id="main">
                    <div id="privacy-policy-script" name="termly-embed" data-id="362f32df-47af-47c1-8df2-3052aea5561a" data-type="iframe">
                    </div>

                </main>
                    
            </div>
        </>
    )
}

export default PrivacyPolicy;