import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';

function CustomSelect(props) {

    const {
        register,
        name,
        errorMesage,
        required,
        label,
        error,
        options,
        watchMaturityLevel,
    } = props || {}

    function RenderOptions() {
        return options.map((item, key) => {
            return <MenuItem key={key} value={item}>{item}</MenuItem>
        })
    }

    console.log("register(name)===>", register(name))

    return (
        <Box
            component="form"
            sx={{
                '& > :not(style)': { m: 2, width: '40ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <FormControl fullWidth className='custom-form-controll'>
                <InputLabel id={`simple-select-label-${name}`}>{label}</InputLabel>
                <Select
                    {...register(name, {
                        required,
                    })}
                    value={watchMaturityLevel}
                    className='custom-dropdown'
                    labelId={`simple-select-label-${name}`}
                    id="simple-select"
                    label={label}
                >
                    {RenderOptions()}
                </Select>
            </FormControl>
            {error &&
                <div className="text text-danger error-text">
                    {errorMesage}
                </div>
            }
        </Box >
    )
}

export default CustomSelect