import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, FormGroup } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import TrailerUpload from './components/TrailerUpload';
import PortraitUpload from './components/PortraitUpload';
import LandscapeUpload from './components/LandscapeUpload';
import moment from "moment";
import { SeasonEdit } from '../../store/Actions/movieActions'
import { useDispatch, useSelector } from 'react-redux';
import { fetchSeasonInfo } from './../../store/Actions/movieActions';


const EditSeason = ({ seasonId }) => {
    const dispatch = useDispatch()
    const { seasonToEdit } = useSelector(state => state.movie)
    const [formData, setFormData] = useState({
        title: '',
        tv_show: '',
        tv_show_id: 0,
        portrait_image: null,
        landscape_image: null,
        trailer: '',
        release_date: new Date(),
        description: ''
    })

    const handleTrailerUpload = event => {
        setFormData(prevState => ({
            ...prevState,
            trailer: event.target.files[0]
        }))
    }

    const handleImageUpload = event => {
        const { name } = event.target
        setFormData(prevState => ({
            ...prevState,
            [name]: event.target.files[0]
        }))
    }

    const handleInputChange = event => {
        const { name, value } = event.target
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const handleSubmit = event => {
        event.preventDefault()
        let newFormat = moment.utc(formData.release_date).format("ddd MMM D YYYY HH:mm:ss")
        // const checkedData = validateAndSanitizeShowData(formData)
        dispatch(SeasonEdit(formData, seasonId, newFormat))
    }

    useEffect(() => {
        dispatch(fetchSeasonInfo(seasonId))
    }, [dispatch, seasonId])

    useEffect(() => {
        setFormData(prevState => ({
            ...prevState,
            title: seasonToEdit.title,
            tv_show: seasonToEdit.show_title,
            tv_show_id: seasonToEdit.tv_show_id,
            portrait_image: seasonToEdit.portrait_image,
            landscape_image: seasonToEdit.landscape_image,
            trailer: seasonToEdit.trailer,
            release_date: seasonToEdit.release_date ? new Date(seasonToEdit?.release_date) : new Date(),
            description: seasonToEdit.description
        }))
    }, [seasonToEdit])    

    return (
        <>
        {seasonToEdit && 
        <Container>
            <Row>
                <Col sm={12}>
                    <div className='publisher-card'>
                        <div className='publisher-card-header justify-content-between'>
                            <h4>Edit Season</h4>
                        </div>
                        <div className='publisher-card-body'>
                            <Form method='POST' encType='multipart/form-data' onSubmit={handleSubmit}>
                                <Row>
                                    <Col sm={12}>
                                        <h5 className='mb-3'>Edit Season</h5>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={8}>
                                        <TrailerUpload handleTrailerUpload={handleTrailerUpload} trailer={seasonToEdit?.trailer} short={true}></TrailerUpload>
                                    </Col>
                                    <Col lg={4}>
                                        <PortraitUpload handleImageUpload={handleImageUpload} originalPortraitImg={formData.portrait_image} short={true}></PortraitUpload>
                                    </Col>
                                </Row>
                                <Row style={{ margin: '48px -15px' }}>
                                    <Col lg={8}>
                                        <Row className='form_publisher-fields'>
                                            <Form.Group className='col-md-6'>
                                                <Form.Label htmlFor='title'>Title</Form.Label>
                                                <Form.Control type='text' name='title' value={formData.title || "Season Title"} onChange={handleInputChange} />
                                            </Form.Group>
                                            <Form.Group className='col-md-6'>
                                                <Form.Label htmlFor='tv_show'>Show</Form.Label>
                                                <Form.Control type='text' name='tv_show' value={formData.tv_show || "No Show"} onChange={handleInputChange} disabled />
                                            </Form.Group>
                                            <Form.Group className='col-md-6'>
                                                <Form.Label htmlFor='release_date'>Original Release Date: </Form.Label>
                                                <DatePicker 
                                                selected={formData?.release_date}
                                                value={formData?.release_date}
                                                onChange={(date) => {
                                                    setFormData(prevState => ({
                                                        ...prevState,
                                                        release_date: date
                                                    }))
                                                }} 
                                                />
                                            </Form.Group>
                                            <Form.Group className='col-md-12'>
                                                <Form.Label htmlFor='description'>Description</Form.Label>
                                                <Form.Control as='textarea' name='description' rows={5} value={formData.description || "No Description"} onChange={handleInputChange} />
                                            </Form.Group>
                                        </Row>
                                    </Col>
                                    <Col lg={4}>
                                        <LandscapeUpload handleImageUpload={handleImageUpload} originalLandscapeImg={formData.landscape_image} short={true}></LandscapeUpload>
                                    </Col>
                                </Row>
                                <Row>
                                    <FormGroup className='col-12'>
                                        <button type='submit' className='btn btn-primary form_video-upload_button'>Submit</button>
                                    </FormGroup>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
        }
        </>
    )
}

export default EditSeason
