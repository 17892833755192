import { composeWithDevTools } from '@redux-devtools/extension'
import {
    applyMiddleware, createStore
} from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './Reducers'

const middleware = [thunk]

const store = createStore(
    rootReducer,
    composeWithDevTools(
        applyMiddleware(...middleware),
        //todo: other store enhancers if any
    )
)

export default store