/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
function getOS() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
}

async function mediaExists(url) {
    try {
        // var http = new XMLHttpRequest();
        // http.open('HEAD', url, false);
        // http.send();
        // return http.status != 404;

        let result = await fetch(url, { method: 'HEAD', mode: 'cors', "Access-Control-Allow-Origin": "*" }).then(res => res)
        if (result.ok)
            return true
        else
            return false

    } catch (error) {
        // console.error("error===>", error)
        return false;
    }
}

function capitalizeFirstLetters(str) {
    return str.toLowerCase().replace(/([^a-z])([a-z])(?=[a-z]{2})|^([a-z])/g, function (_, g1, g2, g3) {
        return (typeof g1 === 'undefined') ? g3.toUpperCase() : g1 + g2.toUpperCase();
    });
}

export {
    getOS,
    mediaExists,
    capitalizeFirstLetters,
}