export const RenderGenres = ({category}) => {
    return (
      <ul className="p-0 list-inline d-flex align-items-center movie-content">
        {category?.map((genre, key) => {
          return (
            <li key={key} className="text-white">
              {genre.category_name}
            </li>
          )
        })}
      </ul>
    );
  };