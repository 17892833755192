import { Modal, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import '../../../../assets/css/edit-profile-modal.css';
import '../../../../assets/css/forgot-password.css';
import '../../../../assets/css/signUp.css';
import CustomInput from '../../../../components/CustomInput';
import {
    ChangeForgotPasswordModal,
    ChangeUpdatePasswordModal,
    UpdateThePassword
} from '../../../../store/Actions/userActions';
import {useState} from 'react'
import ForgotPasswordModal from '../../pages/auth/ForgotPasswordModal';


function UpdatePasswordModal(props) {
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);

    const mouseUpPassword = () => setShowPassword(!showPassword);
    const mouseDownPassword = () => setShowPassword(!showPassword);
    const mouseUpNewPassword = () => setShowNewPassword(!showNewPassword);
    const mouseDownNewPassword = () => setShowNewPassword(!showNewPassword);

    const defaultValues = {
        oldPassword: null,
        password: null,
        confirmPassword: null,
    }

    const dispatch = useDispatch()
    const { register, formState: { errors }, handleSubmit, watch, reset } = useForm({
        mode: 'onSubmit',
        defaultValues,
    })

    const { updatePasswordModal, forgotPasswordStep } = useSelector((state) => state.user)

    const handleUpdatePassword = handleSubmit(async data => {  
        let result = await dispatch(UpdateThePassword(data))
        
        if (result?.status >= 200 && result?.status < 300){ 
            dispatch(ChangeUpdatePasswordModal(false))
            reset(defaultValues)
        }

    })

    return (
        <>
        
        <Modal
            centered
            size={(forgotPasswordStep === 1 || forgotPasswordStep === 2) ? "" : "lg"}
            show={updatePasswordModal}
            onHide={() => {
                dispatch(ChangeUpdatePasswordModal(false))
                reset(defaultValues)
            }}
            dialogClassName="modal-90w show-detail-modal"
            aria-labelledby="example-custom-modal-styling-title modal-container"

        >
            <Modal.Header className='custom-modal-header' closeButton>
                <h5 className='signup__heading'>
                    {"Update Pasword"}
                </h5>
            </Modal.Header>
            <Modal.Body className='modal-body forgot-pass-body'>
                <span className='forgot-pass-label'>
                        Enter your old password or choose "Forgot Password"
                </span>
                <div className='row'>
                    <CustomInput
                            type={showPassword ? "text" : "password"}
                            register={register}
                            name={'oldPassword'}
                            errorMesage={'Enter Old password'}
                            required={forgotPasswordStep === 3}
                            placeholder={'Old Password'}
                            error={errors?.password}
                            mouseUpPassword={mouseUpPassword}
                            mouseDownPassword={mouseDownPassword}
                            showPassword={showPassword}
                        />
                </div>
                <hr></hr>
                <span className='forgot-pass-label'>
                    Enter your new password and confirm password
                </span>
                <div className='row'>
                    <>
                        <CustomInput
                            type={showNewPassword ? "text" : "password"}
                            register={register}
                            name={'password'}
                            errorMesage={'Enter password'}
                            required={forgotPasswordStep === 3}
                            placeholder={'New Password'}
                            error={errors?.password}
                            mouseUpPassword={mouseUpNewPassword}
                            mouseDownPassword={mouseDownNewPassword}
                            showPassword={showNewPassword}
                        />
                        <CustomInput
                            type={showNewPassword ? "text" : "password"}
                            watchPassword={watch('password')}
                            register={register}
                            name={'confirmPassword'}
                            errorMesage={'Password does not match'}
                            required={forgotPasswordStep === 3}
                            placeholder={'Confirm New Password'}
                            error={errors?.confirmPassword}
                            mouseUpPassword={mouseUpNewPassword}
                            mouseDownPassword={mouseDownNewPassword}
                            showPassword={showNewPassword}
                        />
                    </>
                </div>
                <p
                            onClick={() => {
                                dispatch(ChangeUpdatePasswordModal(false))
                                dispatch(ChangeForgotPasswordModal(true))
                            }}
                            className='forgot-password'
                            style={{
                                color: 'white',
                                marginBottom: 0,
                                marginTop: 20,
                                fontStyle: 'italic',
                                cursor: 'pointer',
                            }}
                        >
                            Forgot Password?
                        </p>
            </Modal.Body>
            <Modal.Footer className='custom-modal-footer'>
                <Button 
                    onClick={() => {
                        dispatch(ChangeUpdatePasswordModal(false))
                        reset(defaultValues)
                    }}
                    className="btn btn-hover iq-button text-medium navbar--button signup-button custom-btn" 
                    variant="secondary" 
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleUpdatePassword}
                    className="btn btn-hover iq-button text-medium navbar--button signup-button custom-btn"
                >
                    Update Password
                </Button>
            </Modal.Footer>
        </Modal >
        <ForgotPasswordModal />
        </>
    )
}

export default UpdatePasswordModal