import { useSelector } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';
import LandingPage from '../views/backend/pages/auth/Landing/LandingPage';
import SignIn from '../views/backend/pages/auth/SignIn';
import SignUp from '../views/backend/pages/auth/SignUp';
import PrivacyPolicy from '../views/backend/pages/extrapages/privacyPolicy';
import TermsOfUse from '../views/backend/pages/extrapages/termsOfUse';
import Payment from '../views/backend/pages/Payment/Payment';
import PaymentSuccessful from '../views/backend/pages/Payment/PaymentSuccessful';
import PlanDetails from '../views/backend/pages/Payment/PlanDetails';
import FAQ from './../views/backend/pages/auth/Landing/FAQs';
import HomeRedirect from './HomeRedirect';


const AuthPages = () => {

    let location = useLocation();

    const {
        userData
    } = useSelector((state) => state.shared)

    return (
        <Switch location={location}>
            <Route path="/sign-up" component={SignUp} />
            <Route path="/sign-in" component={SignIn} />
            {userData &&
                <>
                    <Route path="/payment" component={Payment} />
                    <Route path="/plan-details" component={PlanDetails} />
                    <Route path="/" component={HomeRedirect} />
                </>
            }
            <Route path="/faq" component={FAQ} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/terms-of-service" component={TermsOfUse} />
            <Route path="/" component={LandingPage} />
        </Switch>
    )
}

export default AuthPages;