import React, { useState, forwardRef, useCallback } from 'react'
import { Col, Container, Row, Form } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import ChunkedUploady, { useBatchAddListener, useBatchStartListener, useBatchFinishListener, useBatchProgressListener, useBatchErrorListener, useFileInput } from '@rpldy/chunked-uploady'
import { asUploadButton } from '@rpldy/upload-button'
import { toast } from 'react-toastify'
import { baseURL } from '../../config/baseURL'
import store from '../../store'
import loader from '../../assets/custom-assets/loader.gif'

const VideoUpload = props => {
    const { episodeTitle, episodeId } = (props.location && props.location.state) || {};
    const history = useHistory()
    const [fileName, setFileName] = useState('')
    const [episode, setEpisode] = useState(null)
    const [progress, setProgress] = useState(0)
    const [uploading, setUploading] = useState(false)
    const [uploadError, setUploadError] = useState(false)
    const [uploadIsFinal, setUploadIsFinal] = useState(false)
    const [loaded, setLoaded] = useState(0)
    const [total, setTotal] = useState(0)

    const UploadButton = asUploadButton(
        forwardRef(({ onChange, ...props }, ref) => {
            useBatchAddListener(batch => {
                setFileName(batch.items[0].file.name)
                setEpisode(batch.items[0].file)
                setTotal(batch.items[0].file.size)
            })

            useBatchStartListener(() => {
                setUploading(true)
                setUploadIsFinal(false)
                setUploadError(false)
            })

            useBatchFinishListener(batch => {
                setUploading(false)
                setUploadIsFinal(true)
                setProgress(0)
                setLoaded(0)
                setFileName('')
                if (batch.state == 'finished') {
                    if (batch.items[0].state == 'finished') {
                        toast('File Uploaded Successfully')
                        history.push('/publisher')
                    } else {
                        toast('Error Uploading File')
                        setUploadError(true)
                    }
                } else {
                    toast('Error Uploading File')
                    setUploadError(true)
                }
            })
      
            useBatchProgressListener(batch => {
                setProgress(batch.completed)
                setLoaded(batch.loaded)
            })

            useBatchErrorListener(batch => {
                toast('Error Uploading File')
                setUploadError(true)
            })
      
            return (
                <div 
                    {...props} 
                    ref={ref} 
                    id="form-upload-button"
                    className='btn btn-primary form_video-upload_button' 
                    style={{ marginLeft: 15 }}
                >
                    Choose File
                </div>
            )
        })
    )

    return (
        <ChunkedUploady
            chunkSize={1000 * 1024}
            method="POST"
            destination={{ 
                url: `${baseURL}/api/publisher/episode/video/upload/${episodeId}`,
                headers: {
                    "Authorization": `Bearer ${store.getState().shared.userData.token}`,
                    "x-custom": "123"
                }
            }}
            inputFieldName={'file'}
            accept='video/mp4,video/x-m4v,video/*'
        >
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className='publisher-card'>
                            <div className='publisher-card-header justify-content-between'>
                                <h4>Add Video for Episode {episodeTitle}</h4>
                            </div>
                            <div className='publisher-card-body'>
                                <Row>
                                    <Col lg={12}>
                                        <Row className='form_episode-upload' style={{ marginLeft: 0, marginRight: 0 }}>
                                                <UploadButton />
                                                <div style={{ marginLeft: 10 }}>
                                                    {fileName}
                                                </div>
                                        </Row>
                                        <label htmlFor="file-upload">Uploading progress: {Math.round(progress) > 0 && `${Math.round(progress)}%`}</label>
                                        <progress id='file-upload' value={progress} max={100}> Complete</progress><br/>
                                        { uploading && !uploadIsFinal && (
                                        <div className='upload-not-final'>
                                            <img
                                                alt="loader"
                                                src={loader}
                                                style={{
                                                    height: 50,
                                                    width: 50,
                                                    borderRadius: 5,
                                                }}
                                            />
                                            <p>Please wait for Upload to Finalize...</p>
                                        </div>)
                                        }
                                        {uploadError && <p>Error Uploading Episode. Please Try again or Choose a different File</p>}
                                        {loaded ? <p>{`${(loaded/1000000).toFixed(2)} MB / ${(total/1000000).toFixed(2)} MB`}</p> : '0 MB / 0 MB'}
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </ChunkedUploady>
    )
}

export default VideoUpload
