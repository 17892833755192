
import { Link } from 'react-router-dom';
import { GetEpisodeVideos, GetShowDetails } from './../../../store/Actions/movieActions';
import { useDispatch } from 'react-redux';


const ShowDetailsIcon = ({showId, isEpisode}) => {
    const dispatch = useDispatch()
    return ( 
        <Link to='#'>
            <i
            onClick={() => isEpisode ? dispatch(GetEpisodeVideos(isEpisode, true, true)) : dispatch(GetShowDetails(showId))}
            className="ri-eye-line ri-xl" 
            style={{
                backgroundColor: 'rgb(57,90,66)', 
                borderRadius: '5px', 
                color: "rgb(17,231,136)"
                }}
            >
            </i>
        </Link>
     );
}
 
export default ShowDetailsIcon;