import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/bootstrap.min.css';
import './assets/css/custom/custom.css';
import './assets/css/custom/header.css';
import './assets/css/responsive.css';
import './assets/css/responsive2.css';
import './assets/css/style.css';
import './assets/css/style2.css';
import './assets/css/typography.css';
import './assets/css/typography2.css';
import SpinnerLoader from './components/SpinnerLoader';
import { getOS } from './config/helperFunctions';
import LayoutsRoute from './router/layouts-route';
import { ChangeUserData } from './store/Actions/userActions';
import { PayPalScriptProvider} from "@paypal/react-paypal-js";
import "./App.css"


const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#ffca00'
    },
    warning: {
      main: '##F44336'
    },
  }
});

const initialOptions = {

  "client-id": "AS0-ngxTotNW4PeUCir4Is_91AEvy7FsjqM5_rkGRiiChD5aMPHPhaz_MkYcLjAZNznSkf4JRDZBpuag", //sandbox acc test
  // "client-id": "AaIddlDmKq4kADhTDzx9cK2p8lJH4CgOrzHUTIDCOh0RaKda3WQ8t_GQRvR2iL_WF0UzdVOVTqZJ1ggW", //test
  // "client-id": "AcxPHQfYRTd6fUCJoSomOnWszmiOHdPrpd30wucad2M502jljsWbdUiAWSTYaOBZfWih2FxtLgqzSuqc", //live
  components: "buttons",
  intent: "subscription",
  vault: true,
};

function App(props) {
  const dispatch = useDispatch()
  let history = useHistory()
  const { loader } = useSelector(state => state.shared);

  useEffect(() => {
    if (getOS() !== "iOS" && getOS() !== "Android" && getOS() !== "Windows Phone") {
      if (localStorage?.userData) {
        dispatch(ChangeUserData(JSON.parse(localStorage.userData)))
      } else {
        dispatch(ChangeUserData(''))
      }
    } else {
      localStorage.removeItem('userData')
      history.push('/')
    }
  }, [])
  
  return (
    <>
      {/* <script src="https://www.paypal.com/sdk/js?client-id=YOUR_CLIENT_ID&components=buttons&vault=true&intent=subscription"></script> */}
    <PayPalScriptProvider 
        options={initialOptions}
    >
      <ThemeProvider theme={theme}>
        <div className="App">
          <LayoutsRoute />
        </div>
      </ThemeProvider>
    </PayPalScriptProvider>
      {loader &&
        <SpinnerLoader />
      }
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme='dark'
        progressStyle={{
          background: '#ffca00'
        }}
        style={{
        }}
      />


    </>
  )
}

export default App;
