import validator from 'validator';
import isEmpty from './isEmpty';


const validateAndSanitizeShowData = ( data, hasStates = true ) => {
	
	let errors = {};
	let sanitizedData = {
        ...data
    };
	
	data.title = ( ! isEmpty( data.name ) ) ? data.name : '';
	data.description = ( ! isEmpty( data.password ) ) ? data.password : '';
	
	const addErrorAndSanitizedData = ( fieldName, errorContent, min, max, type = '', required ) => {
		
		if ( ! validator.isLength( data[ fieldName ], { min, max } ) ){
			errors[ fieldName ] = `${errorContent} must be ${min} to ${max} characters`;
		}
		
		if ( required && validator.isEmpty( data[ fieldName ] ) ) {
			errors[ fieldName ] = `${errorContent} is required`;
		}
		
		if ( ! errors[ fieldName ] ) {
			sanitizedData[ fieldName ] = validator.trim( data[ fieldName ] );
			sanitizedData[ fieldName ] = validator.escape( sanitizedData[ fieldName ] );
		}
	};
	
	addErrorAndSanitizedData( 'title', 'Title', 2, 50, 'string', true );
	addErrorAndSanitizedData( 'description', 'Description', 5, 500, 'string', true );
	
	return {
		sanitizedData,
		errors,
		isValid: isEmpty( errors )
	}
};

export default validateAndSanitizeShowData;