import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import SwiperCore, { Navigation } from "swiper";
import "swiper/swiper-bundle.css";
import moment from "moment";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { useSelector, useDispatch } from "react-redux";
import {
  FollowShow,
  PlayNow,
  GetSimilarShowsByCategory,
  GetUpcomingShows,
} from "../../../store/Actions/movieActions";
import { DislikeShow, LikeShow } from "../../../store/Actions/sharedActions";
import { RenderSlides } from "./helperComponents/RenderSlides";
import { CopyLink } from "./helperComponents/CopyLink";
import { RenderGenres } from "./helperComponents/RenderGenres";
import { RenderCast } from "./helperComponents/RenderCast";
import { VerifyActiveSubscription, ChangeLoader } from './../../../store/Actions/userActions';
import { FollowedShows } from './../../../store/Actions/movieActions';

// install Swiper modules
SwiperCore.use([Navigation]);

//! need to implement params for movie-details/{movieId}

//? should take a movie that is passed in as an argument when the movie is clicked?
const AddMovie = () => {
  const dispatch = useDispatch();
  const {
    seasonDetail: tv_showsData,
    similarShows,
    upComingShows,
  } = useSelector((state) => state.movie);
  const { dislikedShowsIds, likedShowsIds, followedShowsIds } = useSelector(
    (state) => state.shared
  );
  const [show, setShow] = useState()

  //seasonDetail seems to be tv_shows table in DB. Seems it could contain all data of an episode/season/tvshow
  const {
    id,
    seasons_count,
    episode_count,
    followers_count,
    is_following,
    name,
    description,
    maturity_level,
    release_date,
    portrait_image,
    banner,
    show_logo,
    trailer,
    landscape_image,
    category,
    seasons,
    episode,
    title,
    publisher,
    duration,
    addCast,
  } = tv_showsData;

  useEffect(() => {
    category && dispatch(GetSimilarShowsByCategory(category[0].category_id, 15, true))
  }, [category]);

  useEffect(() => {
    dispatch(GetUpcomingShows(15, true))
    dispatch(VerifyActiveSubscription())
    dispatch(FollowedShows())
  }, []);

  useEffect(() => {
    let timer1 = setTimeout(() => setShow(true), 4000);
    return () => {
      clearTimeout(timer1);
    };
}, []);

  useEffect(() => {
    if(show || name){
      dispatch(ChangeLoader(false))
    }
}, [show, name]);
  return (
  <div className="main-content movi">
    {name ?
      <>
        <section className="movie-detail container-fluid">
          <Row>
            <Col lg="12">
              <div className="trending-info g-border">
                <h1 className="trending-text big-title text-uppercase mt-0">
                  {name ? name : "No Title Found For This Video"}
                </h1>
                {category?.length ? (
                  <RenderGenres category={category}/>
                ) : (
                  <ul className="p-0 list-inline d-flex align-items-center movie-content">
                    <li className="text-white">Error</li>
                    <li className="text-white">Loading</li>
                    <li className="text-white">Genres</li>
                  </ul>
                )}
                {addCast?.length ? <RenderCast addCast={addCast}/> : "No Cast Found"}
                <div className="d-flex align-items-center text-white text-detail">
                  <span className="badge badge-secondary p-3">
                    {maturity_level ? maturity_level : "No Rating Found"}
                  </span>
                  <span className="ml-3">
                    {duration ? duration : "No Video Duration Found"}
                  </span>
                  <span className="trending-year">
                    {release_date
                      ? moment(release_date).format("MMM Do YY")
                      : "No Release Date Found"}
                  </span>
                </div>
                <div className="hover-buttons">
                  <div
                    className="btn btn-hover iq-button font-small iq-button"
                    onClick={() => dispatch(PlayNow(id))}
                    style={{ color: "black" }}
                  >
                    <i className="fa fa-play mr-2" aria-hidden="true"></i>
                    Play Now
                  </div>
                </div>
                <p className="trending-dec w-100 mb-0">
                  {description ? description : "No Description Found"}
                </p>
                <ul className="list-inline p-0 mt-4 share-icons music-play-lists">
                  <li
                    onClick={() => {
                      if (followedShowsIds.includes(id)) {
                        dispatch(FollowShow(id, false));
                      } else {
                        dispatch(FollowShow(id, true));
                      }
                    }}
                  >
                    <span>
                      {followedShowsIds.includes(id) ? (
                        <i className="custom-icon ri-check-line" />
                      ) : (
                        <i className="custom-icon ri-add-line" />
                      )}
                    </span>
                  </li>
                  <li className="share">
                    <span className="">
                      <i className="custom-icon ri-heart-fill"></i>
                    </span>
                    <div className="share-box">
                      <div className="d-flex align-items-center">
                        {likedShowsIds.includes(id) ? (
                          <i
                            onClick={() => dispatch(LikeShow(id))}
                            className="hover-icon ri-thumb-up-fill"
                          />
                        ) : (
                          <i
                            onClick={() => dispatch(LikeShow(id))}
                            className="hover-icon ri-thumb-up-line"
                          />
                        )}
                        {dislikedShowsIds.includes(id) ? (
                          <i
                            onClick={() => dispatch(DislikeShow(id))}
                            className="hover-icon ri-thumb-down-fill"
                          />
                        ) : (
                          <i
                            onClick={() => dispatch(DislikeShow(id))}
                            className="hover-icon ri-thumb-down-line"
                          />
                        )}
                      </div>
                    </div>
                  </li>
                  <li className="share">
                    <span>
                      <i className="custom-icon ri-share-fill"></i>
                    </span>
                    <div className="share-box">
                      <div className="d-flex align-items-center">
                        <FacebookShareButton
                          url={window.location.href}
                          title={name}
                        >
                          <i className="ri-facebook-fill"></i>
                        </FacebookShareButton>
                        <TwitterShareButton
                          url={window.location.href}
                          title={name}
                          className="share-ico"
                        >
                          <i className="ri-twitter-fill"></i>
                        </TwitterShareButton>
                        <Link to="#" 
                          onClick={() => CopyLink(id)} 
                          className="share-ico">
                          <i className="ri-links-fill"></i>
                        </Link>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </section>
        <section id="iq-favorites" className="s-margin iq-rtl-direction">
          <Container fluid>
            <Row>
              <Col sm="12" className="overflow-hidden">
                <div className="d-flex align-items-center justify-content-between">
                  <h4 className="main-title">
                    <Link to="#">More Like This</Link>
                  </h4>
                </div>
                <div id="favorites-contens">
                  {similarShows?.length
                    ? <RenderSlides dislikedShowsIds={dislikedShowsIds} likedShowsIds={likedShowsIds} followedShowsIds={followedShowsIds} showList={similarShows} slideId={1}/>
                    : "Error Loading Similar Shows"}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section id="iq-upcoming-movie" className="iq-rtl-direction">
          <Container fluid>
            <Row>
              <Col sm="12" className="overflow-hidden">
                <div className="d-flex align-items-center justify-content-between">
                  <h4 className="main-title">
                    <Link to="#">Upcoming Shows</Link>
                  </h4>
                </div>
                <div id="upcoming-contens">
                  {upComingShows?.length
                    ? <RenderSlides dislikedShowsIds={dislikedShowsIds} likedShowsIds={likedShowsIds} followedShowsIds={followedShowsIds} showList={upComingShows} slideId={2}/>
                    : "No Upcoming Shows To Load"}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    : show && "No Show Found"
    }
  </div>
  );
};
export default AddMovie;
