import React from 'react'

import '../../../../../assets/css/custom/ContactUs.css'

function ContactUs() {
  return (

    <div className='container'>
      <div className='text-center mt-4'>
        <h1 className='Contactus-header'>Contact Us</h1>
      </div>
      <div className='ContactUs__container-inner row justify-content-center align-items-center mt-4'>
        <div className='col-md-8'>
          <div className='row justify-content-center align-items-center py-4 mt-2'>
            <div className='col-md-8 text-center py-4'>
              <div className="input-group mb-3">
                <input type="text" className="form-control" placeholder='Name' aria-label="Dollar amount (with dot and two decimal places)" />

              </div>

              <div className="input-group mb-3">
                <input type="text" className="form-control" placeholder='Email' aria-label="Dollar amount (with dot and two decimal places)" />

              </div>

              <div className="input-group mb-3">
                <textarea className="form-control" placeholder="Leave a comment here" id="floatingTextarea2" ></textarea>

              </div>

              <button type="button" className="btn btn-light fw-bold send-message-button "  >Send Message</button>
            </div>
          </div>

        </div>
      </div>
    </div>

  )
}

export default ContactUs