import React from "react";
import "../assets/css/custom/Toggle.css";

const ToggleSwitch = ({ label, handleClick }) => {
return (
	<>
	{label}{" "}
	<div className="toggle-switch">
		<input type="checkbox" className="checkbox"
			name={label} id={label} onChange={e => handleClick(e)} />
		<label className="label" htmlFor={label}>
		<span className="inner" />
		<span className="switch" />
		</label>
	</div>
	</>
);
};

export default ToggleSwitch;
