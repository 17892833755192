import React, {useState} from 'react'
import { Waypoint } from "react-waypoint";
import { useSpring, animated } from '@react-spring/web'
import mobile from '../../../../../assets/images/custom/independently-original.png'
import '../../../../../assets/css/custom/DownloadYourShows.css'

function DownloadYourShows() {
    const [inView, setInview] = useState(false);
    const springs = useSpring({
        delay: 300,
        from: { y: -200 },
        to: {
            y: !inView ? -200 : 25,
            opacity: !inView ? 0 : 1,
          },
        config: {
          mass: 5,
          friction: 50,
          tension: 160,
        },
      })
      const bottomSprings = useSpring({
        delay: 100,
        from: { y: 200 },
        to: {
            y: !inView ? 200 : 0,
            opacity: !inView ? 0 : 1,
          },
        config: {
          mass: 5,
          friction: 50,
          tension: 160,
        },
      })
    return (
        <>
            <div className='gray-line'></div>
            <Waypoint onEnter={() => setInview(true)}>
                <div className='container-fluid dys__container '>
                    <animated.div
                        className='col-md-4 dys__content-left'
                        style={{...springs}}
                        >
                        <h1 className='dys__content-left-header'>Independently Original</h1>
                        <p className='dys__content-text'>Join other independent creators. Make your own shows and publish episodes weekly</p>
                    </animated.div> 

                    <animated.div
                        className='col-6 dys__content-right '
                        style={{...bottomSprings}}
                        >
                        <img alt="upload icon" className='dys__content-right-image independently-original' src={mobile} />
                    </animated.div>       
                </div>
            </Waypoint>                  
        </>

    )
}

export default DownloadYourShows