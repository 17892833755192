import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
    publicKey
} from '../../../../config/apiKeys';
import NewCardInfo from './NewCardInfo';

const UpdatePaymentModal = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const stripePromise = loadStripe(publicKey);

    const handleSubmit = () => {
      setShow(false);
    }

  return (
    <>
     <Link to="#" className="text-primary" onClick={handleShow}>Update Payment info</Link>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Payment Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Elements
                stripe={stripePromise}
            >
            <NewCardInfo handleSubmit={handleSubmit} handleClose={handleClose}/>
          </Elements>
        </Modal.Body>
        <Modal.Footer>
          
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UpdatePaymentModal;