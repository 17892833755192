import AddCircleIcon from '@mui/icons-material/AddCircle';
import { IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import '../../../../assets/css/edit-profile-modal.css';
import '../../../../assets/css/signUp.css';
import CustomInput from '../../../../components/CustomInput';
import CustomSelect from '../../../../components/CustomSelect';
import { ChangeEditProfileModal, EditProfile } from '../../../../store/Actions/userActions';
import validateAndSanitizeEditProfileForm from './../../../../validation/editProfileValidation';

function EditProfileModal(props) {

    const defaultValues = {
        name: "",
        email: "",
        address: "",
        maturity_level: "",
    }

    const dispatch = useDispatch()
    const { register, formState: { errors }, handleSubmit, watch, reset, getValues } = useForm({
        mode: 'onSubmit',
        defaultValues,
    })

    const { userProfile, editProfileModal } = useSelector((state) => state.user)

    const [file, setFile] = useState(null)
    const [preview, setPreview] = useState(null)

    useEffect(() => {
        const { profile_name, profile_email, profile_address, maturity_level, profile_image } = userProfile || {}

        reset({
            name: profile_name,
            email: profile_email,
            address: profile_address,
            maturity_level: maturity_level,
        })
        setFile({
            uri: profile_image,
        })

    }, [userProfile])

    return (
        <Modal
            centered
            size="lg"
            show={editProfileModal}
            onHide={() => dispatch(ChangeEditProfileModal(false))}
            dialogClassName="modal-90w show-detail-modal"
            aria-labelledby="example-custom-modal-styling-title modal-container"

        >
            <Modal.Header className='custom-modal-header' closeButton>
                <h5 className='signup__heading'>
                    {"Edit Account"}
                </h5>
            </Modal.Header>
            <Modal.Body className='modal-body'>
                <div className='avatar-edit-container'>
                    <div className='avatar-container'>
                        <Avatar
                            className='profile-avatar'
                            src={preview ? preview : file?.uri}
                            name={"Z"}
                            round
                            size="200px"
                            style={{
                                objectFit: 'cover',
                            }}
                        />
                        <div className='avatar-file-input-conrainer'>
                            <input
                                onChange={(e) => {
                                    if (e.target.files[0]) {
                                        setFile(e.target.files[0])
                                        setPreview(URL.createObjectURL(e.target.files[0]))
                                    } else
                                        console.error("No file found")

                                }}
                                accept="image/png, image/gif, image/jpeg"
                                className='avatar-file-input'
                                id="contained-button-file"
                                type="file"
                            />
                            <label htmlFor="contained-button-file">
                                <IconButton
                                    component="span"
                                >
                                    <AddCircleIcon
                                        className='icon'
                                        sx={{ fontSize: 40 }}
                                    />
                                </IconButton>
                            </label>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <CustomInput
                        register={register}
                        name={'name'}
                        errorMesage={'Enter full name'}
                        required={true}
                        placeholder={'Full Name'}
                        error={errors?.name}
                    />
                    <CustomInput
                        register={register}
                        name={'email'}
                        errorMesage={'Enter valid email'}
                        required={true}
                        placeholder={'Email'}
                        error={errors?.email}
                    />
                    <CustomInput
                        register={register}
                        name={'address'}
                        errorMesage={'Enter address'}
                        required={true}
                        placeholder={'Address'}
                        error={errors?.address}
                    />
                    <CustomSelect
                        register={register}
                        name={'maturity_level'}
                        errorMesage={'Enter maturity level'}
                        required={true}
                        label={'Maturity Level'}
                        error={errors?.maturity_level}
                        options={["E - Everyone", "K - Kids", "T - Teen", "A - Adult"]}
                        watchMaturityLevel={watch('maturity_level')}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer className='custom-modal-footer'>
                <div
                    className="btn btn-hover iq-button iq-button text-medium navbar--button signup-button custom-btn"
                    onClick={handleSubmit(data => {
                        console.log("data===>", data)
                        const checkedData = validateAndSanitizeEditProfileForm(data)
                        console.log("checkedData===>", checkedData)
                        dispatch(EditProfile({ ...checkedData.sanitizedData, file }))
                    })}
                >
                    Save
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default EditProfileModal