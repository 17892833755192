import {
    CHANGE_CELLULAR_DATA_USAGE,
    CHANGE_EDIT_PROFILE_MODAL,
    CHANGE_FORGOT_PASSWORD_CODE,
    CHANGE_FORGOT_PASSWORD_CONFIRM_PASSWORD,
    CHANGE_FORGOT_PASSWORD_EMAIL,
    CHANGE_FORGOT_PASSWORD_MODAL,
    CHANGE_FORGOT_PASSWORD_NEW_PASSWORD,
    CHANGE_FORGOT_PASSWORD_STEP,
    CHANGE_LOGIN_EMAIL,
    CHANGE_LOGIN_PASSWORD,
    CHANGE_NOTIFICATIONS,
    CHANGE_SIGNUP_EMAIL,
    CHANGE_SIGNUP_NAME,
    CHANGE_SIGNUP_PASSWORD,
    CHANGE_UPDATE_PASSWORD_COMFIRM_PASSWORD,
    CHANGE_UPDATE_PASSWORD_NEW_PASSWORD,
    CHANGE_USER_PROFILE,
    CHANGE_VIDEO_QUALITY,
    MARK_NOIFICATIONS_AS_READ,
    TOGGLE_NOTIFICATIONS_SWITCH,
    TOGGLE_WIFI_ONLY_SWITCH,
    CHANGE_UPDATE_PASSWORD_MODAL,
    CHANGE_PUBLISHED_SHOWS,
    CHANGE_PUBLISHED_SEASONS,
    CHANGE_SCHEDULED_CONTENT,
    CHANGE_WALLET_PAYMENTS,
    CHANGE_ANALYTICS_DATA,
} from '../Actions/types'

const initialState = {
    analyticsData: [],
    cellularDataUsage: "Automatic",
    editProfileModal: false,
    faqs: [
        {
            question: 'What is Multitude?',
            answer: 'Multitude is a subscription-based, ad-free video publishing platform. We believe in the power of independent creators to tell compelling stories and we want to develop the best platform for that content to be seen.',
        },
        {
            question: 'Why can I only publish one episode per week?',
            answer: 'We want to encourage our creators to be thoughtful with what they produce. One episode per week ensures Multitude isn’t overwhelmed with low-effort click-bait content. You can produce whatever you want but when everyone can only publish once per week, the best content is most likely to stand out.',
        },
        {
            question: 'Why are my uploads limited to 3 hours?',
            answer: 'Server space for video storage is a significant cost so we want to make sure we limit our overhead by limiting how much can be uploaded. We also want creators to be thoughtful about making good content that doesn’t waste viewers’ time. 3 hours is a lot of room to be creative and productive. ',
        },
        {
            question: 'Can I have a free account?',
            answer: 'Free isn’t free. Every “free” service is making money somehow. We will never sell your personal data and we will never run ads on Multitude. The monthly subscription fee ensures we can run a stable business and provide you with the best user experience.',
        },
        {
            question: 'How is my content monetized?',
            answer: 'You can monetize your content on Multitude through royalties. Instead of paying creators via advertisers and ad impressions, our monthly revenue is divided up into royalty payments and paid back to creators based on the performance of their content.  ',
        },
        {
            question: 'What are the content restrictions?',
            answer: 'While there are some restrictions around content, we’ve written our content policies to resemble U.S. law as closely as possible. We don’t want to restrict freedom of speech and freedom of expression so if it is legal, it’s allowed. If it is illegal, it is not allowed.',
        },
        {
            question: 'Can I upload copyrighted content?',
            answer: 'No. Do not upload content that may be claimed under U.S. copyright law. Multitude is a platform for creators who create their own original content. Do not break the law and do not ruin Multitude for everyone else by violating copyright. There are plenty of resources online for finding royalty-free stock music, images, and videos.',
        },
        {
            question: 'What about fair use?',
            answer: 'Fair use is very subjective so in general, do not upload copyrighted material even under fair use conditions. However, if you absolutely must upload content that would fall under fair use, please ensure you are actually following fair use rules and understand that this content will be highly scrutinized.',
        },
        {
            question: 'What about pornography?',
            answer: 'Sex sells but not on Multitude. There are plenty of other places online to find pornography. Go there instead. If we allow sexually explicit content on Multitude, it will diminish the ability of other content creators to tell compelling stories. If you upload pornographic content, it will be removed.',
        },
        {
            question: 'Who is Multitude for?',
            answer: 'Multitude is for everyone. It’s for viewers looking for the best independent creations and for creators looking for a better platform for their content. Multitude is the most profitable platform for video and provides the best user experience for viewers. ',
        },
        {
            question: 'So it’s like Netflix?',
            answer: 'Kind of, but we don’t want to just produce shows we think you’ll like. We want to allow everyone the ability to produce whatever they think is worth watching. Good viewing experiences shouldn’t be locked away for Hollywood producers and industry insiders. All of us should have access to quality distribution.',
        },
        {
            question: 'So it’s like YouTube?',
            answer: 'Kind of, but it’s not the dumping grounds for an infinite spam of daily uploads and hours of endless streams . We know that user-generated content can be better than short video clips. Creators deserve a quality platform that rewards their creativity, effort, and excellence.',
        },
        {
            question: 'So what is the content like?',
            answer: 'The content is whatever you decide is worth watching. It could be a documentary, a short film, a news brief, a comedy special, an animated cartoon but it doesn’t have to be. Multitude is a space for experimentation. There’s no right or wrong answer to what can be uploaded.      ',
        },
        {
            question: 'Who made Multitude?',
            answer: 'Multitude is a completely independent and entirely self-funded project. As independent filmmakers, we built the platform from the ground up for ourselves and others so we could make the internet a better place for all creators.',
        },
    ],
    forgotPasswordCode: '',
    forgotPasswordConfirmPassword: '',
    forgotPasswordEmail: '',
    forgotPasswordModal: false,
    forgotPasswordNewPassword: '',
    forgotPasswordStep: 1,
    isNotificationSwitchOn: true,
    isWifiOnlySwitchOn: true,
    loginEmail: '',
    loginPassword: '',
    notifications: [],
    publishedSeasons: [],
    publishedShows: [],
    scheduledContent: [],
    signupEmail: '',
    signupName: '',
    signupPassword: '',
    updatePasswordComfirmPassword: '',
    updatePasswordModal: false,
    updatePasswordNewPassword: '',
    userProfile: {},
    videoQuality: "Standard Quality",
    walletPayments: [],
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case CHANGE_ANALYTICS_DATA:
            return {
                ...state,
                analyticsData: payload,
            }
        case CHANGE_WALLET_PAYMENTS:
            return {
                ...state,
                walletPayments: payload,
            }
        case CHANGE_SCHEDULED_CONTENT:
            return {
                ...state,
                scheduledContent: payload,
            }
        case CHANGE_PUBLISHED_SEASONS:
            return {
                ...state,
                publishedSeasons: payload,
            }
        case CHANGE_PUBLISHED_SHOWS:
            return {
                ...state,
                publishedShows: payload,
            }
        case CHANGE_UPDATE_PASSWORD_MODAL:
            return {
                ...state,
                updatePasswordModal: payload,
            }
        case CHANGE_FORGOT_PASSWORD_MODAL:
            return {
                ...state,
                forgotPasswordModal: payload,
                forgotPasswordStep: 1,
            }
        case CHANGE_EDIT_PROFILE_MODAL:
            return {
                ...state,
                editProfileModal: payload,
            }
        case MARK_NOIFICATIONS_AS_READ:
            let notifications = [...state.notifications];
            notifications = notifications.map(notification => {
                const { id } = notification || {}
                if (payload.includes(id))
                    return { ...notification, status: 'read' }
                return notification
            })
            return {
                ...state,
                notifications
            }
        case CHANGE_NOTIFICATIONS:
            return {
                ...state,
                notifications: payload
            }
        case CHANGE_FORGOT_PASSWORD_STEP:
            return {
                ...state,
                forgotPasswordStep: payload
            }
        case CHANGE_FORGOT_PASSWORD_CONFIRM_PASSWORD:
            return {
                ...state,
                forgotPasswordConfirmPassword: payload
            }
        case CHANGE_FORGOT_PASSWORD_NEW_PASSWORD:
            return {
                ...state,
                forgotPasswordNewPassword: payload
            }
        case CHANGE_FORGOT_PASSWORD_CODE:
            return {
                ...state,
                forgotPasswordCode: payload
            }
        case CHANGE_FORGOT_PASSWORD_EMAIL:
            return {
                ...state,
                forgotPasswordEmail: payload
            }
        case CHANGE_UPDATE_PASSWORD_COMFIRM_PASSWORD:
            return {
                ...state,
                updatePasswordComfirmPassword: payload
            }
        case CHANGE_UPDATE_PASSWORD_NEW_PASSWORD:
            return {
                ...state,
                updatePasswordNewPassword: payload
            }
        case CHANGE_USER_PROFILE:
            return {
                ...state,
                userProfile: payload
            }
        case CHANGE_LOGIN_PASSWORD:
            return {
                ...state,
                loginPassword: payload
            }
        case CHANGE_LOGIN_EMAIL:
            return {
                ...state,
                loginEmail: payload
            }
        //---------------------------------------------Signup states---------------------
        case CHANGE_SIGNUP_EMAIL:
            return {
                ...state,
                signupEmail: payload

            }
        case CHANGE_SIGNUP_NAME: {
            return {
                ...state,
                signupName: payload
            }
        }
        case CHANGE_SIGNUP_PASSWORD: {
            return {
                ...state,
                signupPassword: payload
            }
        }
        case TOGGLE_NOTIFICATIONS_SWITCH:
            return {
                ...state,
                isNotificationSwitchOn: !state.isNotificationSwitchOn
            }
        case TOGGLE_WIFI_ONLY_SWITCH:
            return {
                ...state,
                isWifiOnlySwitchOn: !state.isWifiOnlySwitchOn
            }
        case CHANGE_CELLULAR_DATA_USAGE:
            return {
                ...state,
                cellularDataUsage: payload
            }
        case CHANGE_VIDEO_QUALITY:
            return {
                ...state,
                videoQuality: payload
            }
        default:
            return state;
    }
}