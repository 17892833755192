import React, {useState} from 'react'
import { Waypoint } from "react-waypoint";
import { useSpring, animated } from '@react-spring/web'
import '../../../../../assets/css/custom/enjoyYourTv.css';
import alwaysAdFree from '../../../../../assets/images/custom/always-ad-free.png';

function EnjoyYourTv() {
    const [inView, setInview] = useState(false);
    const springs = useSpring({
        delay: 300,
        from: { y: -200 },
        to: {
            y: !inView ? -200 : 0,
            opacity: !inView ? 0 : 1,
          },
        config: {
          mass: 5,
          friction: 50,
          tension: 160,
        },
      })
      const bottomSprings = useSpring({
        delay: 100,
        from: { y: 200 },
        to: {
            y: !inView ? 200 : 0,
            opacity: !inView ? 0 : 1,
          },
        config: {
          mass: 5,
          friction: 50,
          tension: 160,
        },
      })
    return (
        <>
            <div className='gray-line'></div>
            <Waypoint onEnter={() => setInview(true)}>
                <div className='container-fluid dys__container '>
                    <animated.div style={{...springs}} className='col-4 dys__content-right always-free-container'>
                        <img
                            alt="ad free always"
                            className='dys__content-right-image always-ad-free'
                            src={alwaysAdFree}
                        />
                    </animated.div>

                    <animated.div style={{...bottomSprings}} className='col-md-6 dys__content-left'>
                        <h1 className='dys__content-left-header'>Always Ad Free</h1>
                        <p className='dys__content-text'>No ads, ever. Enjoy the freedown of a platform without advertisers.</p>
                    </animated.div>
                </div>
            </Waypoint>           
        </>
    )
}

export default EnjoyYourTv
