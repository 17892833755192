import React, { useState } from 'react'
import { Form } from 'react-bootstrap'

import previewImage from '../../../assets/images/custom/ml.png'

const LandscapeUpload = ({ handleImageUpload, originalLandscapeImg, short }) => {
    const [uploadImg, setUploadImg] = useState(null)

    const handleUpload = event => {
        setUploadImg(URL.createObjectURL(event.target.files[0]))
        handleImageUpload(event)
    }
    let landscapeImage = short ? `https://multitude.s3.wasabisys.com/lg/${originalLandscapeImg}` : originalLandscapeImg
    const backgroundImage = uploadImg ? uploadImg : landscapeImage || previewImage;
    return (
      <div
        className="form_video-upload text-center originalLandscapeImg"
        id="landscapeDiv"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <Form.Group controlId="landscapeFile" style={{ margin: 0 }}>
          <Form.Label className="btn btn-primary form_video-upload_button" style={{ marginTop: "50%", }}>
            Upload Landscape Image
            <Form.Control
              type="file"
              name="landscape_image"
              accept=".png, .jpg, .jpeg"
              onChange={handleUpload}
              hidden
            />
          </Form.Label>
        </Form.Group>
      </div>
    );
 
    // return (
    //     <div className='form_video-upload text-center' id='landscapeDiv' style={{ backgroundImage: uploadImg !== null || originalLandscapeImg ? `url(${originalLandscapeImg ? originalLandscapeImg : uploadImg})` : `url(${previewImage})` }}>
    //         <br /><br /><br />
    //         <br />
    //         <Form.Group controlId='landscapeFile' style={{ margin: 0 }}>
    //             <Form.Label className='btn btn-primary form_video-upload_button' style={{ margin: 0 }}>
    //                 Upload Landscape Image
    //                 <Form.Control type="file" name='landscape_image' accept='.png, .jpg, .jpeg' onChange={handleUpload} hidden/>
    //             </Form.Label>
    //         </Form.Group>
    //         <br />
    //     </div>
    // )
}

export default LandscapeUpload
