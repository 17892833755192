import moment from "moment";
import "swiper/swiper-bundle.css";
import "../../../../assets/css/custom/new-episodes.css";
import { GetEpisodeVideos, GetShowDetails, PlayNow } from './../../../../store/Actions/movieActions';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { CopyLink } from './CopyLink';
import { baseSharingURL } from "../../../../config/baseURL";

const RenderNewEpisodeSlides = ({episode}) => {
  const dispatch = useDispatch();
  console.log({episode})
  const {
    name,
    episode_count,
    description,
    landscape_image,
    release_date,
    id,
    showId
  } = episode;

  return(
    <>
      <div className="new-episode-tile position-relative red-border">
        <div className="top-div red-border">
          <div className="hover-buttons play-new-ep">
            <div
              className="btn btn-hover iq-button iq-button text-medium"
              onClick={() => dispatch(GetEpisodeVideos(id, true, true))}
              style={{ color: "black" }}
            >
              <i className="fa fa-play mr-2" aria-hidden="true"></i>
              Play Now
            </div>
          </div>
          <img
            alt="episode card"
            className="episode-image"
            onClick={() => dispatch(GetShowDetails(showId))}
            src={landscape_image}
          />
          <ul className="list-inline p-0 mt-4 share-icons music-play-lists ml-4">
            <li className="share">
              <span>
                <i className="custom-icon ri-share-fill"></i>
              </span>
              <div className="share-box">
                <div className="d-flex align-items-center">
                  <FacebookShareButton
                    url={baseSharingURL + showId}
                    title={name}
                  >
                    <i className="ri-facebook-fill"></i>
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={baseSharingURL + showId}
                    title={name}
                    className="share-ico"
                  >
                    <i className="ri-twitter-fill"></i>
                  </TwitterShareButton>
                  <Link to="#" 
                    onClick={() => CopyLink(showId)}
                    className="share-ico">
                    <i className="ri-links-fill"></i>
                  </Link>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className="bottom-div red-border">
          <div className="episode-name-date red-border">
            <div className="episode-name-div red-border">
              <span className="episode-name">
              <Link to="#" onClick={() => dispatch(GetShowDetails(showId))}>{name}</Link> - {episode_count ? `Episode ${episode_count}` : ""}
              </span>
            </div>
            <div className="episode-date-div red-border">
              <span className="episode-date">
                {moment(release_date).format("MMM Do YY")}
              </span>
            </div>
          </div>
          <div className="episode-desc red-border">{description}</div>
        </div>
      </div>
    </>
  )
}
 


export default RenderNewEpisodeSlides;