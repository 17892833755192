import React, {useState, useEffect} from 'react'
import { Table, Breadcrumb } from 'react-bootstrap'
import PublisherCard from '../PublisherCard'
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import ShowDetailsIcon from './WatchShowIcon';
import EditContentIcon from './EditContentIcon';
import DeleteConfirmBox from './DeleteConfirmBox';
// import { GetSeasonDataFromShow } from './../../../store/Actions/userActions';
import { GetShowDetails } from '../../../store/Actions/movieActions';
import moment from "moment";
import loader from '../../../assets/custom-assets/loader.gif'

const SeasonsList = ({setCompSwitch, tvShowId, currentComponent, setSidebarComponent, showName}) => {
    // const { publishedSeasons } = useSelector((state) => state.user);
    const { seasonDetail, refreshSidebar } = useSelector((state) => state.movie);
    const [show, setShow] = useState()

    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');

    const handleChangePage = (page) => {
        setCurrentPage(page);
    }

    const handleChangeItemsPerPage = (event) => {
        setItemsPerPage(event.target.value);
        setCurrentPage(0); // Reset to first page when items per page changes
    }

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(0); // Reset to first page when search term changes
    }

    // Filter shows based on search term
    // Slice shows based on current page and items per page
    // Calculate total pages
    const filteredShows = seasonDetail.seasons?.filter(season => season.season_name.toLowerCase().includes(searchTerm.toLowerCase()));
    console.log("filteredShows: ", filteredShows)
    const totalPages = Math.ceil(filteredShows?.length / itemsPerPage);
    const currentItems = filteredShows?.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);
    console.log("currentItems: ", currentItems)

    const dispatch = useDispatch()
    console.log("seasonDetail: ", seasonDetail);
    useEffect(() => {
        dispatch(GetShowDetails(tvShowId, true, true, true))
    }, [refreshSidebar]);

    useEffect(() => {
        let timer1 = setTimeout(() => setShow(true), 10000);
        return () => {
          clearTimeout(timer1);
        };
    }, []);
    return (
        <>
            <PublisherCard 
            title={ `Seasons List` }
            totalEntries={filteredShows?.length}
            currentPage={currentPage}
            totalPages={totalPages}
            handleChangePage={handleChangePage}
            itemsPerPage={itemsPerPage}
            handleChangeItemsPerPage={handleChangeItemsPerPage}
            searchTerm={searchTerm}
            handleSearchChange={handleSearchChange}
            
            >
                <Breadcrumb className="main-bg">
                    <Breadcrumb.Item 
                        onClick={() => setCompSwitch({string: "Show"})}  
                    >
                        Show List
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Seasons List</Breadcrumb.Item>
                </Breadcrumb>
                <Table className='dataTable'>
                    <thead>
                        <tr>
                            <th tabIndex={0} aria-label='#' style={{ width: '59px', paddingRight: '30px' }}>#</th>
                            <th tabIndex={0} aria-label='Show Title' style={{ width: '196px' }}>Show Title</th>
                            <th tabIndex={0} aria-label='Seasons No' style={{ width: '206px' }}>Season</th>
                            <th tabIndex={0} aria-label='Episodes' style={{ width: '194px' }}>Episodes No</th>
                            <th tabIndex={0} aria-label='Views' style={{ width: '123px' }}>Views</th>
                            <th tabIndex={0} aria-label='Release Date' style={{ width: '177px' }}>Release Date</th>
                            <th tabIndex={0} aria-label='Action' style={{ width: '138px' }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {seasonDetail.id === tvShowId && seasonDetail.seasons?.length ? currentItems.map((season) => {
                            let fullDate = moment(new Date(season.release_date)).format("MM DD YYYY")
                            return (
                                <tr key={season.season_id}>
                                    <td>{season.season_id}</td>
                                    <td>
                                        {showName}
                                    </td>
                                    <td>
                                        <Link to="#"
                                            onClick={() => setCompSwitch({string: "Episode", seasonId: season.season_id, seasonTitle: season.season_name, showName, tvShowId})}
                                            >
                                        {season.season_name}
                                        </Link>
                                    </td>
                                    <td>{season.episode_count}</td>
                                    <td>{season.views_count}</td>
                                    <td>{fullDate.split(" ").join("/")}</td>
                                    <td>
                                        <ShowDetailsIcon showId={tvShowId}/>
                                        {' '}
                                        <EditContentIcon setSidebarComponent={setSidebarComponent} contentId={season.season_id} switchComponent="EditSeason" />
                                        {' '}
                                        <DeleteConfirmBox currentComponent={currentComponent} currentId={season.season_id}/>
                                    </td>
                                </tr>
                            )
                        })
                        :
                            <tr >
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                {!show ?
                                    <img
                                        alt="loader"
                                        src={loader}
                                        style={{
                                            height: 50,
                                            width: 50,
                                            borderRadius: 5,
                                            marginLeft: '-20%'
                                            
                                        }}
                                    />
                                    :
                                    "No Seasons Found"
                                
                                }
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                    }
                    </tbody>
                </Table>
            </PublisherCard>
        </>
    )
}

export default SeasonsList
