import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import store from '../';
import { baseURL } from '../../config/baseURL';
import {
    CHANGE_CELLULAR_DATA_USAGE,
    CHANGE_EDIT_PROFILE_MODAL,
    CHANGE_FORGOT_PASSWORD_CODE,
    CHANGE_FORGOT_PASSWORD_CONFIRM_PASSWORD,
    CHANGE_FORGOT_PASSWORD_EMAIL,
    CHANGE_FORGOT_PASSWORD_MODAL,
    CHANGE_FORGOT_PASSWORD_NEW_PASSWORD,
    CHANGE_FORGOT_PASSWORD_STEP,
    CHANGE_LOADER,
    CHANGE_LOGIN_EMAIL,
    CHANGE_LOGIN_PASSWORD,
    CHANGE_NOTIFICATIONS,
    CHANGE_SHOW_FORGOT_PASSWORD_MODAL,
    CHANGE_SIGNUP_EMAIL,
    CHANGE_SIGNUP_NAME,
    CHANGE_SIGNUP_PASSWORD,
    CHANGE_STATUS,
    CHANGE_UPDATE_PASSWORD_COMFIRM_PASSWORD,
    CHANGE_UPDATE_PASSWORD_MODAL,
    CHANGE_UPDATE_PASSWORD_NEW_PASSWORD,
    CHANGE_USER_DATA,
    CHANGE_USER_PROFILE,
    CHANGE_VIDEO_QUALITY,
    MARK_NOIFICATIONS_AS_READ,
    REMOVE_LOADER_AND_RESET_STATUS,
    SUBSCRIBE_USER,
    UNSUBSCRIBE_USER,
    TOGGLE_NOTIFICATIONS_SWITCH,
    TOGGLE_WIFI_ONLY_SWITCH,
    CHANGE_PUBLISHED_SHOWS,
    CHANGE_PUBLISHED_SEASONS,
    CHANGE_SCHEDULED_CONTENT,
    CHANGE_WALLET_PAYMENTS,
    CHANGE_ANALYTICS_DATA,
} from './types';

//STATE MANAGMENT
export const SubscribeUser = () => {
    return async (dispatch) => {
        await dispatch({ type: SUBSCRIBE_USER })
    }
}
export const UnSubscribeUser = () => {
    return async (dispatch) => {
        await dispatch({ type: UNSUBSCRIBE_USER })
    }
}
export const ChangeLoader = (val) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_LOADER, payload: val })
    }
}
export const ChangeUpdatePasswordModal = (val) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_UPDATE_PASSWORD_MODAL, payload: val })
    }
}
export const ChangeForgotPasswordModal = (val) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_FORGOT_PASSWORD_MODAL, payload: val })
    }
}
export const ChangeEditProfileModal = (val) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_EDIT_PROFILE_MODAL, payload: val })
    }
}
export const ChangeShowForgotPasswordModal = (val) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_SHOW_FORGOT_PASSWORD_MODAL, payload: val })
    }
}
export const ChangeForgotPasswordCode = (val) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_FORGOT_PASSWORD_CODE, payload: val })
    }
}
export const ChangeForgotPasswordNewPassword = (val) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_FORGOT_PASSWORD_NEW_PASSWORD, payload: val })
    }
}
export const ChangeForgotPasswordConfirmPassword = (val) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_FORGOT_PASSWORD_CONFIRM_PASSWORD, payload: val })
    }
}
export const ChangeForgotPasswordStep = (val) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_FORGOT_PASSWORD_STEP, payload: val })
    }
}
export const ChangeForgotPasswordEmail = (val) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_FORGOT_PASSWORD_EMAIL, payload: val })
    }
}
export const ChangeUpdatePasswordComfirmPassword = (val) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_UPDATE_PASSWORD_COMFIRM_PASSWORD, payload: val })
    }
}
export const ChangeUpdatePasswordNewPassword = (val) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_UPDATE_PASSWORD_NEW_PASSWORD, payload: val })
    }
}
export const ChangeUserData = (val) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_USER_DATA, payload: val })
    }
}
export const ChangeLoginEmail = (val) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_LOGIN_EMAIL, payload: val })
    }
}

export const ChangeSignupEmail = (val) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_SIGNUP_EMAIL, payload: val })
    }
}
export const ChangeSignupPassword = (val) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_SIGNUP_PASSWORD, payload: val })
    }
}

export const ChangeSignupName = (val) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_SIGNUP_NAME, payload: val })
    }
}
export const ChangeLoginPassword = (val) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_LOGIN_PASSWORD, payload: val })
    }
}
export const ToggleNotificationSwitch = () => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_NOTIFICATIONS_SWITCH, payload: null })
        UserSettings()
    }
}
export const ToggleWifiOnlySwitch = () => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_WIFI_ONLY_SWITCH, payload: null })
        UserSettings()
    }
}
export const ChangeCellularDataUsage = (val) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_CELLULAR_DATA_USAGE, payload: val })
        UserSettings()
    }
}
export const ChangeVideoQuality = (val) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_VIDEO_QUALITY, payload: val })
    }
}

//FETCH AND DISPATCH
export const Subscription = (params) => {
    return async (dispatch) => {
        try {
            dispatch({ type: CHANGE_LOADER, payload: true })

            let myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + store.getState().shared.userData.token);
            
            let requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            let result = await fetch(baseURL + "/api/subscriptionLink", requestOptions)
                .then(response => response.json().then((data) => ({ status: response.status, data })))

            const { status } = result;
        
            if (status >= 200 && status < 300) {
                window.location.replace(result.data.data.payment_link);
                // history.push('/payment-successful')
                // <Redirect to="/payment-successful" />;
                // toast("Payment Successful");
                // dispatch({ type: SUBSCRIBE_USER});
                // toast("User Subscribed");
                dispatch({ type: CHANGE_LOADER, payload: false })
            } else {
                console.error("Something went wrong")
                dispatch({ type: CHANGE_LOADER, payload: false })
                toast("Error Processing Transaction")
            }


        } catch (error) {
            console.error("error===>", error)
            dispatch({ type: CHANGE_LOADER, payload: false })
        }

    }
}
export const PayPalSubscription = (params) => {
    return async (dispatch) => {
        try {
            dispatch({ type: CHANGE_LOADER, payload: true })
            const {
                orderId,
                planId,
                subscriptionID,
                history,
            } = params || {}
            let myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + store.getState().shared.userData.token);
            
            let formdata = new FormData();
            
            formdata.append("type", "paypal");
            formdata.append("orderId", orderId);
            formdata.append("subscriptionID", subscriptionID);
            formdata.append('planId', planId);

            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            let result = await fetch(baseURL + "/api/subscription", requestOptions)
                .then(response => response.json().then((data) => ({ status: response.status, data })))

            const { status, data } = result;

            if (status >= 200 && status < 300) {
                history.push('/payment-successful')
                // <Redirect to="/payment-successful" />;
                toast("Payment Successful");
                dispatch({ type: SUBSCRIBE_USER});
                toast("User Subscribed");
                dispatch({ type: CHANGE_LOADER, payload: false })
            } else {
                console.error("Something went wrong")
                dispatch({ type: CHANGE_LOADER, payload: false })
                toast("Error Processing Transaction")
            }


        } catch (error) {
            console.error("error===>", error)
            dispatch({ type: CHANGE_LOADER, payload: false })
        }

    }
}
export const EditProfile = (params) => {
    return async (dispatch) => {
        try {
            dispatch({ type: CHANGE_LOADER, payload: true })

            const { name, email, address, file, maturity_level } = params || {}

            let myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + store.getState().shared.userData.token);

            let formdata = new FormData();
            formdata.append("name", name);
            formdata.append("email", email);
            formdata.append("address", address);
            formdata.append("maturity_level", maturity_level);
            if (file?.type)
                formdata.append("profile_photo_path", file, file?.uri);

            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            let result = await fetch(baseURL + "/api/editProfile", requestOptions)
                .then(response => response.json().then((data) => ({ status: response.status, data })))

            const { status, data } = result;

            if (status >= 200 && status < 300) {
                toast(data?.user)
                dispatch(GetUserProfile())
                dispatch({ type: CHANGE_EDIT_PROFILE_MODAL, payload: false })
            } else if (status >= 400 && status < 500) {
                dispatch({ type: CHANGE_LOADER, payload: false })
                toast(data?.errors?.email)
            } else {
                dispatch({ type: CHANGE_LOADER, payload: false })
                console.error("Something went wrong")
            }


        } catch (error) {
            console.error("error===>", error)
            dispatch({ type: CHANGE_LOADER, payload: false })
        }

    }
}
export const GetNotifications = () => {
    return async (dispatch) => {

        //todo: call api here

        dispatch({ type: CHANGE_NOTIFICATIONS, payload: null })
    }
}
export const MarkAsRead = (ids) => {
    return async (dispatch) => {

        try {
            dispatch({ type: MARK_NOIFICATIONS_AS_READ, payload: ids })

            let stringIds = `[${ids.join(",")}]`

            let myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + store.getState().shared.userData.token);

            let formdata = new FormData();
            formdata.append("ids", stringIds);

            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            let result = await fetch(baseURL + "/api/mark-as-read", requestOptions)
                .then(response => response.json().then((data) => ({ status: response.status, data })))

            const { status, data } = result;

            if (status >= 200 && status < 300) {
                // toast(data?.message)
            }
            else
                console.error("Something went wrong")

        } catch (error) {
            console.error("error===>", error)
        }

    }
}
export const SendForgotPasswordEmail = (params) => {
    return async (dispatch) => {

        try {
            const { email } = params

            dispatch({ type: CHANGE_LOADER, payload: true })
            let myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", "Bearer " + store.getState().shared.userData.token);
            myHeaders.append("Content-Type", "application/json");

            let raw = JSON.stringify({
                email,
            });

            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            let result = await fetch(baseURL + '/api/forgot_password', requestOptions)
                .then(response => response.json().then((data) => ({ status: response.status, data })))

            const { status, data } = result || {};

            if (status >= 200 && status < 300) {
                toast(data?.message)
                dispatch({ type: CHANGE_FORGOT_PASSWORD_STEP, payload: 2 })
            } else {
                console.error("Something went wrong")
            }

            dispatch({ type: CHANGE_LOADER, payload: false })

        } catch (error) {
            dispatch({ type: CHANGE_LOADER, payload: false })
            console.error("error===>", error)
        }
    }
}
export const VerifyCode = (params) => {
    return async (dispatch) => {
        try {

            dispatch({ type: CHANGE_LOADER, payload: true })

            const { email, code } = params

            let myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", "Bearer " + store.getState().shared.userData.token);
            myHeaders.append("Content-Type", "application/json");

            let raw = JSON.stringify({
                email,
                code,
            });

            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            let result = await fetch(baseURL + '/api/code-verify', requestOptions)
                .then(response => response.json().then((data) => ({ status: response.status, data })))

            const { status, data } = result || {};

            if (status >= 200 && status < 300) {
                if (data?.message?.includes("incorrect"))
                    toast(data?.message)
                else {
                    toast("Code is verified")
                    dispatch({ type: CHANGE_FORGOT_PASSWORD_STEP, payload: 3 })
                }
            } else {
                console.error("Something went wrong")
            }

            dispatch({ type: CHANGE_LOADER, payload: false })

            return { status, data }

        } catch (error) {
            dispatch({ type: CHANGE_LOADER, payload: false })
            console.error("error===>", error)
        }
    }
}
export const RecoverThePassword = (params) => {

    return async (dispatch) => {

        try {

            const { email, password, token } = params

            dispatch({ type: CHANGE_LOADER, payload: true })
            let myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", "Bearer " + token);
            myHeaders.append("Content-Type", "application/json");

            let raw = JSON.stringify({
                email,
                password,
            });

            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            let result = await fetch(baseURL + '/api/update_password', requestOptions)
                .then(response => response.json().then((data) => ({ status: response.status, data })))

            const { status, data } = result || {};

            if (status >= 200 && status < 300) {
                toast(data?.message)
                dispatch({ type: CHANGE_FORGOT_PASSWORD_MODAL, payload: false })
            } else {
                console.error("Something went wrong")
            }

            dispatch({ type: CHANGE_LOADER, payload: false })

            return result

        } catch (error) {
            dispatch({ type: CHANGE_LOADER, payload: false })
            console.error("error===>", error)
        }
    }
}
export const UpdateThePassword = (params) => {
    return async (dispatch) => {

        try {

            const { password, oldPassword } = params

            dispatch({ type: CHANGE_LOADER, payload: true })
            let myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", "Bearer " + store.getState().shared.userData.token);
            myHeaders.append("Content-Type", "application/json");

            let raw = JSON.stringify({
                oldPassword,
                "email": store.getState().shared.userData.email,
                password,
            });

            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            let result = await fetch(baseURL + '/api/update_password', requestOptions)
                .then(response => response.json().then((data) => ({ status: response.status, data })))

            const { status, data } = result || {};

            if (status === 200) {
                dispatch({ type: CHANGE_UPDATE_PASSWORD_MODAL, payload: false })
                toast(data?.message)
            } else {
                console.error("Something went wrong while fetching user profile")
            }
            dispatch({ type: CHANGE_LOADER, payload: false })

            return { status, data }

        } catch (error) {
            console.log("error===>", error)
            dispatch({ type: CHANGE_LOADER, payload: false })
        }
    }
}
export const GetUserProfile = () => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_LOADER, payload: true })
        let myHeaders = new Headers();
        myHeaders.append("ac", "application/json");
        myHeaders.append("Authorization", "Bearer " + store.getState().shared.userData.token);
        let requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(baseURL + '/api/profile',
            requestOptions
        ).then((response) => {
            dispatch({ type: CHANGE_STATUS, payload: response.status })
            return response.json()
        }).then((json) => {
            if (store.getState().shared.status == 200) {
                dispatch({ type: CHANGE_USER_PROFILE, payload: json.data })
            } else {
                console.error("Something went wrong while fetching user profile")
            }
            dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
        }).catch((error) => {
            console.error(error)
            dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
        });
    }
}
export const UserSettings = () => {
    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + store.getState().shared.userData.token);

    let formdata = new FormData();
    formdata.append("allow_notifications", store.getState().user.isNotificationSwitchOn);
    formdata.append("download_on_wifi", store.getState().user.isWifiOnlySwitchOn);
    formdata.append("circular_data", store.getState().user.cellularDataUsage);

    let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(baseURL + "/api/user-settings",
        requestOptions
    ).then((response) => {
        return response.json()
    }).then((json) => {
        if (store.getState().shared.status == 200) {
            console.info("Settings are updated")
        } else {
            console.error("Something went wrong while deleting the downloaded episode")
        }
    }).catch((error) => {
        console.error(error)
    });
}

export const Signup = (data) => {
    return async (dispatch) => {

        const { email, password, name, resetPassword, history } = data || {}

        dispatch({ type: CHANGE_LOADER, payload: true })

        let requestOptions = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "email": email,
                "password": password,
                "name": name,
                "device_token": "123456789",
                "device_type": "web",
                "device_key": "123456"
            })
        }

        fetch(baseURL + '/api/register', requestOptions)
            .then((response) => {
                return response.json()
            }).then((json) => {
                if (json.status_code >= 200 && json.status_code < 300) {

                    dispatch({ type: CHANGE_USER_DATA, payload: json.data })
                    history.push('/plan-details')

                } else if (json.status_code >= 400 && json.status_code < 500) {

                    const { errors } = json || {}

                    if (errors?.email)
                        toast(errors?.email)


                } else
                    console.error("Something went wrong while registering")

                dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })

            }).catch((error) => {
                console.error(error)
                dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
            })
    }
}
export const Login = (username, password, history) => {
    return async (dispatch) => {
        if (username.length === 0 || password.length === 0) {
            //todo: show toast here
        } else {

            dispatch({ type: CHANGE_LOADER, payload: true })
            fetch(baseURL + '/api/login', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "email": username,
                    "password": password,
                    "device_key": "123456",
                })
            }).then((response) => {
                dispatch({ type: CHANGE_STATUS, payload: response.status })
                return response.json()
            }).then((json) => {
                if (store.getState().shared.status === 200) {
                    dispatch({ type: CHANGE_LOGIN_EMAIL, payload: '' })
                    dispatch({ type: CHANGE_LOGIN_PASSWORD, payload: '' })
                    dispatch({ type: CHANGE_USER_DATA, payload: json.data })
                    history.push('/plan-details')
                    dispatch({ type: CHANGE_LOADER, payload: false })
                } else if (store.getState().shared.status === 404) {
                    toast(json.message)
                    history.push('/sign-in')
                    dispatch({ type: CHANGE_LOADER, payload: false })
                } else {
                    toast(json.message)
                    console.error("Something went wrong while logging in")
                    dispatch({ type: CHANGE_LOADER, payload: false })
                }

            }).catch((error) => {
                toast('Something went wrong while logging in')
                console.error(error)
                dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
            });
        }
    }
}
export const Logout = () => {
    return async (dispatch) => {
        try {
            const token = store.getState().shared.userData.token

            dispatch({ type: CHANGE_USER_DATA, payload: '' })
            localStorage.removeItem('userData')
            let myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + token);
            let requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(baseURL + "/api/logout",
                requestOptions
            ).then(response => {
                return response.json()
            }).then(json => {
                if (json.status_code === 200) {
                    console.info(json.data)
                } else {
                    console.error("Something went wrong while logging out")
                }
            }).catch(error => {
                console.error('error', error)
            });
        } catch (e) {
            console.error(e)
        }
    }
}

//HELPING METHODS   
export const GetUserData = async () => {
    try {
        // const jsonValue = await AsyncStorage.getItem('@user')
        // return jsonValue != null ? JSON.parse(jsonValue) : null;
    } catch (e) {
        // error reading value
    }
}

export const CancelMembership = () => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_LOADER, payload: true })
        const myHeaders = new Headers()
        myHeaders.append("Authorization", "Bearer " + store.getState().shared.userData.token)    
        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow'
        }
        fetch(baseURL + "/api/cancel-subscription",
            requestOptions
        ).then(response => {
            return response.json()
        }).then(result => {
            if (result.status_code === 200) {
                // dispatch({ type: UNSUBSCRIBE_USER})
                toast("Membership Canceled Successfully")
                dispatch({ type: CHANGE_LOADER, payload: false })
            }
            else {
                console.error("Error While Canceling Membership")
                toast("Error While Canceling Membership, Please Try Again")
                dispatch({ type: CHANGE_LOADER, payload: false })
            }
        }).catch(error => {
            toast("Error While Canceling Membership, Please Try Again")
            console.log('error', error)
            dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
        })
    }
}

export const UpdatePaymentInfo = (params) => {
    return async (dispatch) => {
        try {
            dispatch({ type: CHANGE_LOADER, payload: true })

            let {
                stripeToken,
                paymentMethod,
            } = params || {}

            let myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + store.getState().shared.userData.token);

            let formdata = new FormData();
            formdata.append("stripeToken", stripeToken);
            formdata.append("paymentMethod", paymentMethod);

            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            let result = await fetch(baseURL + "/api/update-subscription", requestOptions)
                .then(response => response.json().then((data) => ({ status: response.status, data })))

            const {status} = result;

            if (status >= 200 && status < 300) {
                toast("Payment Information Update Successful")
                dispatch({ type: CHANGE_LOADER, payload: false })
            } else {
                console.error("Something went wrong")
                toast("Error While Updating Information, Please Try Again")
                dispatch({ type: CHANGE_LOADER, payload: false })
            }
        } catch (error) {
            console.error("error===>", error)
            dispatch({ type: CHANGE_LOADER, payload: false })
        }

    }
}

export const VerifyActiveSubscription = (history, redirect) => {
    return async (dispatch) => {
            const myHeaders = new Headers()
            myHeaders.append("Authorization", "Bearer " + store.getState().shared.userData.token)    
            const requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            }
            fetch(baseURL + "/api/active-subscription",
                requestOptions
            ).then(response => {
                return response.json()
            }).then(result => {
                if (result.status_code > 199 && result.status_code < 300) {
                    dispatch({ type: SUBSCRIBE_USER})
                    if(redirect){
                        history.push('/')
                    }
                } else if(result.status_code > 399 && result.status_code < 500){
                    console.log("checking sub false");
                    // dispatch({ type: UNSUBSCRIBE_USER})
                    // toast("Membership Status: Canceled")
                    history.push('/plan-details')
                }
                dispatch({ type: CHANGE_LOADER, payload: false })
            }).catch(error => {
                dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
                console.error('error checking sub: ' + error)
            })
    }
}

export const GetPublisherShowList = () => {
    return async (dispatch) =>{
        const token = store.getState().shared.userData.token

            const myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + token);
            const requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            }

            fetch(baseURL + "/api/publisher/tvshow",
                requestOptions
            ).then(response => {
                return response.json()
            }).then(json => {
                if (json.status_code === 200) {
                    dispatch({ type: CHANGE_PUBLISHED_SHOWS, payload: json.data })
                } else {
                    console.error("Something went wrong while fetching tv show information")
                }
            }).catch(error => {
                dispatch({ type: REMOVE_LOADER_AND_RESET_STATUS, payload: null })
                console.error('error', error)
            })
            
    }
}
export const GetSeasonDataFromShow = () => {
    return async (dispatch) =>{
        const token = store.getState().shared.userData.token

            let myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + token);
            let requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            }

            fetch(baseURL + "/api/publisher/get-show-season",
                requestOptions
            ).then(response => {
                return response.json()
            }).then(json => {
                if (json.status_code === 200) {
                    dispatch({ type: CHANGE_PUBLISHED_SHOWS, payload: json.show_data })
                    dispatch({ type: CHANGE_PUBLISHED_SEASONS, payload: json.season_data })
                } else {
                    console.error("Something went wrong while fetching show / season information")
                }
            }).catch(error => {
                console.error('error', error)
            })
    }
}

export const GetScheduledContent = () => {
    return async (dispatch) =>{
        const token = store.getState().shared.userData.token
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);
        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }
        fetch(baseURL + "/api/publisher/schedule/content",
            requestOptions
        ).then(response => {
            return response.json()
        }).then(json => {
            if (json.status_code === 200) {
                dispatch({ type: CHANGE_SCHEDULED_CONTENT, payload: json.data})
            } else {
                console.error("Something went wrong while fetching scheduled content")
            }
        }).catch(error => {
            console.error('error', error)
        })
    }
}

export const GetWalletPayments = () => {
    return async (dispatch) =>{
        const token = store.getState().shared.userData.token
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);
        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }
        fetch(baseURL + "/api/publisher/wallet/payments",
            requestOptions
        ).then(response => {
            return response.json()
        }).then(json => {
            if (json.status_code === 200) {
                dispatch({ type: CHANGE_WALLET_PAYMENTS, payload: json.data})
            } else {
                console.error("Something went wrong while fetching wallet payments")
            }
        }).catch(error => {
            console.error('error', error)
        })
    }
}

export const GetAnalytics = (showId) => {
    return async (dispatch) =>{
        const token = store.getState().shared.userData.token
        let myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);
        let requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }
        fetch(baseURL + `/api/publisher/get-analytics/${showId}`,
            requestOptions
        ).then(response => {
            return response.json()
        }).then(json => {
            if (json.status_code === 200) {
                dispatch({ type: CHANGE_ANALYTICS_DATA, payload: json.data})
            } else {
                console.error("Something went wrong while fetching analytics")
            }
        }).catch(error => {
            console.error('error', error)
        })
    }
}

export const EnableMonetization = (params) => {
    return async (dispatch) => {
        try {
            dispatch({ type: CHANGE_LOADER, payload: true })

            let myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + store.getState().shared.userData.token);

            let formdata = new FormData();
            for (let key in params) {
                formdata.append(key, params[key]);
            }
            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            let result = await fetch(baseURL + "/api/enable-monetization", requestOptions)
                .then(response => response.json().then((data) => ({ status: response.status, data })))

            const {status} = result;

            if (status >= 200 && status < 300) {
                toast("Enable Monetization Successful")
                dispatch({ type: CHANGE_LOADER, payload: false })
            } else {
                console.error("Something went wrong")
                toast("Error While Enabling Monetization, Please Try Again")
                dispatch({ type: CHANGE_LOADER, payload: false })
            }
        } catch (error) {
            console.error("error===>", error)
            dispatch({ type: CHANGE_LOADER, payload: false })
        }

    }
}
