import React, { useEffect, useState } from 'react'
import Avatar from 'react-avatar'
import { Button, ButtonGroup, Col, Container, Dropdown, Nav, Navbar, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import logo from '../../../../assets/custom-assets/logo.png'
import Card from '../../../../components/Card'
import CustomToggle from '../../../../components/dropdowns'
// import { baseURL } from '../../../../config/baseURL'
import {
    ChangeCurrentCategory,
    ChangeSearchQuery,
    GetShowsByCategory
} from '../../../../store/Actions/movieActions'
import {
    Logout
} from '../../../../store/Actions/userActions'
import Notifications from './Notifications'

const HeaderStyle1 = (props) => {
    const dispatch = useDispatch()
    const history = useHistory();
    const { genre } = useSelector((state) => state.movie);
    const { notifications, } = useSelector((state) => state.user)
    const { userData } = useSelector((state) => state.shared)
    let location = useLocation();

    const [localSearchQuery, setLocalSearchQuery] = useState("");
    const [isTouchedTop, ChangeIsTouchedTop] = useState(true)

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            if (localSearchQuery)
                dispatch(ChangeSearchQuery(history, localSearchQuery))
        }
    }
    function RenderGenre() {
        return genre.map((item, key) => {
            const { id, name } = item
            return (
                <Dropdown.Item
                    key={id}
                    href="#"
                    onClick={(e) => {
                        dispatch(ChangeCurrentCategory(name))
                        dispatch(GetShowsByCategory(history, id))
                    }}
                >
                    {name}
                </Dropdown.Item>
            )
        })
    }
    function RenderBell() {

        const unreadNotifications = notifications.filter(obj => obj.status === "unread").length

        //will only render bell when there are unread items. change to -1 if you want all. or remove the filter up above
        return (
            <>
                {notifications.length > 0 &&
                    <Dropdown as="li" className="nav-item ">
                        <Dropdown.Toggle
                            href="#" as={CustomToggle} variant="search-toggle position-relative">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22"
                                className="noti-svg">
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path
                                    d="M18 10a6 6 0 1 0-12 0v8h12v-8zm2 8.667l.4.533a.5.5 0 0 1-.4.8H4a.5.5 0 0 1-.4-.8l.4-.533V10a8 8 0 1 1 16 0v8.667zM9.5 21h5a2.5 2.5 0 1 1-5 0z" />
                            </svg>
                            {unreadNotifications > 0 &&
                                <span className="bg-danger dots notification-badge">
                                    {unreadNotifications}
                                </span>
                            }
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="iq-sub-dropdown" align="right">
                            <Notifications />
                        </Dropdown.Menu>
                    </Dropdown>
                }
            </>
        )
    }

    useEffect(() => {
        window.addEventListener('scroll', (e) => {
            if (document.documentElement.scrollTop > 0)
                ChangeIsTouchedTop(false)
            else
                ChangeIsTouchedTop(true)
        })
    }, [])


    return (
        <>
            <header
                id="main-header"
                className={isTouchedTop ? 'touched-top-header my-header' : 'not-touched-top-header my-header'}
            >
                <div className="main-header">
                    <Container fluid>
                        <Row>
                            <Col sm="12">
                                <Navbar style={{ flexWrap: 'nowrap' }} className='custom-navbar p-0' expand="lg" >
                                    <Navbar.Toggle className="c-toggler">
                                        <div className="navbar-toggler-icon"  >
                                            <span className="navbar-menu-icon navbar-menu-icon--top"></span>
                                            <span className="navbar-menu-icon navbar-menu-icon--middle"></span>
                                            <span className="navbar-menu-icon navbar-menu-icon--bottom"></span>
                                        </div>
                                    </Navbar.Toggle>
                                
                                        <Navbar.Brand className={`${location.pathname === "/publisher" ? "invisible" : "navbar-brand"} `} href="/">
                                            <img className="img-fluid logo" src={logo} alt="multitude" />
                                        </Navbar.Brand>
                                    <Navbar.Collapse>
                                        <div className="menu-main-menu-container">
                                            <Nav as="ul" id="top-menu" className="ml-auto">
                                                <li className="menu-item">
                                                    <Link to="/">Home</Link>
                                                </li>
                                                <li className="menu-item">
                                                    <Link to="/new-episodes">New</Link>
                                                </li>
                                                <li className="menu-item">
                                                    <Link to="/trending">Trending</Link>
                                                </li>
                                                <li className="menu-item">
                                                    <Link to="/following">Following</Link>
                                                </li>
                                            </Nav>
                                        </div>
                                    </Navbar.Collapse>
                                    <div className="navbar-right menu-right">
                                        <ul className="d-flex align-items-center list-inline m-0">
                                            <Dropdown style={{ marginRight: 50 }} as="li" className="nav-item nav-icon">
                                                <Dropdown.Toggle as={ButtonGroup} variant="secondary">
                                                    <Button variant="link">Categories</Button>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="two-column category-dropdown">
                                                    {RenderGenre()}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <Dropdown as="li" className="nav-item nav-icon">
                                                <Dropdown.Toggle as={CustomToggle} href="#" variant="search-toggle device-search">
                                                    <i className="ri-search-line"></i>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="search-box iq-search-bar d-search p-0 m-0" align="right">
                                                    <div className="searchbox">
                                                        <div className="position-relative">
                                                            {/* //* Web input */}
                                                            <input
                                                                onChange={(e) => setLocalSearchQuery(e.target.value)}
                                                                type="text"
                                                                className="text search-input font-size-12"
                                                                placeholder="Enter a search query..."
                                                                onKeyDown={handleKeyPress}
                                                            />
                                                            <i className="search-link ri-search-line"></i>
                                                        </div>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            {RenderBell()}
                                            <Dropdown as="li" className="nav-item nav-icon">
                                                <Dropdown.Toggle href="#" as={CustomToggle} variant="search-toggle">
                                                    <div className="iq-user-dropdown search-toggle p-0 d-flex align-items-center active"
                                                        data-toggle="search-toggle">
                                                        <Avatar
                                                            className='profile-avatar profile-avatar-small'
                                                            src={userData.profile_photo_path}
                                                            name={userData?.name[0]}
                                                            round
                                                            size="35px"
                                                        />
                                                    </div>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="iq-sub-dropdown iq-user-dropdown drpdwn-menu" align="right">
                                                    <Card className="shadow-none m-0">
                                                        <Card.Body className="p-0 pl-3 pr-3">
                                                            <Dropdown.Item className="dropdown-itemz">
                                                                <div onClick={() => history.push('/publisher')} className="iq-sub-card setting-dropdown">
                                                                    <div className="media align-items-center">
                                                                        <div className="right-icon">
                                                                            <i className="ri-dashboard-line text-primary"></i>
                                                                        </div>
                                                                        <div className="media-body ml-3">
                                                                            <h6 className="my-0 ">Dashboard</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item className="dropdown-itemz">
                                                                <div onClick={() => history.push('/show')} className="iq-sub-card setting-dropdown">
                                                                    <div className="media align-items-center">
                                                                        <div className="right-icon">
                                                                            <i className="ri-vidicon-2-line text-primary"></i>
                                                                        </div>
                                                                        <div className="media-body ml-3">
                                                                            <h6 className="my-0 ">Show</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item className="dropdown-itemz">
                                                                <div onClick={() => history.push('/profile')} className="iq-sub-card setting-dropdown">
                                                                    <div className="media align-items-center">
                                                                        <div className="right-icon">
                                                                            <i className="ri-settings-4-line text-primary"></i>
                                                                        </div>
                                                                        <div className="media-body ml-3">
                                                                            <h6 className="my-0 ">Profile</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item className="dropdown-itemz">
                                                                <div
                                                                    style={{ cursor: 'pointer' }}
                                                                    className="iq-sub-card setting-dropdown"
                                                                    onClick={() => {
                                                                        dispatch(Logout())
                                                                    }}
                                                                >
                                                                    <div className="media align-items-center">
                                                                        <div className="right-icon">
                                                                            <i className="ri-logout-circle-line text-primary"></i>
                                                                        </div>
                                                                        <div className="media-body ml-3">
                                                                            <h6 className="my-0 ">Logout</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Dropdown.Item>
                                                        </Card.Body>
                                                    </Card>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </ul>
                                    </div>
                                </Navbar>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </header>
        </>
    )
}

export default HeaderStyle1
