import React, {useEffect, useState} from 'react'
import { Table } from 'react-bootstrap'
import PublisherCard from './PublisherCard'
import ShowDetailsIcon from './showlistComponents/WatchShowIcon';
import EditContentIcon from './showlistComponents/EditContentIcon';
import DeleteConfirmBox from './showlistComponents/DeleteConfirmBox';
import { useDispatch, useSelector } from 'react-redux';
import { GetScheduledContent } from '../../store/Actions/userActions';

const ScheduledContent = ({setSidebarComponent}) => {
    const { scheduledContent } = useSelector((state) => state.user);
    const dispatch = useDispatch()

    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');

    const handleChangePage = (page) => {
        setCurrentPage(page);
    }

    const handleChangeItemsPerPage = (event) => {
        setItemsPerPage(event.target.value);
        setCurrentPage(0); // Reset to first page when items per page changes
    }

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(0); // Reset to first page when search term changes
    }

    // Filter shows based on search term
    // Slice shows based on current page and items per page
    // Calculate total pages
    console.log("scheduledContent: ", scheduledContent)
    const filteredShows = scheduledContent?.filter(show => show.episode.title.toLowerCase().includes(searchTerm.toLowerCase()));
    const totalPages = Math.ceil(filteredShows?.length / itemsPerPage);
    const currentItems = filteredShows?.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

    useEffect(() => {
        dispatch(GetScheduledContent())
    }, []);

    return (
        <PublisherCard 
            title={ `Scheduled Episodes` }
            totalEntries={filteredShows?.length}
            currentPage={currentPage}
            totalPages={totalPages}
            handleChangePage={handleChangePage}
            itemsPerPage={itemsPerPage}
            handleChangeItemsPerPage={handleChangeItemsPerPage}
            searchTerm={searchTerm}
            handleSearchChange={handleSearchChange}
        >
            <Table className='dataTable'>
                <thead>
                    <tr>
                        <th tabIndex={0} aria-label='Sr: activate to sort column ascending' style={{ width: '43px', paddingRight: '30px' }}>SR</th>
                        <th tabIndex={0} aria-label='Episode Title: activate to sort column ascending' style={{ width: '151px', paddingRight: '30px' }}>Episode Title</th>
                        <th tabIndex={0} aria-label='Tv Show Title: activate to sort column ascending' style={{ width: '154px', paddingRight: '30px' }}>Tv Show Title</th>
                        <th tabIndex={0} aria-label='Season Title: activate to sort column ascending' style={{ width: '148px', paddingRight: '30px' }}>Season Title</th>
                        <th tabIndex={0} aria-label='Episode No: activate to sort column ascending' style={{ width: '129px', paddingRight: '30px' }}>Episode No</th>
                        <th tabIndex={0} aria-label='Publish Date: activate to sort column ascending' style={{ width: '149px', paddingRight: '30px' }}>Publish Date</th>
                        <th tabIndex={0} aria-label='Publish Time: activate to sort column ascending' style={{ width: '147px', paddingRight: '30px' }}>Publish Time</th>
                        <th tabIndex={0} aria-label='Created At: activate to sort column ascending' style={{ width: '130px', paddingRight: '30px' }}>Created At</th>
                        <th tabIndex={0} aria-label='Action' style={{ width: '87px' }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                {currentItems.map((episode) => {
                        const date = episode.episode.created_at
                        const [fullDate] = date.split('T');
                        const [year, month, day] = fullDate.split('-')
                        return (
                            <tr key={episode.episode.id}>
                                <td>{episode.episode.id}</td>
                                <td>
                                    {episode.episode.title}
                                </td>
                                <td>{episode.show_title}</td>
                                <td>{episode.season_title}</td>
                                <td>{episode.episode.episode_number}</td>
                                <td>{episode.episode.release_date}</td>
                                <td>{episode.episode.release_time}</td>
                                <td>{`${month}/${day}/${year}`}</td>
                                <td>
                                    <ShowDetailsIcon showId={episode.episode.tv_show_id}/>
                                    {' '}
                                    <EditContentIcon setSidebarComponent={setSidebarComponent} contentId={episode.episode.id} switchComponent="EditEpisode" />
                                    {' '}
                                    <DeleteConfirmBox currentComponent='Episode' currentId={episode.episode.id}/>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </PublisherCard>
    )
}

export default ScheduledContent
