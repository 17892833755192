import React, { useState, useEffect } from 'react'
import { Col, Container, Form, FormGroup, Row } from 'react-bootstrap'
import TrailerUpload from './components/TrailerUpload'
import PortraitUpload from './components/PortraitUpload'
import LandscapeUpload from './components/LandscapeUpload'
import DatePicker from 'react-datepicker'
// import LogoUpload from './components/LogoUpload'
// import BannerUpload from './components/BannerUpload'
import { useDispatch, useSelector } from 'react-redux'
import { GetAllCategories, ShowCreation } from '../../store/Actions/movieActions'
import { Controller, useForm } from 'react-hook-form'
import ReactSelect, {components} from 'react-select'
import { fetchLanguageInfo } from './../../store/Actions/movieActions';
// import { useHistory } from 'react-router-dom';
import validateAndSanitizeShowData from './../../validation/showValidation';
import moment from "moment";


const AddNewShow = () => {
    // const history = useHistory()
    const { genre, languages  } = useSelector((state) => state.movie);
    const dispatch = useDispatch()
    const [langOptionSelected, setLangOptionSelected] = useState()
    const [langOptionList, setLangOptionList] = useState([])
    
    const [optionSelected, setOptionSelected] = useState()
    const [genreOptionList, setGenreOptionList] = useState([])
    
    const { control } = useForm()
    const [formData, setFormData] = useState({
        banner: null,
        logo: null,
        title: '',
        language: 1,
        category: [],
        trailer: null,
        portrait_image: null,
        landscape_image: null,
        release_date: new Date(),
        description: ''
    })
    const colorStyles = {
        control: styles => ({ ...styles, backgroundColor: '#141414'}),
    };

    const Option = (props) => {
        return (
          <div style={{backgroundColor: '#141414'}}>
            <components.Option {...props}>
              <input
                style={{height: '.85rem', backgroundColor: '#141414'}}
                type="checkbox"
                checked={props.isSelected}
                onChange={() => null}
                />{" "}
              <label>{props.label}</label>
            </components.Option>
          </div>
        );
    };

    
    
    const handleTrailerUpload = event => {
        setFormData(prevState => ({
            ...prevState,
            trailer: event.target.files[0]
        }))
    }
    const handleImageUpload = event => {
        const { name } = event.target
        setFormData(prevState => ({
            ...prevState,
            [name]: event.target.files[0]
        }))
    }
    
    const handleInputChange = event => {
        const { name, value } = event.target
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }))
    }
    
    const handleSubmit = event => {
        event.preventDefault()
        let newFormat = moment.utc(formData.release_date).format("ddd MMM D YYYY HH:mm:ss [UTC]ZZ")
        console.log({newFormat})
        const checkedData = validateAndSanitizeShowData(formData)
        dispatch(ShowCreation(checkedData.sanitizedData, newFormat))
    }
    
    useEffect(() => {
        dispatch(GetAllCategories())
        dispatch(fetchLanguageInfo())
    }, [dispatch]);
    
    useEffect(() => {
        const tempList = []
        const genreOptions = () => {
            genre?.length && genre.map((genre) => {
                const objContainer = {}
                objContainer.value = genre.id
                objContainer.label = genre.name
                tempList.push(objContainer)
                return setGenreOptionList(tempList)
            })
        }
        genreOptions()
    }, [genre]);
    
    useEffect(() => {
        const tempListLang = []
        const langOptions = () => {
        languages?.length && languages.map((language) => {
                const objContainer = {}
                objContainer.value = language.id
                objContainer.label = language.name
                tempListLang.push(objContainer)
                return setLangOptionList(tempListLang)
            })
          }
          langOptions()
      }, [languages]);

    useEffect(() => {
        const newGenreList = []
        optionSelected?.length > 0 && optionSelected.map((genreObj) => {
            return newGenreList.push(genreObj.value)
        })
        setFormData(prevState => ({
            ...prevState,
            category: newGenreList
        }))
     }, [optionSelected])

    useEffect(() => {
        setFormData(prevState => ({
            ...prevState,
            language: langOptionSelected?.value
        }))
     }, [langOptionSelected])

    return (
        <Container>
            <Row>
                <Col sm={12}>
                    <div className='publisher-card'>
                        <div className='publisher-card-header justify-content-between'>
                            <h4>Add New Show</h4>
                        </div>
                        <div className='publisher-card-body'>
                            <Form method='POST' encType='multipart/form-data' onSubmit={handleSubmit}>
                                <Row>
                                    <Col lg={8} className='pt-2'>
                                        <TrailerUpload handleTrailerUpload={handleTrailerUpload}></TrailerUpload>
                                    </Col>
                                    <Col lg={4} className='pt-2'>
                                        <PortraitUpload handleImageUpload={handleImageUpload}></PortraitUpload>
                                    </Col>
                                </Row>
                                <Row  style={{ margin: '48px -15px' }}>
                                    <Col lg={8}>
                                        <Row className='form_publisher-fields'>
                                            <FormGroup className='col-md-6'>
                                                <Form.Label htmlFor='title'>Title</Form.Label>
                                                <Form.Control type='text' name='title' onChange={handleInputChange} required />
                                            </FormGroup>
                                            {languages?.length ? <Controller
                                                control={control}
                                                name="mylang"
                                                render={({ field }) => (
                                                    <Form.Group {...field} className='col-md-5'>
                                                        <Form.Label htmlFor='reactSelect-lang'>Languages</Form.Label>
                                                        <ReactSelect
                                                                maxMenuHeight={200}
                                                                placeholder="Choose A Language"
                                                                styles={colorStyles}
                                                                id='reactSelect-lang'
                                                                options={langOptionList}
                                                                closeMenuOnSelect={false}
                                                                hideSelectedOptions={false}
                                                                components={{
                                                                    Option
                                                                }}
                                                                onChange={setLangOptionSelected}
                                                                value={langOptionSelected}
                                                                
                                                            />
                                                    </Form.Group>
                                                )}  
                                            />
                                            : "No Languages"
                                            }
                                            <FormGroup className='col-6 pt-2'>
                                                <Form.Label htmlFor='release_date'>Release Date</Form.Label>
                                                <DatePicker required selected={formData.release_date} value={formData.release_date} 
                                                format="ddd MMM D YYYY HH:mm:ss [UTC]ZZ"
                                                onChange={(date) => {
                                                    setFormData(prevState => ({
                                                        ...prevState,
                                                        release_date: date
                                                    }))
                                                }} />
                                            </FormGroup>
                                            
                                            <Controller
                                                control={control}
                                                name="mygenre"
                                                render={({ field }) => (
                                                    <Form.Group {...field} className='col-md-5'>
                                                        <Form.Label htmlFor='reactSelect-genre'>Category</Form.Label>
                                                        <ReactSelect
                                                                maxMenuHeight={300}
                                                                placeholder="Choose A Category"
                                                                styles={colorStyles}
                                                                id='reactSelect-genre'
                                                                options={genreOptionList}
                                                                isMulti
                                                                closeMenuOnSelect={false}
                                                                hideSelectedOptions={false}
                                                                components={{
                                                                    Option
                                                                }}
                                                                onChange={setOptionSelected}
                                                                allowSelectAll={true}
                                                                value={optionSelected}
                                                                
                                                            />
                                                    </Form.Group>
                                                )}  
                                            />
                                            <FormGroup className='col-md-12'>
                                                <Form.Label htmlFor='description'>Description</Form.Label>
                                                <Form.Control as='textarea' name='description' rows={5} onChange={handleInputChange} required/>
                                            </FormGroup>
                                        </Row>
                                    </Col>
                                    <Col lg={4}>
                                            <LandscapeUpload handleImageUpload={handleImageUpload}></LandscapeUpload>
                                            {/* <LogoUpload handleImageUpload={handleImageUpload}></LogoUpload> */}
                                            {/* <BannerUpload handleImageUpload={handleImageUpload}></BannerUpload> */}
                                    </Col>
                                    
                                </Row>
                                <Row>
                                    <FormGroup className='col-12 text-center'>
                                        <button type='submit' className='btn btn-primary form_video-upload_button'>Submit</button>
                                    </FormGroup>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )

}
 
export default AddNewShow;