import { Divider, Typography, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import React, {useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import '../../../../assets/css/signIn.css';
import ComputerIcon from '@mui/icons-material/Computer';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import TabletAndroidIcon from '@mui/icons-material/TabletAndroid';
import { useDispatch, useSelector } from 'react-redux';
import { VerifyActiveSubscription } from './../../../../store/Actions/userActions';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { pricePlan, publicKey, testPricePlan } from './../../../../config/apiKeys';
import { loadStripe } from '@stripe/stripe-js';
import ContinueToPaymentLink from './ContinueToPaymentLink';
import { useState } from 'react';
import ButtonWrapper from './Components/ButtonWrapper';
import loader from '../../../../assets/custom-assets/loader.gif'

function PlanDetails() {
    const history = useHistory()
    const matches = useMediaQuery('(min-width:768px)');
    const {userData} = useSelector((state) => state.shared)
    const dispatch = useDispatch()
    const [stripePromise, setStripePromise] = useState(() => loadStripe(publicKey))
    // const stripe = useStripe();
    // const price = pricePlan;
    // const price = testPricePlan;

    useEffect(() => {
        dispatch(VerifyActiveSubscription(history))
    }, []);

    useEffect(() => {
        userData?.is_subscribed ? history.push('/') : console.log('Not subbed')
    }, [userData]);

    // const handleSubmit = async (event) => {
    //     event.preventDefault();
    //     const paymentLink = await stripe.paymentLinks.create({
    //         line_items: [
    //         {
    //             price: price,
    //             quantity: 1,
    //         },
    //         ],
    //         subscription_data: {
    //         trial_period_days: 7,
    //         },
    //     });
    // };
    return (
        <div>
            {userData.is_subscribed ? 
                    <div>
                        <img
                            alt="loader"
                            src={loader}
                            style={{
                                height: 150,
                                width: 150,
                                borderRadius: 5,
                            }}
                        />
                    </div>
                :
                    <div className='signIn__container'>
                        <div className='signIn__container-inner signin-inner'>
                            <Typography
                                variant='h5'
                            >
                                {"Plan Details"}
                            </Typography>
                            <div className='text__feilds'>
                                <div className='row'>
                                    <Box
                                        component="form"
                                        sx={{
                                            m: 2,
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                border: '0px solid red',
                                                height: 125,
                                                justifyContent: 'space-between',
                                                padding: '16px'
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: 'white'
                                                }}
                                                component={"span"}
                                            >
                                                <Typography
                                                    component={"span"}
                                                    sx={{
                                                        color: 'yellow'
                                                    }}
                                                >
                                                    {"> "}
                                                </Typography>
                                                {"  Watch Shows"}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    color: 'white'
                                                }}
                                                component={"span"}
                                            >
                                                <Typography
                                                    component={"span"}
                                                    sx={{
                                                        color: 'yellow'
                                                    }}
                                                >
                                                    {"> "}
                                                </Typography>
                                                {"  Create your own"}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    color: 'white'
                                                }}
                                                component={"span"}
                                            >
                                                <Typography
                                                    component={"span"}
                                                    sx={{
                                                        color: 'yellow'
                                                    }}
                                                >
                                                    {"> "}
                                                </Typography>
                                                {"  No Ads"}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    color: 'white'
                                                }}
                                                component={"span"}
                                            >
                                                <Typography
                                                    component={"span"}
                                                    sx={{
                                                        color: 'yellow'
                                                    }}
                                                >
                                                    {"> "}
                                                </Typography>
                                                {"  Cancel Anytime"}
                                            </Typography>
                                        </Box>
                                        <Divider />
                                        <Box
                                            sx={{
                                                border: '0px solid red',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    border: '0px solid red',
                                                    width: matches ? '40ch' : 'unset',
                                                    // width: 100,
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        border: '0px solid red',
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                        p: 2,
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            color: 'white'
                                                        }}
                                                    >
                                                        Monthly Price
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            color: 'white'
                                                        }}
                                                    >
                                                         $9.99
                                                    </Typography>
                                                </Box>
                                                <Divider />
                                            </Box>
                                            <Box
                                                sx={{
                                                    mt: 3,
                                                    mb: 3,
                                                }}
                                            >
                                                <Elements
                                                    stripe={stripePromise}
                                                >
                                                    <ContinueToPaymentLink />
                                                </Elements>
                                            </Box>
                                            {/* <ButtonWrapper type="subscription" /> */}
                                        </Box>
                                    </Box>
                                </div>
                            </div>
                        </div>
                    </div >
            }
        </div>
    )
}

export default PlanDetails
