import { useSelector } from 'react-redux';
import { Switch, Route, BrowserRouter } from 'react-router-dom'
import MainLayout from '../layouts/backend/main-layout'
import BlankLayout from '../layouts/blanklayout'

const LayoutsRoute = () => {
    const {userData} = useSelector((state) => state.shared);

    return (
        <BrowserRouter basename="#">
            <Switch>
                {userData?.is_subscribed ?
                    
                        <Route path="/" component={MainLayout} />
                    
                    :
                        <Route path="" component={BlankLayout} />
                }
            </Switch>
        </BrowserRouter>
    )
}

export default LayoutsRoute