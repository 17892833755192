import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CancelMembership } from '../../../../store/Actions/userActions';

const CancelMembershipModal = () => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleConfirm = () => {
        dispatch(CancelMembership())
        setShow(false);
    };

  return (
    <>
     
     <Link to="#" className="btn btn-hover" variant="primary" onClick={handleShow}>Cancel Membership</Link>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Membership</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to Cancel your Membership?</Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-hover iq-button text-medium navbar--button signup-button" variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button className="btn btn-hover iq-button text-medium navbar--button signup-button" variant="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CancelMembershipModal;