import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../assets/css/custom/episodes-sidebar.css';
import {
    ChangeShowEpisodesSidebar,
} from '../store/Actions/sharedActions';
import { IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import moment from 'moment';
import Loader from 'react-loader-spinner';
import { GetAllEpisodesOfSpecificSeason, GetEpisodeVideos } from '../store/Actions/movieActions';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function EpisodesSidebar(props) {

    const {
        handlePauseEpisode
    } = props

    const dispatch = useDispatch()

    const { showEpisodesSidebar, showDetailEpisodesLoader } = useSelector(state => state.shared)
    const { seasonDetail, currentSeasonEpisodes } = useSelector(state => state.movie)
    const {
        // addCast,
        // banner,
        // category,
        // description,
        // episode_count,
        // followers_count,
        // id,
        // is_following,
        // landscape_image,
        // maturity_level,
        // name,
        // portrait_image,
        // publisher,
        // release_date,
        seasons,
        // seasons_count,
        // show_logo,
        // trailer,
    } = seasonDetail

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        let seasonId = seasons[newValue].season_id
        dispatch(GetAllEpisodesOfSpecificSeason(seasonId))
    }
    const handleClose = () => {
        dispatch(ChangeShowEpisodesSidebar(false))
    }
    const handleChangeEpisode = (id) => {
        dispatch(ChangeShowEpisodesSidebar(false))
        dispatch(GetEpisodeVideos(id, false, true))
        handlePauseEpisode()
    }

    function RenderSeasonTabs() {
        return seasons?.map((item, key) => {
            const { season_name } = item
            return <Tab label={season_name} {...a11yProps(key)} key={key}/>
        })
    }
    function RenderEpisodes() {
        return currentSeasonEpisodes.map((item, key) => {
            const { 
                id, 
                landscape_image, 
                release_date, 
                title, 
                // created_at, 
                // portrait_image, 
                // status, 
                // video, 
            } = item
            return (
                <div onClick={() => handleChangeEpisode(id)} key={key}>
                    <div className='sidebar-epiosode-container yellow-border'>
                        <div className='thumbnail yellow-border'>
                            <img className='episode-image' src={landscape_image} alt="landscape poster"/>
                            <div className='hover-play-btn-div'>
                                <IconButton
                                    className=''
                                    style={{ marginRight: 5 }}
                                >
                                    <PlayCircleFilledWhiteIcon
                                        className='yellow-border icon'
                                        sx={{ fontSize: 25, color: "#ffca00" }}
                                    />
                                </IconButton>
                            </div>
                        </div>
                        <div className='content yellow-border'>
                            <Typography variant="button" component="div">
                                {title}
                            </Typography>
                            <Typography className='off-text' variant="caption" display="block" color={'silver'}>
                                {'publisher'}
                                {/* //todo: Needed in API + maturityLevel */}
                            </Typography>
                            <div className='episode-bottom-content'>
                                <Typography className='off-text' variant="caption" display="block" color={'silver'}>
                                    {moment(new Date(release_date)).format("MMM Do YY")} • {'maturityLevel'}
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <Divider />
                </div>
            )
        })
    }
    function RenderSeasonTabPannels() {
        return seasons?.map((item, key) => {
            // const { seasonName, episodes } = item
            return (
                <TabPanel className='sidebar-tabpannel' value={value} index={key} key={key}>
                    {!showDetailEpisodesLoader ?
                        <>
                            {currentSeasonEpisodes.length !== 0 ?
                                RenderEpisodes()
                                :
                                <div style={{ paddingTop: 20 }}>
                                    <span style={{ fontSize: 18, fontWeight: 'bold', color: 'gray', marginTop: 50 }}>No Episodes</span>
                                </div>
                            }
                        </>
                        :
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                paddingTop: 20,
                            }}
                        >
                            <Loader
                                type="TailSpin"
                                color="#EDB500"
                                height={30}
                                width={30}
                            />
                        </div>
                    }
                </TabPanel>
            )
        })
    }

    useEffect(() => {
        // setValue(0)
    }, [showEpisodesSidebar])

    return (
        <Dialog
            scroll='body'
            className='episodes-sidebar-dialoge yellow-border'
            fullScreen
            open={showEpisodesSidebar}
            onClose={handleClose}
            PaperProps={{
                style: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                },
            }}
            TransitionComponent={Transition}
        >
            <div className='episodes-sidebar-container yellow-border'>
                <div className='episodes-sidebar-header yellow-border'>
                    <div className='yellow-border heading-div'>
                        <Typography variant="h5" component="div">
                            Episodes
                        </Typography>
                    </div>
                    <div className='yellow-border close-btn-div'>
                    </div>
                </div>
                <Divider />
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            textColor="primary"
                            indicatorColor="primary"
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons={false}
                            aria-label="scrollable prevent tabs example"
                        >
                            {RenderSeasonTabs()}
                        </Tabs>
                    </Box>
                    {RenderSeasonTabPannels()}
                </Box>
                <IconButton
                    className='close-btn'
                    style={{ marginRight: 5 }}
                    onClick={handleClose}
                >
                    <CancelIcon
                        className='yellow-border icon'
                        sx={{ fontSize: 40 }}
                    />
                </IconButton>
            </div>
        </Dialog>
    );
}

export default EpisodesSidebar