import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { DeleteContent, DeleteShow } from '../../../store/Actions/movieActions';

const DeleteConfirmBox = ({currentComponent, currentId}) => {

    const dispatch = useDispatch()
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleConfirm = (currentComp, contentId) => {
        switch (currentComp) {
            case 'Show':
                console.log('dispatch(DeleteShowFromDB(itemId)), itemId =' + contentId);
                dispatch(DeleteShow(contentId))
                break;
            case 'Season':
                console.log('dispatch(DeleteSeasonFromDB(itemId)), itemId =' + contentId);
                dispatch(DeleteContent(contentId,'Season'))
                break;
            case 'Episode':
                console.log('dispatch(DeleteEpisodeFromDB(itemId)), itemId =' + contentId);
                dispatch(DeleteContent(contentId,'Episode'))
                break;
            default:
                console.log("Error, no currentComp passed to DeleteConfirmBox");
                break;
        }
        setShow(false);
    };
    return ( 
        <>
            <Link to='#'>
                <i 
                onClick={handleShow}
                className="ri-delete-bin-line ri-xl" 
                style={{
                    backgroundColor: 'rgb(90,50,44)', 
                    borderRadius: '5px', 
                    color: 'rgb(255,202,0)'
                    }}></i>
            </Link>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>{`Delete ${currentComponent}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{`Are you sure you want to Delete this ${currentComponent}?`}</Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-hover iq-button text-medium navbar--button signup-button" variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button className="btn btn-hover iq-button text-medium navbar--button signup-button" variant="primary" onClick={() => handleConfirm(currentComponent, currentId)}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
      </>
     );
}
 
export default DeleteConfirmBox;