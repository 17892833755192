import moment from "moment";


const padTo2Digits = (num) => {
    return num.toString().padStart(2, '0')
}

export const formatDate = (date) => {
    return [
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
        date.getFullYear(),
    ].join('/')
}

export const newFormat = (data) => {
    const newData = moment(data).format("ddd MMM D YYYY HH:mm:ss [GMT]ZZ")
    return newData
}

export const formatTime = (date) => {
    var hours = date.getHours()
    var minutes = date.getMinutes()
    minutes = minutes < 10 ? '0'+minutes : minutes
    return `${hours}:${minutes}`
}
