import validator from 'validator';
import isEmpty from './isEmpty';


const validateAndSanitizeEditProfileForm = ( data, hasStates = true ) => {
	
	let errors = {};
	let sanitizedData = {};
	
	data.name = ( ! isEmpty( data.name ) ) ? data.name : '';
	data.email = ( ! isEmpty( data.email ) ) ? data.email : '';
	data.address = ( ! isEmpty( data.email ) ) ? data.email : '';
	data.maturity_level = ( ! isEmpty( data.maturity_level ) ) ? data.maturity_level : '';
 
	
	const addErrorAndSanitizedData = ( fieldName, errorContent, min, max, type = '', required ) => {
		
		if ( ! validator.isLength( data[ fieldName ], { min, max } ) ){
			errors[ fieldName ] = `${errorContent} must be ${min} to ${max} characters`;
		}
		
		if ( 'email' === type && ! validator.isEmail( data[ fieldName ] ) ){
			errors[ fieldName ] = `${errorContent} is not valid`;
		}
		
		if ( required && validator.isEmpty( data[ fieldName ] ) ) {
			errors[ fieldName ] = `${errorContent} is required`;
		}

		if ( ! errors[ fieldName ] ) {
			sanitizedData[ fieldName ] = validator.trim( data[ fieldName ] );
			sanitizedData[ fieldName ] = ( 'email' === type ) ? validator.normalizeEmail( sanitizedData[ fieldName ] ) : sanitizedData[ fieldName ];
			sanitizedData[ fieldName ] = validator.escape( sanitizedData[ fieldName ] );
		}
		
	};
	
	addErrorAndSanitizedData( 'name', 'Name', 2, 35, 'string', true );
	addErrorAndSanitizedData( 'address', 'Address', 2, 35, 'string', true );
	addErrorAndSanitizedData( 'email', 'Email', 11, 254, 'email', true );
	addErrorAndSanitizedData( 'maturity_level', 'MaturityLevel', 7, 14, 'string', true );
	
	return {
		sanitizedData,
		errors,
		isValid: isEmpty( errors )
	}
};

export default validateAndSanitizeEditProfileForm;