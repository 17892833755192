import store from '../';
import { baseURL } from '../../config/baseURL';
import {
    CHANGE_CONNECTION_TYPE, CHANGE_DISLIKED_SHOWS_IDS,
    CHANGE_FOLLOWED_SHOWS_IDS,
    CHANGE_IS_CONNECTED,
    CHANGE_LIKED_SHOWS_IDS,
    CHANGE_SHOW_FULL_SCREEN_PLAYER,
    CHANGE_SHOW_ID_IN_EPISODES_PER_SEASON,
    CHANGE_YAXIS,
    DISLIKE_SHOW,
    LIKE_SHOW, SET_NAVIGATION,
    CHANGE_SHOW_EPISODES_SIDEBAR,
    CHANGE_SHOW_CHANGE_QUALITY,
    CHANGE_GET_STARTED_EMAIL,
} from './types';

//HELPING METHODS
export const CheckStoragePermission = async () => {
}
export const CheckReadStoragePermission = async () => {
}
export const DownloadWithoutNotification = async () => { //! not working
}

//STATE MANAGMENT
export const ChangeGetStartedEmail = (val) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_GET_STARTED_EMAIL, payload: val })
    }
}
export const ChangeIsConnected = (val) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_IS_CONNECTED, payload: val })
    }
}
export const ChangeShowEpisodesSidebar = (val) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_SHOW_EPISODES_SIDEBAR, payload: val })
    }
}
export const ChangeShowChangeQuality = (val) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_SHOW_CHANGE_QUALITY, payload: val })
    }
}
export const rtlModeAction = (value) => {
    return async (dispatch) => {
        sessionStorage.setItem('rtl-mode', value)

        if (value === 'rtl') {
            document.body.parentElement.setAttribute('dir', 'rtl')
            document.querySelector('[data-value="rtl"]').classList.add('active')
            document.querySelector('[data-value="ltr"]').classList.remove('active')

        }

        else {
            document.body.parentElement.setAttribute('dir', 'ltr')
            document.querySelector('[data-value="ltr"]').classList.add('active')
            document.querySelector('[data-value="rtl"]').classList.remove('active')
        }

        dispatch({ type: 'RTLMODE', payload: value })
    }
}
export const ChangeConnectionType = (val) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_CONNECTION_TYPE, payload: val })
    }
}
export const ChangeShowFullScreenPlayer = (val) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_SHOW_FULL_SCREEN_PLAYER, payload: val })
    }
}
export const ChangeYaxis = (val) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_YAXIS, payload: val })
    }
}
export const SetNavigation = (val) => {
    return async (dispatch) => {
        dispatch({ type: SET_NAVIGATION, payload: val })
    }
}
export const ChangeShowIdInEpisodesPerSeason = (val) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_SHOW_ID_IN_EPISODES_PER_SEASON, payload: val })
    }
}

//FERCH AND DISPATCH
export const DownloadFile = (filePath, id) => {
    return async (dispatch) => {
    }
}
export const LikeShow = (showId) => {
    return async (dispatch) => {
        dispatch({ type: LIKE_SHOW, payload: showId })
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + store.getState().shared.userData.token);

        var formdata = new FormData();
        formdata.append("show_id", showId);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(baseURL + "/api/like-show",
            requestOptions
        ).then(response => {
            return response.json()
        }).then(json => {
            if (json.status_code === 200) {
            }
            else {
                console.error("Error while liking show")
            }
        }).catch(error => {
            console.log('error', error)
        });
    }
}
export const DislikeShow = (showId) => {
    return async (dispatch) => {
        dispatch({ type: DISLIKE_SHOW, payload: showId })
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + store.getState().shared.userData.token);

        var formdata = new FormData();
        formdata.append("show_id", showId);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(baseURL + "/api/dislike-show",
            requestOptions
        ).then(response => {
            return response.json()
        }).then(json => {
            if (json.status_code === 200) {
            }
            else {
                console.error("Error while disliking show")
            }
        }).catch(error => {
            console.log('error', error)
        });
    }
}
export const LikeDislike = () => { //! Remove this later (called in movies/GetAllData)
    return async (dispatch) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + store.getState().shared.userData.token);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(baseURL + "/api/like-dislike",
            requestOptions
        ).then(response => {
            return response.json()
        }).then(json => {
            if (json.status_code === 200) {
                dispatch({ type: CHANGE_LIKED_SHOWS_IDS, payload: json.data.like_shows })
                dispatch({ type: CHANGE_DISLIKED_SHOWS_IDS, payload: json.data.dislike_shows })
            }
            else {
                console.error("Error while fetching like &  show")
            }
        }).catch(error => {
            console.log('error', error)
        });
    }
}
export const FollowedShows = () => {
    return async (dispatch) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + store.getState().shared.userData.token);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(baseURL + "/api/followed-shows",
            requestOptions
        ).then(response => {
            return response.json()
        }).then(json => {
            if (json.status_code === 200) {
                dispatch({ type: CHANGE_FOLLOWED_SHOWS_IDS, payload: json.data.show_ids })
            }
            else {
                console.error("Error while fetching like &  show")
            }
        }).catch(error => {
            console.error('error', error)
        });
    }
}