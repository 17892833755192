import { Col, Container, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import RenderNewEpisodeSlides from "./RenderNewEpisodeSlides";

import "swiper/swiper-bundle.css";
import "../../../../assets/css/custom/new-episodes.css";


function RenderNewEpisodeSwiper({episodeList}) {
        return (
          <section id="iq-favorites" className="slider-section">
            <Container fluid className="custom-container">
              <Row>
                <Col sm="12" className="overflow-hidden">
                  <div id="favorites-contens">
                    <div id='next-new' className="swiper-button swiper-button-next">
                      <i className="fa fa-chevron-right"></i>
                    </div>
                    <div id='prev-new' className="swiper-button swiper-button-prev">
                      <i className="fa fa-chevron-left"></i>
                    </div>
                    <Swiper
                      spaceBetween={30}
                      modules={[Navigation]}
                      navigation={{
                        prevEl: '#prev-new',
                        nextEl: '#next-new',
                      }}
                      centeredSlides={episodeList.length < 3 ? true : false}
                      breakpoints={{
                        320: { slidesPerView: 2 },
                        550: { slidesPerView: 3 },
                        991: { slidesPerView: 4 },
                        1400: { slidesPerView: 5 },
                      }}
                      className='favorites-slider list-inline  row p-0 m-0 iq-rtl-direction'
                    >
                      {episodeList.map((episode, key) => {
                        return(
                          <SwiperSlide className='swiper-slide slide-item red-border new-ep-slides' key={key}>
                            <RenderNewEpisodeSlides episode={episode}/>
                          </SwiperSlide>
                          )
                        })
                      }
                    </Swiper>
                   </div>
                 </Col>
               </Row>
             </Container>
           </section>
        );
  }

  export default RenderNewEpisodeSwiper;