import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import loader from '../assets/custom-assets/loader.gif'

const Loader = () => {
    const history = useHistory()

    useEffect(() => {
        history.push('/')
    }, []);
    return ( 
        <div className='text-center'>
            <img
                alt="loader"
                src={loader}
                style={{
                    height: 150,
                    width: 150,
                    borderRadius: 5,
                }}
            />
        </div>
     );
}
 
export default Loader;