import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "swiper/swiper-bundle.css";
import { ControlBar, Player } from "video-react";
import { 
  FollowShow,
  GetShowDetails,
  PlayNow,
} from "../../../../store/Actions/movieActions";
import { DislikeShow, LikeShow} from "../../../../store/Actions/sharedActions";
import { FacebookShareButton } from 'react-share';
import { TwitterShareButton } from 'react-share';
import { Link } from 'react-router-dom';
import { CopyLink } from './CopyLink';
import { baseSharingURL } from "../../../../config/baseURL";


export default function RenderTopShow({show}) {
  const {
    addCast,
    category,
    description,
    id,
    maturity_level,
    name,
    seasons,
    seasons_count,
    trailer,
  } = show
  const { dislikedShowsIds, likedShowsIds, followedShowsIds } = useSelector(
    (state) => state.shared
  );
    const RenderSeasons = (seasons) => {
        return seasons.map((item, key) => {
          const {
            id,
            description,
            landscape_image,
            season_name,
          } = item;
      
          return (
            <div key={id} className="season-banner-tile red-border">
              <div className="season-banner-tile-left">
                <h4>{season_name}</h4>
                <span className="banner-season-desc font-xsmall">{description}</span>
              </div>
              <img
                alt="banner"
                src={landscape_image}
                className="banner-season-img red-border"
              />
            </div>
          );
        });
      }

  const dispatch = useDispatch();

  let stringCategories = "";
  category?.map((item, key) => {
    const { category_name } = item;
    if (key === 0) stringCategories = category_name;
    else stringCategories = stringCategories + ", " + category_name;
  });

  let stringCasts = "";
  addCast?.map((item, key) => {
    const { addCast_name } = item;
    if (key === 0) stringCasts = addCast_name;
    else stringCasts = stringCasts + ", " + addCast_name;
  });

  return (
    <>
      {show && show?.length !== 0 ? 
      (<div id="home-slider" className="slider m-0 p-0 home-banner">
        <Player
          className="banner-video-player red-border"
          src={trailer}
          fluid={false}
          autoPlay={true}
          loop={true}
          muted={true}
        >
          <ControlBar disableCompletely />
        </Player>
        <Container fluid className="position-relative h-100 red-border">
          <div className="slider-inner h-100">
            <Row className="row align-items-center h-100 iq-ltr-direction">
              <Col xl="6" lg="12" md="12">
                <h1
                  className="slider-text big-title title text-uppercase"
                  data-iq-gsap="onStart"
                  data-iq-position-x="-200"
                >
                  {name}
                </h1>
                <div className="d-flex flex-wrap align-items-center animated">
                  <div
                    className="d-flex align-items-center mt-2 mt-md-3"
                    data-iq-gsap="onStart"
                    data-iq-position-x="-200"
                    data-iq-delay="-0.5"
                  >
                    <span className="badge badge-secondary p-2">
                      {maturity_level}
                    </span>
                    <span className="ml-3 font-small">
                      {seasons_count} Seasons
                    </span>
                  </div>
                </div>
                <p
                  data-iq-gsap="onStart"
                  data-iq-position-y="80"
                  data-iq-delay="0.8"
                >
                  {description}
                </p>
                <div
                  className="trending-list"
                  data-wp_object-in="fadeInUp"
                  data-delay-in="1.2"
                >
                  <div className="text-primary title genres font-small">
                    Staring: <span className="text-body">{stringCasts}</span>
                  </div>
                  <div className="text-primary title genres font-small">
                    Categories:{" "}
                    <span className="text-body">{stringCategories}</span>
                  </div>
                </div>
                <div
                  className="d-flex align-items-center r-mb-23"
                  data-iq-gsap="onStart"
                  data-iq-position-y="80"
                  data-iq-delay="0.8"
                >
                  <div
                    className="btn btn-hover iq-button font-small"
                    onClick={() => dispatch(PlayNow(id))}
                    style={{ color: "black" }}
                  >
                    <i className="fa fa-play mr-2" aria-hidden="true"></i>
                    Play Now
                  </div>
                  <ul className="list-inline p-0 mt-4 share-icons music-play-lists ml-4">
                    <li
                      onClick={() => {
                        if (followedShowsIds.includes(id)) {
                          dispatch(FollowShow(id, false));
                        } else {
                          dispatch(FollowShow(id, true));
                        }
                      }}
                    >
                      <span>
                        {followedShowsIds.includes(id) ? (
                          <i className="custom-icon ri-check-line" />
                        ) : (
                          <i className="custom-icon ri-add-line" />
                        )}
                      </span>
                    </li>
                    <li className="share">
                      <span className="">
                        <i className="custom-icon ri-heart-fill"></i>
                      </span>
                      <div className="share-box">
                        <div className="d-flex align-items-center">
                          {likedShowsIds.includes(id) ? (
                            <i
                              onClick={() => dispatch(LikeShow(id))}
                              className="hover-icon ri-thumb-up-fill"
                            />
                          ) : (
                            <i
                              onClick={() => dispatch(LikeShow(id))}
                              className="hover-icon ri-thumb-up-line"
                            />
                          )}
                          {dislikedShowsIds.includes(id) ? (
                            <i
                              onClick={() => dispatch(DislikeShow(id))}
                              className="hover-icon ri-thumb-down-fill"
                            />
                          ) : (
                            <i
                              onClick={() => dispatch(DislikeShow(id))}
                              className="hover-icon ri-thumb-down-line"
                            />
                          )}
                        </div>
                      </div>
                    </li>
                    <li className="share">
                      <span>
                        <i className="custom-icon ri-share-fill"></i>
                      </span>
                      <div className="share-box">
                        <div className="d-flex align-items-center">
                          <FacebookShareButton
                            url={baseSharingURL + id}
                            title={name}
                          >
                            <i className="ri-facebook-fill"></i>
                          </FacebookShareButton>
                          <TwitterShareButton
                            url={baseSharingURL + id}
                            title={name}
                            className="share-ico"
                          >
                            <i className="ri-twitter-fill"></i>
                          </TwitterShareButton>
                          <Link to="#" 
                            onClick={() => CopyLink(id)} 
                            className="share-ico">
                            <i className="ri-links-fill"></i>
                          </Link>
                        </div>
                      </div>
                    </li>
                    <li>
                        <span onClick={() => dispatch(GetShowDetails(id))} className=''>
                          <i className="custom-icon ri-information-fill"></i>
                        </span>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col
                className="red-border season-banner-right-col"
                xl="6"
                lg="12"
                md="12"
              >
                {RenderSeasons(seasons)}
              </Col>
            </Row>
          </div>
        </Container>
      </div> )
      :
        (<div style={{ height: 40 }}>No Top Shows</div>)
      }
    </>
  );
}