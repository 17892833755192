import CancelIcon from '@mui/icons-material/Cancel';
import { IconButton } from '@mui/material';
// import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
// import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
// import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../assets/css/custom/episodes-sidebar.css';
import '../assets/css/custom/change-quality.css';
import { ChangeShowChangeQuality } from '../store/Actions/sharedActions';

// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="left" ref={ref} {...props} />;
// });

function ChangeQuality(props) {

    const {
        setCurrentQualityIndex
    } = props

    let qualities = ["360p", "480p", "720p", "1080p", "2160p"]
    const dispatch = useDispatch()

    const { showChangeQuality } = useSelector(state => state.shared)
    const { fullScreenEpisode } = useSelector(state => state.movie)


    const handleClose = () => {
        dispatch(ChangeShowChangeQuality(false))
    }

    function RenderQualities() {
        return fullScreenEpisode?.videos.map((item, key) => {
            // const { url } = item
            return (
                <div key={key}>
                    <div
                        onClick={() => {
                            setCurrentQualityIndex(key)
                            handleClose()
                        }}
                        className='quality-wrapper'
                    >
                        <Typography variant="subtitle1" component="div">
                            {qualities[key]}
                        </Typography>
                        {key > 2 &&
                            <Typography className='red-hd' variant="subtitle2" component="div">
                                HD
                            </Typography>
                        }
                    </div>
                    <Divider />
                </div>
            )
        })
    }

    useEffect(() => {
    }, [])

    return (
        <Dialog
            scroll='body'
            className='iyellow-border'
            // fullScreen
            open={showChangeQuality}
            onClose={handleClose}
            PaperProps={{
                style: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                },
            }}
        // TransitionComponent={Transition}
        >
            <div className='episodes-sidebar-container yellow-border'>
                <div className='episodes-sidebar-header yellow-border'>
                    <div className='yellow-border heading-div'>
                        <Typography variant="h6" component="div">
                            Select Quality
                        </Typography>
                    </div>
                </div>
                <Divider />
                {RenderQualities()}
                <IconButton
                    className='close-btn'
                    style={{ marginRight: 5 }}
                    onClick={handleClose}
                >
                    <CancelIcon
                        className='yellow-border icon'
                        sx={{ fontSize: 30 }}
                    />
                </IconButton>
            </div>
        </Dialog>
    );
}

export default ChangeQuality