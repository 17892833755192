import React from 'react'
import { FaInstagram } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import '../../../../../assets/css/custom/footer-landing.css';

//This footer Is what shows up in the Landing Page
function Footer() {
    return (
        <>
            <div className='gray-line'></div>
            <div className='conatainer-fluid footer__container'>
                <div className="container footer__container-inner">
                    <ul className="footer__list">
                        <li>
                            <Link style={{ marginRight: '8px', marginTop: '2rem' }} to="/privacy-policy" >
                                Privacy Policy
                            </Link></li>
                        <li>
                            <Link to="/terms-of-service">
                                Terms of Use
                            </Link>
                        </li>
                    </ul>
                    <p className="text-center">Copyright © 2022 | <a href="/#" className="text-primary"> Multitude . </a> All Rights Reserved.
                    </p>
                    <ul className="footer__social-icons">
                        {/* <li><a href="#" style={{ marginRight: '8px' }}><FaTwitter /></a></li>
                        <li><a href="#" style={{ marginRight: '8px' }}><FaFacebookF /></a></li> */}
                        <li><a target="_blank" rel="noreferrer" href="https://www.instagram.com/multitude.io/ " style={{ marginRight: '8px' }}><FaInstagram /></a></li>
                        {/* <li><a href="#" style={{ marginRight: '8px' }}><FaLinkedinIn /></a></li> */}
                    </ul>

                </div>

            </div>

        </>
    )
}

export default Footer

